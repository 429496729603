import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { get } from 'lodash';
import { combineLatest, iif, Observable, of } from 'rxjs';
import { filter, map, startWith, switchMap, take } from 'rxjs/operators';
import { TargetMarketService } from '../../../routes/target-market/services/target-market.service';
import { truthy } from '../../../shared/helpers/general.helper';
import { getFirstMenuItemLink } from '../../helpers/menu.helper';
import { AuthService } from '../../services/auth.service';
import { ConfigurationService } from '../../services/configuration.service';
import { MenuService } from '../../services/menu.service';
import { UiService } from '../../services/ui.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/state/index.state';
import { AuthStoreService } from '../../services/auth-store.service';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { AsyncPipe } from '@angular/common';
import { IconComponent } from '../../../shared/components/icon/icon.component';

@Component({
    selector: 'app-admin-header-bar',
    templateUrl: './admin-header-bar.component.html',
    styleUrls: ['./admin-header-bar.component.scss'],
    standalone: true,
    imports: [RouterLink, IconComponent, AsyncPipe, TranslocoModule]
})
export class AdminHeaderBarComponent {
    public profileLink$: Observable<any[]>;
    public logoUrl$: Observable<any[]>;
    public logoUrl: string;
    public profileMenuLinkLabel$: Observable<string>
    public logoImgUrl$: Observable<string>;
    public displayUsername$: Observable<boolean>;

    constructor(
        private uiService: UiService,
        private targetMarketService: TargetMarketService,
        private authService: AuthService,
        private authStore: AuthStoreService,
        private router: Router,
        private translocoService: TranslocoService,
        private menuService: MenuService,
        private configurationService: ConfigurationService,
        private store: Store<AppState>
    ) {
        this.displayUsername$ = this.configurationService.displayUsername$;

        const getSlideDownOutletRoute = () => {
            const urlTree = this.router.parseUrl(this.router.url);
            const slideDownOutlet = urlTree.root.children.primary.children['slide-down'];

            return get(slideDownOutlet, 'segments[0].path', '');
        };

        this.profileMenuLinkLabel$ = combineLatest([
            this.store.pipe(select('auth')),
            this.displayUsername$
        ]).pipe(
            switchMap(([authState, displayUsername]) => {
                const displayName = (get(authState.authUser, 'firstname', '') + ' ' + get(authState.authUser, 'lastname', '')).trim();

                if (displayUsername) {
                    return of(displayName);
                } else {
                    return this.translocoService.selectTranslate('profile.menuLink');
                }
            })
        );

        this.profileLink$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            startWith(null as NavigationEnd),
            map(getSlideDownOutletRoute),
            map((slideDownOutletRoute) => {
                if (slideDownOutletRoute === 'profile') {
                    return ['/admin', {outlets: {'slide-down': null}}];
                } else {
                    return [
                        '/admin',
                        {
                            outlets: {
                                'slide-down': ['profile'],
                            },
                        },
                    ];
                }
            }),
        );

        this.logoUrl$ = this.menuService.getMenuConfig().pipe(
            filter(truthy),
            map((menuConfig) => getFirstMenuItemLink(menuConfig)),
        );

        this.logoImgUrl$ = this.configurationService.getLogoImageUrl('logoInverse');
    }

    /**
     * Opens the menu when the user clicks the menu button
     */
    public onMenuButtonClicked(e: Event): boolean {
        e.preventDefault();
        this.uiService.openMenu();
        return false;
    }

    /**
     * Signs out the user when the sign out button is clicked
     */
    public onSignOutButtonClicked(e: Event): boolean {
        e.preventDefault();
        this.authService.signOut();
        return false;
    }

    /**
     * Load the auth user
     * @param e
     */
    public onLoadAuthUserClicked(e: Event) {
        e.preventDefault();
        this.authService.loadAuthUser();
        return false;
    }
}
