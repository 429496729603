/**
 * Created by Alex Klein <alex@rocketloop.de> on 08/145/2017.
 */

import { Action, createAction, props } from '@ngrx/store';
import { AppError } from '../../../../core/errors/base.errors';
import { Data } from '../../../../core/models/data.model';
import { FormValues } from '../../../../core/models/form.model';
import { FactsheetUniverseData, ProductChangelog, ProductDetailsStructure, ProductDetailsTabStructure } from '../../models/product-details-structure.model';

export enum ProductDetailsActionTypes {
    PRODUCT_DETAILS_TAB_STRUCTURE_REQUEST = 'PRODUCTS:PRODUCT_DETAILS_TAB_STRUCTURE_REQUEST',
    PRODUCT_DETAILS_TAB_STRUCTURE_SUCCESS = 'PRODUCTS:PRODUCT_DETAILS_TAB_STRUCTURE_SUCCESS',
    PRODUCT_DETAILS_TAB_STRUCTURE_FAILURE = 'PRODUCTS:PRODUCT_DETAILS_TAB_STRUCTURE_FAILURE',

    UPDATE_SELECTED_TAB = 'PRODUCTS:UPDATE_SELECTED_TAB',

    PRODUCT_DETAILS_STRUCTURE_REQUEST = 'PRODUCTS:PRODUCT_DETAILS_STRUCTURE_REQUEST',
    PRODUCT_DETAILS_STRUCTURE_SUCCESS = 'PRODUCTS:PRODUCT_DETAILS_STRUCTURE_SUCCESS',
    PRODUCT_DETAILS_STRUCTURE_FAILURE = 'PRODUCTS:PRODUCT_DETAILS_STRUCTURE_FAILURE',

    PRODUCT_DETAILS_DATA_REQUEST = 'PRODUCTS:PRODUCT_DETAILS_DATA_REQUEST',
    PRODUCT_DETAILS_DATA_SUCCESS = 'PRODUCTS:PRODUCT_DETAILS_DATA_SUCCESS',
    PRODUCT_DETAILS_DATA_FAILURE = 'PRODUCTS:PRODUCT_DETAILS_DATA_FAILURE',

    PRODUCT_DETAILS_VALUES_REQUEST = 'PRODUCTS:PRODUCT_DETAILS_VALUES_REQUEST',
    PRODUCT_DETAILS_VALUES_SUCCESS = 'PRODUCTS:PRODUCT_DETAILS_VALUES_SUCCESS',
    PRODUCT_DETAILS_VALUES_FAILURE = 'PRODUCTS:PRODUCT_DETAILS_VALUES_FAILURE',

    UPDATED_PRODUCT_DETAILS_DATA_REQUEST = 'PRODUCTS:UPDATED_PRODUCT_DETAILS_DATA_REQUEST',
    UPDATED_PRODUCT_DETAILS_DATA_SUCCESS = 'PRODUCTS:UPDATED_PRODUCT_DETAILS_DATA_SUCCESS',
    UPDATED_PRODUCT_DETAILS_DATA_FAILURE = 'PRODUCTS:UPDATED_PRODUCT_DETAILS_DATA_FAILURE',

    UPDATE_PRODUCT_DETAILS_DATA = 'PRODUCTS:UPDATE_PRODUCT_DETAILS_DATA',

    LOAD_FACTSHEET_UNIVERSE_DATA = 'FACTSHEET:LOAD_FACTSHEET_UNIVERSE_DATA',
    LOAD_FACTSHEET_UNIVERSE_DATA_SUCCEEDED = 'FACTSHEET:LOAD_FACTSHEET_UNIVERSE_DATA_SUCCEEDED',
    LOAD_FACTSHEET_UNIVERSE_DATA_FAILED = 'FACTSHEET:LOAD_FACTSHEET_UNIVERSE_DATA_FAILED',
}

// ----------

export interface UpdateProductDetailsDataAction extends Action {
    payload: {
        sessionId: string;
        data: Data;
    };
}

export function updateProductDetailsData(sessionId: string, data: Data): UpdateProductDetailsDataAction {
    return {
        type: ProductDetailsActionTypes.UPDATE_PRODUCT_DETAILS_DATA,
        payload: {
            sessionId,
            data,
        },
    };
}

/** ProductDetailsTabStructureRequestAction **/

export interface ProductDetailsTabStructureRequestAction extends Action {
    payload: {
        productId: string;
    };
}

export function productDetailsTabStructureRequest(productId: string): ProductDetailsTabStructureRequestAction {
    return {
        type: ProductDetailsActionTypes.PRODUCT_DETAILS_TAB_STRUCTURE_REQUEST,
        payload: {
            productId,
        },
    };
}

/** ProductDetailsTabStructureSuccessAction **/

export interface ProductDetailsTabStructureSuccessAction extends Action {
    payload: {
        productId: string;
        structure: ProductDetailsTabStructure;
    };
}

export function productDetailsTabStructureSuccess(
    productId: string,
    structure: ProductDetailsTabStructure,
): ProductDetailsTabStructureSuccessAction {
    return {
        type: ProductDetailsActionTypes.PRODUCT_DETAILS_TAB_STRUCTURE_SUCCESS,
        payload: {
            productId,
            structure,
        },
    };
}

/** ProductDetailsTabStructureFailureAction **/

export interface ProductDetailsTabStructureFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function productDetailsTabStructureFailure(error: AppError): ProductDetailsTabStructureFailureAction {
    return {
        type: ProductDetailsActionTypes.PRODUCT_DETAILS_TAB_STRUCTURE_FAILURE,
        payload: {
            error,
        },
    };
}

/** UpdateSelectedTabIdAction **/

export interface UpdateSelectedTabIdAction extends Action {
    payload: {
        tabId: string;
    };
}

export function updateSelectedTabId(tabId: string): UpdateSelectedTabIdAction {
    return {
        type: ProductDetailsActionTypes.UPDATE_SELECTED_TAB,
        payload: {
            tabId,
        },
    };
}

/** ProductDetailsStructureRequestAction **/

export interface ProductDetailsStructureRequestAction extends Action {
    payload: {
        sessionId: string;
        tabId: string;
        encodedTargetMarketCriteria?: string;
    };
}

export function productDetailsStructureRequest(
    sessionId: string,
    tabId: string,
    encodedTargetMarketCriteria?: string
): ProductDetailsStructureRequestAction {
    return {
        type: ProductDetailsActionTypes.PRODUCT_DETAILS_STRUCTURE_REQUEST,
        payload: {
            sessionId,
            tabId,
            encodedTargetMarketCriteria
        }
    };
}

/** ProductDetailsStructureSuccessAction **/

export interface ProductDetailsStructureSuccessAction extends Action {
    payload: {
        sessionId: string;
        tabId: string;
        structure: ProductDetailsStructure,
        encodedTargetMarketCriteria?: string;
    };
}

export function productDetailsStructureSuccess(
    sessionId: string,
    tabId: string,
    structure: ProductDetailsStructure,
    encodedTargetMarketCriteria?: string,
): ProductDetailsStructureSuccessAction {
    return {
        type: ProductDetailsActionTypes.PRODUCT_DETAILS_STRUCTURE_SUCCESS,
        payload: {
            sessionId,
            structure,
            tabId,
            encodedTargetMarketCriteria
        }
    };
}

/** ProductDetailsStructureFailureAction **/

export interface ProductDetailsStructureFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function productDetailsStructureFailure(error: AppError): ProductDetailsStructureFailureAction {
    return {
        type: ProductDetailsActionTypes.PRODUCT_DETAILS_STRUCTURE_FAILURE,
        payload: {
            error,
        },
    };
}

/** ProductDetailsDataRequestAction **/

export interface ProductDetailsDataRequestAction extends Action {
    payload: {
        sessionId: string,
        tabId: string,
        encodedTargetMarketCriteria?: string;
    };
}

export function productDetailsDataRequest(
    sessionId: string,
    tabId: string,
    encodedTargetMarketCriteria?: string,
): ProductDetailsDataRequestAction {
    return {
        type: ProductDetailsActionTypes.PRODUCT_DETAILS_DATA_REQUEST,
        payload: {
            sessionId,
            encodedTargetMarketCriteria,
            tabId,
        },
    };
}

/** ProductDetailsDataSuccessAction **/

export interface ProductDetailsDataSuccessAction extends Action {
    payload: {
        sessionId: string;
        data: Data,
        encodedTargetMarketCriteria?: string;
    };
}

export function productDetailsDataSuccess(
    sessionId: string, data: Data,
    encodedTargetMarketCriteria?: string,
): ProductDetailsDataSuccessAction {
    return {
        type: ProductDetailsActionTypes.PRODUCT_DETAILS_DATA_SUCCESS,
        payload: {
            sessionId,
            data,
            encodedTargetMarketCriteria,
        },
    };
}

/** ProductDetailsDataFailureAction **/

export interface ProductDetailsDataFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function productDetailsDataFailure(error: AppError): ProductDetailsDataFailureAction {
    return {
        type: ProductDetailsActionTypes.PRODUCT_DETAILS_DATA_FAILURE,
        payload: {
            error,
        },
    };
}

/** ProductDetailsValuesRequestAction **/

export interface ProductDetailsValuesRequestAction extends Action {
    payload: {
        sessionId: string,
        tabId: string,
        encodedTargetMarketCriteria?: string;
    };
}

export function productDetailsValuesRequest(
    sessionId: string,
    tabId: string,
    encodedTargetMarketCriteria?: string,
): ProductDetailsValuesRequestAction {
    return {
        type: ProductDetailsActionTypes.PRODUCT_DETAILS_VALUES_REQUEST,
        payload: {
            sessionId,
            tabId,
            encodedTargetMarketCriteria,
        },
    };
}

/** ProductDetailsValuesSuccessAction **/

export interface ProductDetailsValuesSuccessAction extends Action {
    payload: {
        sessionId: string;
        values: FormValues,
        encodedTargetMarketCriteria?: string;
    };
}

export function productDetailsValuesSuccess(
    sessionId: string, values: FormValues,
    encodedTargetMarketCriteria?: string,
): ProductDetailsValuesSuccessAction {
    return {
        type: ProductDetailsActionTypes.PRODUCT_DETAILS_VALUES_SUCCESS,
        payload: {
            sessionId,
            values,
            encodedTargetMarketCriteria,
        },
    };
}

/** ProductDetailsValuesFailureAction **/

export interface ProductDetailsValuesFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function productDetailsValuesFailure(error: AppError): ProductDetailsValuesFailureAction {
    return {
        type: ProductDetailsActionTypes.PRODUCT_DETAILS_VALUES_FAILURE,
        payload: {
            error,
        },
    };
}

/** UpdatedProductDetailsDataRequestAction **/

export interface UpdatedProductDetailsDataRequestAction extends Action {
    payload: {
        sessionId: string,
        tabId: string,
        encodedTargetMarketCriteria?: string;
    };
}

export function updatedProductDetailsRequestData(
    sessionId: string,
    tabId: string,
    encodedTargetMarketCriteria?: string,
): UpdatedProductDetailsDataRequestAction {
    return {
        type: ProductDetailsActionTypes.UPDATED_PRODUCT_DETAILS_DATA_REQUEST,
        payload: {
            sessionId,
            tabId,
            encodedTargetMarketCriteria,
        },
    };
}

/** UpdatedProductDetailsDataSuccessAction **/

export interface UpdatedProductDetailsDataSuccessAction extends Action {
    payload: {
        sessionId: string;
        data: Data,
        encodedTargetMarketCriteria?: string;
    };
}

export function updatedProductDetailsDataSuccess(
    sessionId: string,
    data: Data,
    encodedTargetMarketCriteria?: string,
): UpdatedProductDetailsDataSuccessAction {
    return {
        type: ProductDetailsActionTypes.UPDATED_PRODUCT_DETAILS_DATA_SUCCESS,
        payload: {
            sessionId,
            data,
            encodedTargetMarketCriteria,
        },
    };
}

/** UpdatedProductDetailsDataFailureAction **/

export interface UpdatedProductDetailsDataFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function updatedProductDetailsDataFailure(error: AppError): UpdatedProductDetailsDataFailureAction {
    return {
        type: ProductDetailsActionTypes.UPDATED_PRODUCT_DETAILS_DATA_FAILURE,
        payload: {
            error,
        },
    };
}

// <editor-fold desc="-- FactsheetUniverseData --">

/** LoadFactsheetUniverseDataAction **/
export interface LoadFactsheetUniverseDataAction extends Action {
    payload: {
        sessionId: string;
    };
}

export function loadFactsheetUniverseData(sessionId: string): LoadFactsheetUniverseDataAction {
    return {
        type: ProductDetailsActionTypes.LOAD_FACTSHEET_UNIVERSE_DATA,
        payload: {
            sessionId,
        },
    };
}

/** LoadFactsheetUniverseDataSucceededAction **/
export interface LoadFactsheetUniverseDataSucceededAction extends Action {
    payload: {
        sessionId: string;
        data: FactsheetUniverseData;
    };
}

export function loadFactsheetUniverseDataSucceeded(sessionId: string, data: FactsheetUniverseData): LoadFactsheetUniverseDataSucceededAction {
    return {
        type: ProductDetailsActionTypes.LOAD_FACTSHEET_UNIVERSE_DATA_SUCCEEDED,
        payload: {
            sessionId,
            data,
        },
    };
}

/** LoadFactsheetUniverseDataFailedAction **/
export interface LoadFactsheetUniverseDataFailedAction extends Action {
    payload: {
        sessionId: string;
        error: AppError;
    };
}

export function loadFactsheetUniverseDataFailed(sessionId: string, error: AppError): LoadFactsheetUniverseDataFailedAction {
    return {
        type: ProductDetailsActionTypes.LOAD_FACTSHEET_UNIVERSE_DATA_FAILED,
        payload: {
            sessionId,
            error,
        },
    };
}

// </editor-fold>

export const loadChangelog = createAction(
    '[Product Details] Load Changelog',
    props<{ productDq: string }>()
);

export const setChangelog = createAction(
    '[Product Details] Set Changelog',
    props<{ changelog: ProductChangelog }>()
);

export const filterChangelog = createAction(
    '[Product Details] Filter Changelog',
    props<{ 
        filter: Partial<{
            user: string,
            startDate: Date,
            endDate: Date
        }>
    }>()
);
