import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-current-page-indicator',
    templateUrl: './current-page-indicator.component.html',
    styleUrls: ['./current-page-indicator.component.scss'],
    standalone: true,
    imports: [AsyncPipe]
})
export class CurrentPageIndicatorComponent implements OnInit {
    @HostBinding('class.current-page-indicator') public currentPageIndicatorClass = true;
    public pageTitle$: Observable<string>;

    constructor(private router: Router, private route: ActivatedRoute) {}

    /**
     * Creates the observable that calculates the current page title from the route data on component initialization
     */
    public ngOnInit() {
        this.pageTitle$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => {
                let title = '';
                let currentRoute = this.route.snapshot;
                while (currentRoute.children.length > 0) {
                    for (const child of currentRoute.children) {
                        if (child.outlet === PRIMARY_OUTLET) {
                            if (child.data.title) {
                                title = child.data.title;
                            }
                            currentRoute = child;
                            break;
                        }
                    }
                }
                return title;
            }),
        );
    }
}
