<div [formGroup]="form" class="form-group row">
    <div class="col-5">
        <div class="custom-controls-stacked">
            <label class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" [attr.disabled]="disabled ? '' : null" formControlName="enabled">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description switchable-dropdown-input__label">
                      {{ config?.options?.label }}
                </span>
            </label>
        </div>
    </div>

    <div class="col-4">
        <select
            class="custom-select"
            formControlName="operator">
            <option [value]="null" disabled style="display: none;">-</option>
            <option *ngFor="let value of values" [value]="value.id">{{value.label}}</option>
        </select>
    </div>
    <div class="col-2"
         [class.has-danger]="!disabled && !form.get('value')?.valid">
        <app-number-input class="form-control" formControlName="value"></app-number-input>
    </div>
    <div class="col-1">
        <span *ngIf="config?.options?.unit" class="switchable-dropdown-input__unit">
            {{ config?.options?.unit }}
        </span>
    </div>
</div>
