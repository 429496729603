export type Shortcuts = {
    [key in ShortcutType]?: Array<Shortcut>;
};

export interface BaseShortcut {
    searchText: string;
    link: Array<any>;
}

export interface ModuleShortcut extends BaseShortcut {
    moduleId: string;
    moduleType: string;
    label: string;
}

export interface WatchlistShortcut extends BaseShortcut {
    watchlistId: string;
    watchlistName: string;
    watchlistDescription: string;
}

export type Shortcut = ModuleShortcut & WatchlistShortcut;

export enum ShortcutType {
    Module = 'MODULE',
    Watchlist = 'WATCHLIST'
}
