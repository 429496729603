/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 5/4/17.
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthStoreService } from '../services/auth-store.service';
import { ConfigurationService } from '../services/configuration.service';
import { LogglyLoggerService } from '../services/loggly-logger.service';
import { AuthenticatedApi } from './authenticated.api';
import { TokenUser } from './models/me.model';

/**
 * API abstraction layer for the User API
 */
@Injectable({
    providedIn: 'root',
})
export class AuthUserApi extends AuthenticatedApi {
    constructor(http: HttpClient, authStoreService: AuthStoreService, loggerService: LogglyLoggerService, private appConfigService: ConfigurationService) {
        super(appConfigService.configuration.api.duse.baseUrl, http, authStoreService, loggerService);
    }

    public getAuthenticatedUser(): Observable<TokenUser> {
        return this.get(`auth/token/me?appId=derifin&envId=${this.appConfigService.configuration.environment.id}`).pipe(
            map((response) => {
                return {
                    email: response.email,
                    username: response.username,
                    firstname: response.firstname,
                    lastname: response.lastname,
                    gender: response.gender,
                    id: response.id,
                    appData: {
                        smallMode: get(response, 'appData.smallMode', false),
                    },
                    organization: response.organization,
                    roles: response.roles,
                };
            }),
        );
    }

    public updatePassword(changePassword: {
        oldPassword: string;
        newPassword: string;
        newRepeatedPassword: string;
    }): Observable<any> {
        return this.post('auth/token/me/resetPassword', changePassword);
    }

    public getPasswordRules() {
        return this.get('auth/token/me/password-complexity');
    }
}
