import { Injectable } from '@angular/core';
import { camelCase, get } from 'lodash';
import { marked } from 'marked';

import { Decorator } from '../../shared/models/decorators.model';
import { Entity } from '../../shared/models/entities.model';
import { LocaleFormatterService } from './locale-formatter.service';

@Injectable()
export class EntityTableDecoratorService {
    private NOT_AVAILABLE = '&mdash;';
    private multiIndex = 0;

    constructor(private readonly localeFormatter: LocaleFormatterService) {
    }

    public getDataFromPath(entity: Entity, path: string, defaultValue?: any): any {
        if (path && path.includes('[]')) {
            path = path.replace('[]', `[${this.multiIndex}]`);
        }

        return get(entity, path, defaultValue);
    }

    /**
     * Decorator function that only emits a string representation of data
     */
    public plain(data, context: Entity, options): string {
        if (data === undefined || data === null) {
            return null;
        }

        return data.toString();
    }

    /**
     * Decorator function that decorates the input as a link
     */
    public link(data: string | { url: string; label: string }, context: Entity, options): string {
        let url;
        let label;

        if (typeof data === 'string') {
            url = data;
            label = options.label ? options.label : data;
        } else {
            url = data.url;
            label = data.label;
        }
        return `<a href="${url}">${label}</a>`;
    }

    /**
     * Decorator function that decorates the input as a date
     */
    public date(data: any, context: Entity, options): string {
        return this.localeFormatter.transformDate(data, options.format);
    }

    /**
     * Decorator function the decorates the input as a date range
     */
    public dateRange(data: any, context: Entity, options): string {
        const startDate = data;
        const endDate = get(context, options.endDatePath);

        const transformedStartDate = this.date(startDate, context, options);
        const transformedEndDate = this.date(endDate, context, options);

        return `${transformedStartDate} - ${transformedEndDate}`;
    }

    /**
     * Decorator function that decorates the input as a decimal number
     */
    public decimal(data: any, context: Entity, options): string {
        return this.localeFormatter.transformDecimal(data, options.format);
    }

    /**
     * Decorator function that decorates the input as a percentage
     */
    public percent(data: any, context: Entity, options): string {
        return this.localeFormatter.transformPercent(data, options.format);
    }

    /**
     * Decorator function that decorates the input as a currency value
     */
    public currency(data: number, context: Entity, options): string {
        if (data === null || data === undefined) {
            return this.NOT_AVAILABLE;
        } else {
            const decimal = this.decimal(data, context, options);
            const currency = this.getDataFromPath(context, options.currencyPath, '');

            return `${decimal}&nbsp;${currency}`;
        }
    }

    /**
     * Decorator function that decorates the input as one of two specified availableValues (truthy and falsy)
     */
    public boolean(data: boolean, context: Entity, options): string {
        switch(data) {
            case true:
                return options.truthy;
            case false:
                return options.falsy;
            default:
                return options.nully;
        }
    }

    public markdown(data: string, context: Entity, options): string {
        return marked(data, {
            mangle: false,
            headerIds: false
        });
    }

    public applyDecorator(name: string, entity: Entity | any, path: string, options?: any, multiIndex = 0): string {
        let decorator: Decorator;

        name = camelCase(name);

        if (this[name]) {
            decorator = this[name];
        } else {
            decorator = this.plain;
        }

        options = options || {};

        this.multiIndex = multiIndex;

        const data = this.getDataFromPath(entity, path);
        if (
            name === 'composite' ||
            (data !== undefined && data !== null) ||
            ((data === null || data === undefined) && name === 'boolean')
        ) {
            try {
                return decorator.bind(this)(data, entity, options);
            } catch (e) {
                return this.NOT_AVAILABLE;
            }
        } else {
            return this.NOT_AVAILABLE;
        }
    }
}
