<div class="container">
    <app-issue-message></app-issue-message>
    <app-header-bar></app-header-bar>
    <router-outlet name="slide-down"></router-outlet>
    <app-ad-for-default-layout *ngIf="(overlayOpen$ | async) === false"></app-ad-for-default-layout>

    <app-inline-menu *ngIf="inlineMenuConfig$ | async as menuConfig" [menu]="menuConfig"></app-inline-menu>
    <router-outlet></router-outlet>
</div>
<app-main-menu [menu]="menu$ | async" [@menuState]="'visible'" *ngIf="menuVisible$ | async"></app-main-menu>
<router-outlet name="overlay"></router-outlet>
<app-scroll-top-button></app-scroll-top-button>
