import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { get } from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { TargetMarketCustomCustomer } from '../../../routes/target-market/models/target-market-customer.model';
import { truthy } from '../../../shared/helpers/general.helper';
import { getFirstMenuItemLink } from '../../helpers/menu.helper';
import { AuthService } from '../../services/auth.service';
import { ConfigurationService } from '../../services/configuration.service';
import { MenuService } from '../../services/menu.service';
import { UiService } from '../../services/ui.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/state/index.state';
import { AuthStoreService } from '../../services/auth-store.service';
import { StringsService } from '../../services/strings.service';
import * as CustomerSelectionSelectors from '../../../routes/customer-selection/store/selectors/customer-selection.selectors';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { LabelParams } from '../../../routes/customer-selection/store/state/customer-selection.state';
import * as SearchActions from '../../store/actions/search.actions';
import * as UserSelectors from '../../store/selectors/user.selectors';
import { Shortcuts } from '../../api/models/user.model';
import { GroupedResults } from '../../models/search.model';
import * as SearchSelectors from '../../store/selectors/search.selectors';
import { ProductSelectionIndicatorComponent } from '../../../routes/shared/product-shared/components/product-selection-indicator/product-selection-indicator.component';
import { IconComponent } from '../../../shared/components/icon/icon.component';
import { SearchComponent } from '../search/search.component';
import { NgIf, AsyncPipe } from '@angular/common';

/**
 * Component that holds the header bar
 */
@Component({
    selector: 'app-header-bar',
    templateUrl: './header-bar.component.html',
    styleUrls: ['./header-bar.component.scss'],
    standalone: true,
    imports: [RouterLink, NgIf, SearchComponent, IconComponent, ProductSelectionIndicatorComponent, AsyncPipe, TranslocoModule]
})
export class HeaderBarComponent implements OnInit  {
    public canSelectCustomer$: Observable<boolean>;
    public activatedCustomer$: Observable<any>;
    public activatedLabel$: Observable<string>;
    public activatedLabelParams$: Observable<LabelParams>;
    public activatedLink$: Observable<any[]>;
    public profileLink$: Observable<any[]>;
    public logoUrl$: Observable<any[]>;
    public logoUrl: string;
    public activatedTargetMarketReferenceCustomer$: Observable<TargetMarketCustomCustomer>;
    public displayMenuTitle$: Observable<boolean>;
    public displaySearch$: Observable<boolean>;
    public displayUsername$: Observable<boolean>;
    public profileMenuLinkLabel$: Observable<string>;
    public logoImgUrl$: Observable<string>;

    public products$: Observable<Array<GroupedResults>>
    public shortcuts$: Observable<Shortcuts>;

    constructor(
        private uiService: UiService,
        private authService: AuthService,
        private authStore: AuthStoreService,
        private router: Router,
        private translocoService: TranslocoService,
        private menuService: MenuService,
        private configurationService: ConfigurationService,
        private store: Store<AppState>,
        private readonly stringsService: StringsService
    ) {
        this.displayMenuTitle$ = this.configurationService.displayMenuTitle$;
        this.displaySearch$ = this.configurationService.displaySearch$;
        this.displayUsername$ = this.configurationService.displayUsername$;

        this.profileMenuLinkLabel$ = combineLatest([
            this.store.pipe(select('auth')),
            this.displayUsername$
        ]).pipe(
            switchMap(([authState, displayUsername]) => {
                const displayName = (get(authState.authUser, 'firstname', '') + ' ' + get(authState.authUser, 'lastname', '')).trim();

                if (displayUsername) {
                    return of(displayName);
                } else {
                    return this.translocoService.selectTranslate('profile.menuLink');
                }
            })
        );

        this.logoImgUrl$ = this.configurationService.getLogoImageUrl();

        const getSlideDownOutletRoute = () => {
            const urlTree = this.router.parseUrl(this.router.url);
            const slideDownOutlet = urlTree.root.children.primary.children['slide-down'];

            return get(slideDownOutlet, 'segments[0].path', '');
        };

        this.profileLink$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            startWith(null as NavigationEnd),
            map(getSlideDownOutletRoute),
            map((slideDownOutletRoute) => {
                if (slideDownOutletRoute === 'profile') {
                    return ['/app', {outlets: {'slide-down': null}}];
                } else {
                    return [
                        '/app',
                        {
                            outlets: {
                                'slide-down': ['profile'],
                            },
                        },
                    ];
                }
            }),
        );

        this.activatedLink$ = this.store.select(CustomerSelectionSelectors.selectActivatedLink);

        this.logoUrl$ = this.menuService.getMenuConfig().pipe(
            filter(truthy),
            map((menuConfig) => getFirstMenuItemLink(menuConfig)),
        );

        this.canSelectCustomer$ = this.menuService.isTmcSelectable();

        this.products$ = this.store.select(SearchSelectors.selectProducts);
        this.shortcuts$ = this.store.select(UserSelectors.selectShortcuts);
    }

    ngOnInit(): void {
        this.activatedCustomer$ = this.store.select(CustomerSelectionSelectors.selectActivatedCustomer);
        this.activatedLabelParams$ = this.store.select(CustomerSelectionSelectors.selectActivatedLabelParams);
        this.activatedLabel$ = this.store.select(CustomerSelectionSelectors.selectActivatedLabel);

        this.stringsService.loadStringsByPath('targetMarket');
    }

    public onTermSearch(term: string): void {
        this.store.dispatch(SearchActions.loadSolrResults({ term }));
    }

    /**
     * Opens the menu when the user clicks the menu button
     */
    public onMenuButtonClicked(e: Event): boolean {
        e.preventDefault();
        this.uiService.openMenu();
        return false;
    }

    /**
     * Signs out the user when the sign out button is clicked
     */
    public onSignOutButtonClicked(e: Event): boolean {
        e.preventDefault();
        this.authService.signOut();
        return false;
    }

    /**
     * Load the auth user
     * @param e
     */
    public onLoadAuthUserClicked(e: Event) {
        e.preventDefault();
        this.authService.loadAuthUser();
        return false;
    }
}
