import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterLinkActive, RouterLink } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { delay, filter, map, startWith, tap } from 'rxjs/operators';
import { MenuItem } from '../../../core/models/menu.model';
import { SubmenuItemComponent } from '../submenu-item/submenu-item.component';
import { IconComponent } from '../icon/icon.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss'],
    animations: [
        trigger('submenuState', [
            state('void', style({
                height: 0,
                opacity: 0,
            })),
            state('default', style({
                height: '*',
                opacity: 1,
            })),
            transition('void => *', animate('250ms ease-out')),
            transition('* => void', animate('250ms ease-out')),
        ]),
    ],
    standalone: true,
    imports: [RouterLinkActive, RouterLink, NgIf, IconComponent, NgFor, SubmenuItemComponent, AsyncPipe]
})
export class MenuItemComponent implements OnInit, OnDestroy {
    @Input() public item: MenuItem;
    @Input() public active: boolean;
    @Input() public showCurrent: boolean;
    @Input() public expandActiveSubmenu: boolean;
    @Output() public submenuItemClicked: EventEmitter<number> = new EventEmitter();
    @Output() public menuItemClick: EventEmitter<MouseEvent> = new EventEmitter();

    @ViewChild('rla', {static: true}) public routerLink: RouterLinkActive;

    @HostBinding('class.menu-item') public menuItemClass = true;
    @HostBinding('class.menu-item--active') public menuItemActiveClass;
    public hasActiveSubmenu$: Observable<boolean>;
    public hasActiveSubmenu = false;
    private subscription: Subscription;

    constructor(private router: Router, private cdf: ChangeDetectorRef) {
        this.menuItemActiveClass = this.active;

        this.hasActiveSubmenu$ = this.router.events.pipe(
            filter((s) => s instanceof NavigationEnd),
            startWith(null as NavigationEnd),
            map(() => {
                return this.item.hasSubmenu && this.item.children && this.item.children.reduce((activeSubmenu, item) => {
                    return activeSubmenu || (item.link && this.router.isActive(item.link.join('/'), false));
                }, false);
            }),
            delay(0),
            tap((active) => {
                this.hasActiveSubmenu = active;
                this.cdf.detectChanges();
            }),
        );
    }

    @HostBinding('class.menu-item--current')
    get isRouterLinkActive() {
        return this.showCurrent && ((this.routerLink.isActive && this.item && this.item.link) || this.hasActiveSubmenu);
    }

    public ngOnInit() {
        this.subscription = this.hasActiveSubmenu$.subscribe(() => {
        });
    }

    public onSubmenuItemClicked(e: MouseEvent, subitemIndex: number) {
        e.preventDefault();
        this.submenuItemClicked.next(subitemIndex);
        return false;
    }

    public handleMenuClick(e) {
        e.preventDefault();

        this.menuItemClick.next(e);

        if (this.item && this.item.link) {
            this.router.navigate(this.item.link);
        }

        return false;
    }

    public ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
