import { DOCUMENT } from '@angular/common';
import {
    Component,
    HostListener,
    Inject,
    OnDestroy,
    OnInit,
    Renderer2
} from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subscription, timer } from 'rxjs';
import { environment } from '../environments/environment';
import { NewVersionToastComponent } from './core/toasts/new-version-toast/new-version-toast.component';
import { ConfigurationService } from './core/services/configuration.service';
import { LocaleFormatterService } from './core/services/locale-formatter.service';
import { UiService } from './core/services/ui.service';
import * as IssueMessageActions from './core/store/actions/issue-message.action';
import { filter, first, take } from 'rxjs/operators';
import * as UIAction from './core/store/actions/ui.action';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    public production = environment.production;

    constructor(
        private translocoService: TranslocoService,
        private localeFormatter: LocaleFormatterService,
        private uiService: UiService,
        private appConfigService: ConfigurationService,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document,
        private store: Store,
        private swUpdate: SwUpdate,
        private toastrService: ToastrService
    ) {
        const environmentLocale = this.appConfigService.configuration.environment.translationId;

        if (environmentLocale) {
            this.document.documentElement.lang = environmentLocale;
            this.translocoService.setActiveLang(environmentLocale);
            this.localeFormatter.use(environmentLocale);
        } else {
            this.document.documentElement.lang = this.translocoService.getDefaultLang();
            this.localeFormatter.use(this.translocoService.getDefaultLang());
        }

        this.setupUpdate();
    }

    public ngOnInit(): void {
        const link = this.renderer.createElement('link');

        this.renderer.setProperty(link, 'href', `${this.appConfigService.configuration.theme}.theme.css`);
        this.renderer.setProperty(link, 'rel', 'stylesheet');
        this.renderer.appendChild(this.document.head, link);
        this.renderer.setAttribute(this.document.documentElement, 'data-theme', this.appConfigService.configuration.theme);

        this.subscription = this.uiService.isOverlayOpen().subscribe((overlayOpen) => {
            if(overlayOpen) {
                this.renderer.addClass(this.document.body, 'overlay-open');
            } else {
                this.renderer.removeClass(this.document.body, 'overlay-open');
            }
        });

        timer(0, 5 * 60 * 1000).subscribe(() =>
            this.store.dispatch(IssueMessageActions.getIssueMessage())
        );
    }

    public ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public setupUpdate(): void {
        if (this.swUpdate.isEnabled) {
            timer(10 * 1000, 5 * 60 * 1000).subscribe(() =>
                this.swUpdate.checkForUpdate()
            );

            this.swUpdate.versionUpdates
                .pipe(
                    filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
                    take(1)
                )
                .subscribe(() =>
                    this.toastrService.show(undefined, undefined, {
                        toastComponent: NewVersionToastComponent,
                        disableTimeOut: true
                    }).onAction.pipe(first()).subscribe(() =>
                        document.location.reload()
                    )
                );

            this.swUpdate.unrecoverable.subscribe(() =>
                document.location.reload()
            );
        }
    }

    @HostListener('document:visibilitychange', ['$event'])
    public visibilityChange(): void {
        if (this.document.hidden) {
            this.store.dispatch(
                UIAction.setPageVisibility({
                    visible: false
                })
            );
        } else {
            this.store.dispatch(
                UIAction.setPageVisibility({
                    visible: true
                })
            );
        }
    }
}
