<div class="row">
    <div class="col justify-content-center entity-widget-mischprodukte__graph">
        <app-advisor-baskets-chart
            [data]="chartData"
            [size]="[400, 300]"
            [margin]="30"
            [grayscale]="false"
            [disableTransitions]="true"
        ></app-advisor-baskets-chart>
        <div class="entity-widget-mischprodukte__summary">
            <span>{{ 'widgets.mischproduktFractions.sumCheckLabel' | transloco }}: {{ anteileSum / 100 | translocoPercent }}</span>
            <span class="assets-deviation-summary__icon" [class.success]="formValidation()">
                <ng-container *ngIf="formValidation() else: invalidIcon">
                    <app-icon icon="material:check"></app-icon>
                </ng-container>
            </span>
            <ng-template #invalidIcon>
                <app-icon icon="material:cancel"></app-icon>
            </ng-template>
        </div>
    </div>

    <div class="col">
        <div class="alert alert-info" style="font-size: 0.8rem; margin-bottom: 0" *ngIf="!editable">
            {{ 'widgets.mischproduktFractions.kvgMessage' | transloco }}
        </div>
        <table class="table entity-details-table__table">
            <tbody>
            <tr *ngFor="let anteil of anteileValues; let i=index">
                <th class="entity-details-table__line-label entity-details-table-cell--middle" colspan="1" width="200">{{anteil.label}}</th>
                <th class="entity-details-table-cell entity-details-table-cell--middle pr-4" width="70">
                    <app-number-input [disabled]="!editable" [tabindex]="i + 1" [min]="0" [max]="100" class="form-control" [ngModel]="sliderData[anteil.id]" (ngModelChange)="onChangeAnteilValueInput($event, anteil.id, slider)"></app-number-input>
                </th>
                <td class="entity-details-table-cell">
                    <app-no-ui-slider class="slider-round slider-styled" [min]="0" [max]="100" [(ngModel)]="sliderData[anteil.id]"
                                [disabled]="!editable" [tooltips]="[formatter]" (end)="onChangeAnteilValue($event, anteil.id)"
                                (start)="onStartSliderChange(slider)" (slide)="onStartSliderChange(slider)" #slider>
                    </app-no-ui-slider>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="absolute top-2 right-2">
    <button
        type="button"
        chroma-button
        appearance="outline"
        size="small"
        *ngIf="!showInputConfirm && editable"
        (click)="onClickReset()">{{ 'widgets.mischproduktFractions.deactivateButton' | transloco }}</button>
</div>
<div class="mischprodukte__overlay" *ngIf="showInputConfirm && editable">
    <button
        type="button"
        chroma-button
        (click)="onClickConfirm()">{{ 'widgets.mischproduktFractions.activateButton' | transloco }}</button>
</div>
