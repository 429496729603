import { Component, HostBinding, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductSelectionService } from '../../../../product-selection/services/product-selection.service';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf, AsyncPipe } from '@angular/common';
import { IconComponent } from '../../../../../shared/components/icon/icon.component';

@Component({
    selector: 'app-product-selection-indicator',
    templateUrl: './product-selection-indicator.component.html',
    styleUrls: ['./product-selection-indicator.component.scss'],
    standalone: true,
    imports: [IconComponent, NgIf, AsyncPipe, TranslocoModule]
})
export class ProductSelectionIndicatorComponent implements OnInit {

    @HostBinding('class.product-selection-indicator') public productSelectionIndicatorClass = true;

    public selectedProductCount$: Observable<number>;

    constructor(private productSelectionService: ProductSelectionService) {
        this.selectedProductCount$ = this.productSelectionService.getNumberOfSelectedProducts();
    }

    public ngOnInit() {
    }

}
