import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { ConfigurationWindow } from '../../../declarations';
import { Configuration } from '../../shared/models/configuration';
import { AppState } from '../store/state/index.state';
import { filter, map } from 'rxjs/operators';
import { truthy } from '../../shared/helpers/general.helper';
import { includes } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {
    public configuration: Configuration;

    public displayMenuTitle$ = this.store.select((state) => {
        return state.config.uiFeatureFlags && includes(state.config.uiFeatureFlags, 'DISPLAY_MENU_TITLE');
    });

    public displaySearch$ = this.store.select((state) => {
        return state.config.uiFeatureFlags && !includes(state.config.uiFeatureFlags, 'DISABLE_GLOBAL_PRODUCT_SEARCH_BOX');
    });

    public displayUsername$ = this.store.select((state) => {
        return state.config.uiFeatureFlags && includes(state.config.uiFeatureFlags, 'DISPLAY_USERNAME');
    });

    constructor(private store: Store<AppState>) {
        this.configuration = (window as unknown as ConfigurationWindow).configuration;
    }

    public getMercuryEnvironmentUrl(): Observable<string> {
        return this.store.select((state) => state.config.mercuryEnvironmentUrl);
    }

    public getLogoImageUrl(type = 'logo'): Observable<string> {
        const logoUrl = this.configuration.environment.assets[type];

        if(!!this.configuration.environment.logoUseCustomUser) {
            return this.store.pipe(
                select((state) => state.auth.authUser),
            ).pipe(
                filter(truthy),
                map((user) => {
                    if(this.configuration.environment.logoUserMap[user.id]) {
                        return this.configuration.environment.logoUserMap[user.id][type]
                    } else {
                        return logoUrl;
                    }
                })
            );
        } else {
            return of(logoUrl);
        }
    }
}
