/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 5/4/17.
 */

import { Action } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { of } from 'rxjs';
import { AuthErrors } from '../errors/auth.errors';
import { AppError } from '../errors/base.errors';
import { refreshToken } from '../store/actions/auth.action';

export function handleAuthenticationError(err: AppError, originalAction: Action): Observable<Action> {
    if (err.errorCode === AuthErrors.TOKEN_EXPIRED) {
        return of(refreshToken(originalAction));
    } else {
        return throwError(err);
    }
}
