import { Component, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormControlConfig, FormValues } from '../../../core/models/form.model';
import { SingleValueFilterControl } from '../filter-control';
import { NumberInputComponent } from '../number-input/number-input.component';
import { NgFor, NgIf } from '@angular/common';

/**
 * Filter component that can be switched on or off with an operator dropdown and a number input
 */
@Component({
    selector: 'app-filter-switchable-dropdown-input',
    templateUrl: './filter-switchable-dropdown-input.component.html',
    styleUrls: ['./filter-switchable-dropdown-input.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgFor, NumberInputComponent, NgIf]
})
export class FilterSwitchableDropdownInputComponent implements OnInit, OnChanges, OnDestroy, SingleValueFilterControl<any> {

    @HostBinding('class.switchable-dropdown-input') public switchableDropdownInputClass = true;

    @Input() public config: FormControlConfig<FilterSwitchableDropdownInputOptions>;

    @Input() public values: any;

    @Input() public disabled = true;

    public onChange: (value: any) => void;

    public onTouched: () => void;

    public form: UntypedFormGroup;

    public valueChangesSubscription: Subscription;

    constructor(private fb: UntypedFormBuilder) {
    }

    public ngOnInit() {
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.config && this.config) {
            this._updateForm();
        }
    }

    public ngOnDestroy() {
        if (this.valueChangesSubscription) { this.valueChangesSubscription.unsubscribe(); }
    }

    public writeValue(values: FormValues): void {
        if (values) {
            this.form.patchValue(values, {emitEvent: false});
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = (value) => {
            if (fn) {
                fn(value);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    /**
     * Checks if the filter is valid
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        return (this.form.valid) ? null : {
            switchableDropdownInputError: 'Invalid filter state specified',
        };
    }

    public setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    /**
     * Update the form group of this control
     * @private
     */
    private _updateForm() {
        if (this.valueChangesSubscription) { this.valueChangesSubscription.unsubscribe(); }

        const fields = {
            enabled: [false],
            operator: [this.config.options.defaultOperator ? this.config.options.defaultOperator : null],
            value: [null],
        };

        const oldValue = (this.form) ? this.form.value : undefined;

        this.form = this.fb.group(fields);

        if (oldValue) { this.form.patchValue(oldValue); }

        this.valueChangesSubscription = this.form.valueChanges.subscribe((value) => this.onChange(value));
    }

}

export interface FilterSwitchableDropdownInputOptions {
    label: string;
    unit?: string;
    defaultOperator?: string;
}
