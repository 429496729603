<nav class="admin-header-bar">
    <a class="admin-header-bar__logo" [routerLink]="['/']">
        <div class="admin-header-bar__logo__image_wrapper">
            <img [src]="logoImgUrl$ | async" alt="Logo"/>
        </div>
    </a>
    <div  class="admin-header-bar__left">
        <a class="admin-header-bar__link header-bar__logout-button" href="#"
           (click)="onSignOutButtonClicked($event)">
            <span class="header-bar__logout-button-label">{{ 'logout' | transloco }}</span>
            <app-icon class="header-bar__logout-button-icon" icon="derifin:log-out"></app-icon>
        </a>

        <a class="admin-header-bar__link header-bar__profile-button"
           [routerLink]="profileLink$ | async">
            <span class="header-bar__profile-button-label">{{ profileMenuLinkLabel$ | async }}</span>
            <app-icon [icon]="'material:account'"></app-icon>
        </a>
    </div>
    <div  class="admin-header-bar__right">
        <a href="#" class="admin-header-bar__link header-bar__menu-button icon-link"
           (click)="onMenuButtonClicked($event)">
            <app-icon icon="derifin:hamburger"></app-icon>
        </a>
    </div>
</nav>
