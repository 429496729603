<div class="card banners" *ngIf="ad">
    <ng-container *ngFor="let banner of ad.banners; index as i; count as count" [ngSwitch]="banner.fileType">
        <ng-container *ngIf="i === count - 1">
            <ng-container *ngSwitchCase="'IMAGE'">
                <a
                    *ngIf="(authUserId$ | async) as userId"
                    class="flex justify-center"
                    [href]="link(ad.targetLinkUrl, environment, organization, userId)"
                    target="_blank">
                    <img [alt]="banner.alternativeText" [src]="resolveImage(banner.fileId)">
                </a>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
