<div
    class="search"
    cdkOverlayOrigin
    [ngClass]="{'search__show-above-overlay' : triggerOrigin && (overlayOpen$ | async)}"
    #trigger="cdkOverlayOrigin">
    <app-icon
        class="search__icon"
        icon="material:magnify">
    </app-icon>
    <input
        [formControl]="searchControl"
        class="form-control search__input"
        placeholder="Suche..."
        type="text"
        (click)="onInputClick(trigger)"/>
    <app-spinner
        *ngIf="searching$ | async"
        class="search__spinner"
        type="circle-box">
    </app-spinner>
</div>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="triggerOrigin && (overlayOpen$ | async)"
    [cdkConnectedOverlayHasBackdrop]="true"
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
    (backdropClick)="closeOverlay()"
    (overlayKeydown)="onOverlayKeydown($event)">
    <div class="search-results">
        <ng-container *ngFor="let group of products">
            <div class="search-results__group">
                <div
                    *ngIf="showGroupName"
                    class="search-results__group-title">{{ 'search.instrumentType.' + group.instrumentType | transloco }}</div>
                <ng-container [ngSwitch]="group.instrumentType">
                    <ng-container *ngSwitchCase="instrumentType.Bond">
                        <div class="list-group search-results__group-list">
                            <ng-container *ngFor="let result of group.results">
                                <app-search-result [link]="result.link">
                                    <div>{{ result.name }}</div>
                                    <div class="search-results__group-list__item-caption">{{ result.wkn }} / {{ result.isin }} | {{ result.subTypeDE }}</div>
                                </app-search-result>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="instrumentType.Fund">
                        <div class="list-group search-results__group-list">
                            <ng-container *ngFor="let result of group.results">
                                <app-search-result
                                    [actionType]="actionType"
                                    [link]="result.link"
                                    [id]="result.id"
                                    (selectId)="onSelectId($event)">
                                    <div>{{ result.name }}</div>
                                    <div class="search-results__group-list__item-caption">{{ result.wkn }} / {{ result.isin }} | {{ result.subTypeDE }} | {{ result.issuer }}</div>
                                </app-search-result>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="instrumentType.Derivate">
                        <div class="list-group search-results__group-list">
                            <ng-container *ngFor="let result of group.results">
                                <app-search-result [link]="result.link">
                                    <div>{{ result.name }}</div>
                                    <div class="search-results__group-list__item-caption">{{ result.wkn}} / {{ result.isin }} | {{ result.issuer }}</div>
                                </app-search-result>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="instrumentType.Stock">
                        <div class="list-group search-results__group-list">
                            <ng-container *ngFor="let result of group.results">
                                <app-search-result [link]="result.link">
                                    <div>{{ result.name }}</div>
                                    <div class="search-results__group-list__item-caption">{{ result.wkn }} / {{ result.isin }}</div>
                                </app-search-result>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="products.length === 0">
            <div
                *ngIf="showGroupName"
                class="search-results__group-title">{{ 'search.products' | transloco }}</div>
            <div class="search-results__group">
                <ul class="list-group search-results__group-list">
                    <li class="list-group-item px-0 border-0">
                        <p>{{ 'search.noProducts.title' | transloco }}</p>
                        <ng-container [ngSwitch]="notifyOnProductActivation$ | async">
                            <button
                                *ngSwitchCase="notifyOnProductActivation.Request"
                                type="button"
                                class="btn btn-link btn-block search-results__group__not-found__notify"
                                (click)="onNotifyOnProductActivationClick()">{{ 'search.noProducts.notify-on-product-activation-request' | transloco }}</button>
                            <div
                                *ngSwitchCase="notifyOnProductActivation.Loading"
                                class="w-100">
                                <app-spinner></app-spinner>
                            </div>
                            <div
                                *ngSwitchCase="notifyOnProductActivation.Requested"
                                class="search-results__group__not-found__notified">{{ 'search.noProducts.notify-on-product-activation-done' | transloco }}</div>
                        </ng-container>
                    </li>
                </ul>
            </div>
        </ng-container>
    </div>
    <div
        *ngIf="displayShortcuts"
        class="search-results">
        <div class="list-group search-results__group-list">
            <ng-container *ngFor="let shortcutsGroup of shortcuts | keyvalue">
                <div class="search-results__group">
                    <div class="search-results__group-title">{{ 'search.shortcutType.' + shortcutsGroup.key | transloco }}</div>
                    <ng-container [ngSwitch]="shortcutsGroup.key">
                        <ng-container *ngSwitchCase="shortcutType.Module">
                            <ng-container *ngFor="let shortcut of shortcutsGroup.value">
                                <app-search-result [link]="shortcut.link">
                                    <div>{{ shortcut.label }}</div>
                                </app-search-result>
                            </ng-container>
                            <ng-container *ngIf="shortcutsGroup.value.length === 0">
                                <li class="list-group-item px-0 border-0">
                                    <p>{{ 'search.noMenuEntries' | transloco }}</p>
                                </li>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="shortcutType.Watchlist">
                            <ng-container *ngFor="let shortcut of shortcutsGroup.value">
                                <app-search-result [link]="shortcut.link">
                                    <div>{{ shortcut.watchlistName }}</div>
                                </app-search-result>
                            </ng-container>
                            <ng-container *ngIf="shortcutsGroup.value.length === 0">
                                <li class="list-group-item px-0 border-0">
                                    <p>{{ 'search.noWatchlists' | transloco }}</p>
                                </li>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>
