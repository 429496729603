import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { get, has, uniq } from 'lodash';
import { timer } from 'rxjs';
import { truthy } from '../../helpers/general.helper';
import { Entity, EntitySortSetting, EntityTableColumn, EntityTableConfig } from '../../models/entities.model';
import { ColumnLabelPipe } from '../../pipes/column-label.pipe';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { EntityTableRowComponent } from '../entity-table-row/entity-table-row.component';
import { StickyDirective } from '../../directives/sticky.directive';
import { SyncColumnsWidthsDirective } from '../../directives/sync-columns-widths.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgStyle, NgIf, NgFor, NgTemplateOutlet } from '@angular/common';

export enum EntityTableSelectionState {
    NONE,
    ALL,
    SOME,
}

@Component({
    selector: 'app-entity-table',
    templateUrl: './entity-table.component.html',
    styleUrls: ['./entity-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgStyle, NgIf, NgFor, MatTooltipModule, NgTemplateOutlet, SyncColumnsWidthsDirective, StickyDirective, EntityTableRowComponent, ContentLoaderModule, ColumnLabelPipe]
})
export class EntityTableComponent implements OnInit, OnChanges {

    @ViewChild('scrollOne') scrollOne: ElementRef; 

    @Input() public config: EntityTableConfig;
    @Input() public entities: Entity[];
    @Input() public selectedEntities: string[] = [];
    @Input() public emptyMessage;
    @Input() public stickyHeader = false;
    @Input() public isLoading = false;
    @Input() public isLoadingMoreEntities = false;
    @Input() public clickable = false;

    /**
     * Legacy code, the default should be removed in the future
     * @type {number}
     */
    @Input() public stickyOffset = 0;
    @Input() public sort: EntitySortSetting;
    @Input() public noActions: boolean;
    @Input() public highlightable = true;
    @Input() public highlight: string;
    @Input() public highlightFlash: string;
    public highlightFlashState = 'none';

    public selectedEntitiesMap: { [entitiesId: string]: boolean } = {};

    @Output() public toggleSelection: EventEmitter<{ entityId: string; selected: boolean }> = new EventEmitter();
    @Output() public toggleSelections: EventEmitter<{ entityIds: string[]; selected: boolean }> = new EventEmitter();
    @Output() public remove: EventEmitter<Entity> = new EventEmitter<Entity>();
    @Output() public sortChange: EventEmitter<EntitySortSetting> = new EventEmitter();
    @Output() public componentEvent: EventEmitter<EntityEvent> = new EventEmitter();
    @Output() public highlightChangedEvent: EventEmitter<string> = new EventEmitter<string>();
    @Output() public clickedRow: EventEmitter<string> = new EventEmitter<string>();

    @Output() public tableHeaderInfo: EventEmitter<{ height: number, heading: string }> = new EventEmitter();

    public selectionState: EntityTableSelectionState;
    public entityTableSelectionState = EntityTableSelectionState;

    public hoverMultiRow: boolean   [] = [];

    constructor(private readonly cd: ChangeDetectorRef) {
    }

    get originalWeightSum() {
        return (this.config && this.config.columns) ? this.config.columns.reduce((sum, column) => {
            return sum + (column.weight || 0);
        }, 0) : 0;
    }
    get columnWeightSum() {
        const weightSum = this.originalWeightSum;

        let newWeightSum = weightSum;

        if (this.config.selectable) {
            newWeightSum = weightSum * 1.05;
        }

        if (this.config.removable) {
            newWeightSum = weightSum * 1.05;
        }

        return newWeightSum;
    }

    get selectableColumnWidth() {
        return this.originalWeightSum ? '5%' : 'auto';
    }

    public getMultiRowIndexList(entity) {
        const rowCount = this.config.pathToMultiRowCount ? get(entity, this.config.pathToMultiRowCount) : 1;
        return [...Array(rowCount).keys()];
    }

    public ngOnInit() {
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedEntities && this.selectedEntities) {
            this.selectedEntitiesMap = this.selectedEntities.reduce((map, id) => {
                map[id] = true;
                return map;
            }, {});
        }
        if (this.entities && Array.isArray(this.entities) && (changes.entities || changes.selectedEntities)) {
            const selectedEntitiesCount = this.entities.map(
                (entity) => (entity) ? this.selectedEntitiesMap[entity.id] : false).filter(truthy).length;

            this.selectionState = (selectedEntitiesCount === this.entities.length)
                ? EntityTableSelectionState.ALL
                : (selectedEntitiesCount === 0)
                    ? EntityTableSelectionState.NONE
                    : EntityTableSelectionState.SOME;
        }

        if (changes.highlightFlash && String(changes.highlightFlash) !== this.highlightFlash) {
            this.highlightFlashState = 'flash';
            this.cd.markForCheck();

            timer(500)
                .subscribe((val) => {
                    this.highlightFlashState = 'none';
                    this.cd.markForCheck();
                });
        }
    }

    public onToggleSelection(selection: { entityId: string; selected: boolean }) {
        this.toggleSelection.next(selection);
    }

    public onToggleSelectionAll() {
        const selected = this.selectionState !== EntityTableSelectionState.ALL;
        this.toggleSelections.next({
            entityIds: this.entities.map((entity) => entity.id),
            selected,
        });
    }

    public onRemove(e: Entity) {
        this.remove.next(e);
    }

    public trackByFunction(index: number, item: Entity) {
        return (item) ? item.id : index;
    }

    public onSortClicked(e: Event, column: EntityTableColumn) {
        e.preventDefault();
        const clientSort = (this.sort) ? this.sort.clientSort : true;
        if (this.sort && this.sort.path === column.sortPath) {
            this.sortChange.next({
                path: column.sortPath,
                order: (this.sort.order === 'ASC') ? 'DESC' : 'ASC',
                clientSort,
            });
        } else {
            this.sortChange.next({
                path: column.sortPath,
                order: column.initialOrder || 'ASC',
                clientSort,
            });
        }
    }

    public onComponentEvent(id: string, event: any) {
        this.componentEvent.next({
            id,
            event,
        });
    }

    public onHighlightChanged(id: string) {
        this.highlightChangedEvent.next(id);
    }

    public getColumnHeaderClasses(column: EntityTableColumn) {
        const styleHintClasses = (column.decoratorOptions && column.decoratorOptions.styleHints)
            ? column.decoratorOptions.styleHints.join(' ')
            : '';

        const decoratorClass = [column.decorator ? 'entity-table-header--' + column.decorator : ''];

        return uniq([
            'entity-table-header',
            decoratorClass,
            styleHintClasses,
        ].filter(truthy)).join(' ');
    }

    public getEntityId(entity: Entity) {
        const potentialIdProperties = ['id', '_id', 'dq'];

        for (const property of potentialIdProperties) {
            if (has(entity, property)) {
                return get(entity, property);
            }
        }

        return null;
    }

    public onClickedRow(event: MouseEvent, entity: Entity) {
        event.preventDefault();

        if (this.highlightable) {
            this.onHighlightChanged(this.getEntityId(entity));
        }

        if (this.clickable && this.clickedRow) {
            this.clickedRow.emit(this.getEntityId(entity));
        }
    }

    public synchronizeScroll(scrollTwo: HTMLElement): void {
        if (this.scrollOne) {
            (this.scrollOne.nativeElement as HTMLElement).scrollLeft = scrollTwo.scrollLeft;
        }
    }
}

export interface EntityEvent {
    id: string;
    event: {
        type: string;
        payload?: any;
    };
    entity?: any;
}
