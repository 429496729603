import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Dictionary } from '@ngrx/entity';
import { flatten, unflatten } from 'flat';

import { get, includes, isEqual, set } from 'lodash';
import { Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
    FormControlConfig,
    FormControlGroupConfig, FormControlOptions,
    FormStructureConfig,
    FormStructureElementConfig,
    FormStructureWithoutAreasConfig,
    FormValues,
} from '../../../core/models/form.model';
import * as formValidators from '../../../core/validators';
import { TranslocoModule } from '@ngneat/transloco';
import { FilterControlContainerComponent } from '../filter-control-container/filter-control-container.component';
import { NgClass, NgIf, NgFor, SlicePipe, KeyValuePipe } from '@angular/common';

/**
 * FilterComponent displays the filter for the specified filter config including the available values and the
 * initial values
 */
@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
    standalone: true,
    imports: [FormsModule, NgClass, ReactiveFormsModule, NgIf, NgFor, FilterControlContainerComponent, SlicePipe, KeyValuePipe, TranslocoModule]
})
export class FilterComponent implements OnChanges, OnDestroy {
    @HostBinding('class.filter') public componentClass = true;

    @Input() public structure: FormStructureConfig;
    @Input() public availableValues: FormValues;
    @Input() public initialValues: any;
    @Input() public overrideValues: FormValues;
    @Input() public muted: boolean;
    @Input() public flat: boolean;
    @Input() public disabled = false;
    @Input() public orientation: 'vertical' | 'horizontal' = 'horizontal';
    @Input() public rawValue: FormValues = {};
    @Input() public emitEventWhenOverride = false;
    @Input() public filterId: string;

    @Input() visibleInput = true;

    @Output() public valueChange: EventEmitter<FormValues> = new EventEmitter();
    @Output() public valueChangeRaw: EventEmitter<FormValues> = new EventEmitter();
    @Output() public statusChange: EventEmitter<boolean> = new EventEmitter();

    public filterForm: UntypedFormGroup;
    public filterChangeSubscription: Subscription;
    public filterStatusSubscription: Subscription;
    public previousRawValue: FormValues = {};

    constructor(private fb: UntypedFormBuilder, public ref: ElementRef) {
    }

    public get valid() {
        return (this.structure && this.structure.options && this.structure.options.enableFormValidation) ? this.filterForm.valid : true;
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.structure) {
            this.updateFormGroup();
        }

        if (changes.initialValues && this.initialValues) {
            this.setInitialValues();
        }

        if (changes.overrideValues && this.overrideValues) {
            this.setOverrideValues(this.emitEventWhenOverride);
        }

        if (changes.rawValue && this.rawValue) {
            this.filterForm.patchValue(this.rawValue, {emitEvent: false});
        }
    }

    public ngOnDestroy() {
        if (this.filterChangeSubscription) {
            this.filterChangeSubscription.unsubscribe();
        }
    }

    public validateFilter() {
        this.filterForm.markAllAsTouched();
        this.filterForm.updateValueAndValidity();
    }

    /**
     * Returns the available data for the filter control
     * @param {string} valuePath
     * @returns {any}
     */
    public getAvailableDataForControl(valuePath: string): any {
        if (valuePath && valuePath.includes(',')) {
            return valuePath.split(',').map((path) => {
                return get(this.availableValues, path);
            });
        }
        return get(this.availableValues, valuePath);
    }

    public hasVisibleControls(controlGroup: FormControlGroupConfig): boolean {
        return controlGroup.controls.some((control) => this.isControlVisibleInConditionalTree(control));
    }

    public hasControlsWithData(controlGroup: FormControlGroupConfig) {
        return controlGroup.controls.some((control) => this.getAvailableDataForControl(control.path));
    }

    public isControlVisible(control: FormControlConfig | FormStructureElementConfig) {
        if (control.options && control.options.conditionPath && control.options.conditionValues) {
            const conditionValue = get(flatten(this.rawValue || {}), control.options.conditionPath);
            return includes(control.options.conditionValues, conditionValue);
        } else {
            return true;
        }
    }

    public isControlVisibleInConditionalTree(control: FormControlConfig | FormStructureElementConfig) {
        const filterControls = this.getFilterControls();

        if (!this.isControlVisible(control)) {
            return false;
        }

        // check recursively upstream, if parents are hidden. That would also hide the child controls.
        if (control._parent && !this.isControlVisibleInConditionalTree(control._parent)) {
            return false;
        }

        // control dependencies can also be sidewards (sibilings), so we have to also check the visibility recursively sidestream
        if (control.options && control.options.conditionPath && control.options.conditionValues) {
            const conditionalControl = filterControls.find((c) => c.path === control.options.conditionPath);

            return conditionalControl ? this.isControlVisibleInConditionalTree(conditionalControl) : true;
        } else {
            return true;
        }
    }

    /**
     * Returns the visible empty control values
     * @returns {FormValues}
     */
    protected getEmptyControlValues(): FormValues {
        const filterControls = this.getFilterControls();

        return filterControls.reduce((controls, control) => {
            if (control.options && control.options.validationRules) {
                const validators = [];

                Object.keys(control.options.validationRules).forEach((rule) => {
                    if (formValidators[rule + 'Validator']) {
                        validators.push(formValidators[rule + 'Validator'](control.options.validationRules[rule]));
                    }
                });

                controls[control.path] = ['', validators];
            } else {
                controls[control.path] = [];
            }

            return controls;
        }, {});
    }

    protected getControlsByPath(): Dictionary<FormControlConfig> {
        const filterControls = this.getFilterControls();

        return filterControls.reduce((controls, control) => {
            controls[control.path] = control;
            return controls;
        }, {});
    }

    /**
     * Returns the all the initial values
     * @returns {FormValues}
     */
    protected getFullInitialValues(): FormValues {
        const controlValues = this.getControlsByPath();

        Object.keys(controlValues).forEach((value) => {
            const control = controlValues[value];
            controlValues[value] = get(this.initialValues, value);

            if (!control.options || !control.options.submitEmptyValue) {
                // @ts-ignore
                const isEmpty = (controlValues[value] !== 0) && !controlValues[value];

                controlValues[value] = isEmpty ? undefined : controlValues[value];
            }
        });

        return controlValues;
    }

    /**
     * Updates the form group
     */
    protected updateFormGroup() {
        if (this.filterChangeSubscription) {
            this.filterChangeSubscription.unsubscribe();
        }

        this.filterForm = this.fb.group(this.getEmptyControlValues());

        const valuesChanges$ = this.filterForm.valueChanges.pipe(
            map((value) => {
                const visibleControlPaths = this.getFilterControls()
                    .filter((control) => this.isControlVisibleInConditionalTree(control))
                    .map((control) => control.path);

                return Object.keys(value)
                    .filter((path) => visibleControlPaths.indexOf(path) >= 0)
                    .reduce((values, path) => {
                        values[path] = value[path];
                        return values;
                    }, {});
            }),
        );

        this.filterChangeSubscription = valuesChanges$.pipe(
            map((value) => unflatten(value)),
        ).subscribe(this.valueChange);

        this.filterChangeSubscription.add(valuesChanges$.pipe(
            tap((rawValue) => {
                this.rawValue = {
                    ...this.rawValue,
                    ...rawValue,
                };
            }),
        ).subscribe(this.valueChangeRaw));

        this.filterChangeSubscription.add(valuesChanges$.pipe(
            tap((rawValue) => {
                this.rawValue = {
                    ...this.rawValue,
                    ...rawValue,
                };
            }),
        ).subscribe((rawValue) => {
            const changedControl = Object.entries(rawValue).reduce(
                (diff, [key, value]) =>
                    isEqual(this.previousRawValue[key], value) ? diff : { ...diff, [key]: value },
                {}
            );

            if (Object.keys(changedControl).length > 0) {
                Object.keys(changedControl)
                    .map((triggerPath) => [changedControl[triggerPath], triggerPath])
                    .filter(([changedValue]) => typeof changedValue === 'boolean')
                    .forEach(([_, triggerPath]) => 

                    Object.keys(this.filterForm.controls)
                        .map((resolvePath) => this.getControlbyPath(resolvePath))
                        .filter((controlConfig) => controlConfig.options?.pathToDisabled &&
                            controlConfig.options.pathToDisabled === triggerPath)
                        .map((controlConfig) => this.filterForm.controls[controlConfig.path])
                        .forEach((control) => 

                        control.enabled ? control.disable({ emitEvent: false }) : control.enable({ emitEvent: false })
                    )
                );
            }

            this.previousRawValue = rawValue;
        }));

        this.filterStatusSubscription = this.filterForm.statusChanges.pipe(
            map((status) => status === 'VALID' ? true : false)
        ).subscribe(this.statusChange);

        this.setInitialValues();

        setTimeout(() => {
            Object.keys(this.filterForm.controls).forEach((field) => {
                const control = this.filterForm.controls[field];

                control.updateValueAndValidity();
            });
        }, 500);
    }

    /**
     * Sets the initial filter values
     */
    protected setInitialValues() {
        this.filterForm.patchValue(this.getFullInitialValues());
    }

    protected setOverrideValues(emitEvent = false) {
        this.filterForm.patchValue(this.overrideValues, {emitEvent});
    }

    /**
     * Resets the filter to it's default values
     */
    public reset() {
        this.setInitialValues();
    }

    /**
     * Returns the filter controls
     * @returns {FormControlConfig[]}
     */
    public getFilterControls(): FormControlConfig[] {
        if (this.structure && this.structure.areas) {
            return this.structure.areas.reduce((controls1, area) => {
                return controls1.concat(area.sections.reduce((controls2, sec) => {
                    const section = {
                        ...sec,
                        _parent: area,
                    };

                    return controls2.concat(section.columns.reduce((controls3, col) => {
                        const column = {
                            ...col,
                            _parent: section,
                        };

                        return controls3.concat(column.controlGroups.reduce((controls4, cg) => {
                            const controlGroup = {
                                ...cg,
                                _parent: column,
                            };

                            return [
                                ...controls4,
                                ...controlGroup.controls.map((c) => {
                                    return {
                                        ...c,
                                        _parent: controlGroup,
                                    };
                                }),
                            ];
                        }, []));
                    }, []));
                }, []));
            }, []);
        } else {
            return [];
        }
    }

    public getControl(control: FormControlConfig<FormControlOptions>) {
        const formControl = this.filterForm.controls[control.path];

        if (formControl) {
            return formControl;
        }

        return null;
    }

    public getControlbyPath(path: string): FormControlConfig {
        return this.getFilterControls()
            .filter((control) => control.path === path)[0];
    }
}
