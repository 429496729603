import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { booleanToString, getParamValueQueryString, stringToBoolean } from '../../../shared/helpers/general.helper';
import { AuthStoreService } from '../../services/auth-store.service';
import { AuthService } from '../../services/auth.service';
import { typeCache } from '../../store/util';
import { ActionPayloadDialogComponent } from '../action-payload-dialog/action-payload-dialog.component';
import { ViewTokensDialogComponent } from '../view-tokens-dialog/view-tokens-dialog.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { IconComponent } from '../../../shared/components/icon/icon.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@dericon/ng-select';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-dev-toolbar',
    templateUrl: './dev-toolbar.component.html',
    styleUrls: ['./dev-toolbar.component.scss'],
    standalone: true,
    imports: [NgIf, MatToolbarModule, MatButtonModule, NgSelectModule, FormsModule, NgFor, IconComponent, MatSlideToggleModule]
})
export class DevToolbarComponent implements OnInit {
    public selectedEnvironment: string;
    public selectedAction: string;
    public visible = JSON.parse(localStorage.getItem('wms-toolbar-visibility'));
    public production = false;

    public actions = Object.keys(typeCache);

    public environment = environment;
    public environments = [
        'default',
        'broker',
        'abnamro.derifin.de',
        'bethmannbank.derifin.de',
        'blsk.derifin.de',
        'bnpparibas.derifin.de',
        'bnprfq.derifin.de',
        'burkhardt-vv.derifin.de',
        'derifin.dericon.it',
        'deutsche-bank.derifin.de',
        'fuggerbank.derifin.de',
        'hal-privatbank.derifin.de',
        'ksklb.derifin.de',
        'ksk-koeln.derifin.de',
        'hrklunis.derifin.de',
        'berenberg.derifin.de',
        'degussa-bank.derifin.de',
        'demo-lbbw.derifin.de',
        'demo-sim.derifin.de',
        'neu-demo.derifin.de',
        'zertifikate.derifin.de',
        'olb.derifin.de',
        'oddo-bhf.derifin.de',
        'rcb.derifin.de',
        'sparkasse-bremen.derifin.de',
        'sparkasse-co-lif.derifin.de',
        'sparkasse-langenfeld.derifin.de',
        'sparkasse-hannover.derifin.de',
        'sparkasse-hochsauerland.derifin.de',
        'sparkasse-karlsruhe.derifin.de',
        'sparkasse-koelnbonn.derifin.de',
        'sparkasse-nuernberg.derifin.de',
        'sparkasse-bochum.derifin.de',
        'sparkasse-sha.derifin.de',
        'spk-suedholstein.derifin.de',
        'spk-goettingen.derifin.de',
        'spk-ri.derifin.de',
        'sskm.derifin.de',
        'berliner-sparkasse.derifin.de',
        'ksk-bc.derifin.de',
        'ksk-verden.derifin.de',
        's-jena.derifin.de',
        'sparkasse-pforzheim-calw.derifin.de',
        'ostsaechsische-sparkasse-dresden.derifin.de',
        'verbundvolksbank-owl.derifin.de',
        'national-bank.derifin.de',
        'hanvb.derifin.de',
        'lbbw.derifin.de',
        'commerzbank.derifin.de',
        'weberbank.derifin.de',
        'bisonwms.derifin.de',
        'demo-bisonwms.derifin.de',
        'sska.derifin.de',
        'sskduesseldorf.derifin.de',
        'taunussparkasse.derifin.de',
        'wms.derifin.de',
        'attrax-fondsadvisor.derifin.de',
        'haspa.derifin.de',
        'merkur-privatbank.derifin.de'
    ];
    
    public accessToken$: Observable<string>;
    public refreshToken$: Observable<string>;
    private jwtHelperService: JwtHelperService;
    public accessTokenDecoded$: Observable<any>;

    constructor(
        private authStoreService: AuthStoreService,
        private authService: AuthService,
        private store: Store,
        private dialog: MatDialog
    ) {
        const fakeEnvironment = getParamValueQueryString('fakeEnvironment') || sessionStorage.getItem('fakeEnvironment');
        const productionEnvironment = getParamValueQueryString('productionEnvironment') || sessionStorage.getItem('productionEnvironment');

        this.production = productionEnvironment ? stringToBoolean(productionEnvironment) : false;
        this.selectedEnvironment = fakeEnvironment ? fakeEnvironment : 'default';

        this.accessToken$ = this.authStoreService.getAccessToken();
        this.refreshToken$ = this.authStoreService.getRefreshToken();

        this.jwtHelperService = new JwtHelperService();
        this.accessTokenDecoded$ = this.accessToken$.pipe(map((token) => {
            const json = this.jwtHelperService.decodeToken(token);

            if (json) {
                json['iat'] = moment.unix(json['iat']).format();
                json['exp'] = moment.unix(json['exp']).format();
            }
            return json;
        }));
    }

    public ngOnInit() {
    }

    public onEnvironmentSelect(selectedEnvironment: string) {
        window.location.href = '/?fakeEnvironment=' + selectedEnvironment + '&productionEnvironment=' + booleanToString(this.production);
    }

    public onOpenActionPayload(action: string) {
        this.selectedAction = null;

        const ref = this.dialog.open(ActionPayloadDialogComponent);

        ref.afterClosed().subscribe((value) => {
            if (value && 'payload' in value) {
                try {
                    this.store.dispatch({type: action, payload: value.payload});
                } catch (exception) {
                    alert(exception);
                }
            }
        });
    }

    @HostListener('document:keydown', ['$event'])
    public onKeyDown(event: KeyboardEvent) {
        if (event.ctrlKey && event.altKey) {
            switch(event.code) {
                case 'KeyO':
                    this.toggleVisibility();
                    break;
                case 'KeyA':
                    this.previousEnvironment();
                    break;
                case 'KeyD':
                    this.nextEnvironment();
                    break;
            }
        }
    }

    public toggleVisibility() {
        this.visible = !this.visible;
        localStorage.setItem('wms-toolbar-visibility', JSON.stringify(this.visible));
    }

    public previousEnvironment() {
        const selectedEnvironmentIdx = this.environments.findIndex((env) => env === this.selectedEnvironment);
        const previousEnvironment = Math.max(selectedEnvironmentIdx - 1, 0);
        this.onEnvironmentSelect(this.environments[previousEnvironment]);
    }

    public nextEnvironment() {
        const selectedEnvironmentIdx = this.environments.findIndex((env) => env === this.selectedEnvironment);
        const nextEnvironment = Math.min(selectedEnvironmentIdx + 1, this.environments.length - 1);
        this.onEnvironmentSelect(this.environments[nextEnvironment]);
    }

    public onClickClose(event: MouseEvent) {
        event.preventDefault();

        this.visible = false;
        localStorage.setItem('wms-toolbar-visibility', JSON.stringify(this.visible));
    }

    public onChangeProduction(value: boolean) {
        const val = booleanToString(value);

        window.location.href = '/?fakeEnvironment=' + this.selectedEnvironment + '&productionEnvironment=' + val;
    }

    public onTokens() {
        combineLatest([
            this.accessToken$,
            this.refreshToken$,
            this.accessTokenDecoded$,
        ]).subscribe(([accessToken, refreshToken, accessTokenDecoded]) => {
            this.dialog.open(ViewTokensDialogComponent, {
                data: {
                    accessToken,
                    refreshToken,
                    accessTokenDecoded,
                },
            }).afterClosed().subscribe((value) => {
                if (value && 'event' in value) {
                    switch (value.event) {
                        case 'reset_access_token':
                            this.authService.resetAccessToken();
                            break;
                        case 'reset_refresh_token':
                            this.authService.resetRefreshToken();
                            break;
                    }
                }
            });
        });

    }
}
