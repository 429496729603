<div class="wms-dialog-inner error-dialog">
    <nav class="navbar navbar-toggleable-md header-bar navbar-inverse header-bar modal__navbar">
        <div class="container">
            <i class="mr-auto"></i>
            <div class="navbar-nav">
                <a (click)="onCloseClick($event)" class="nav-link icon-link close-modal-button" href="#">
                    <app-icon class="derifin-icon" icon="derifin:schliessen"></app-icon>
                </a>
            </div>
        </div>
    </nav>
    
    
    <div class="modal-body">
        <h1 class="modal-form__title">{{title}}</h1>
    
        <p *ngIf="!html">{{message}}</p>
        <div *ngIf="html" [innerHTML]="message"></div>
    
        <div class="modal-actions">
            <a class="modal-actions__action" href="#" (click)="onCloseClick($event)">
                {{ 'modals.createErrorDialog.close' | transloco }}
            </a>
        </div>
    </div>
</div>
