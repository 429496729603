import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { SlideDownComponent } from './slide-down.component';
import { IconComponent } from '../../../shared/components/icon/icon.component';
import { RouterLink, RouterOutlet } from '@angular/router';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-admin-slide-down',
    templateUrl: './admin-slide-down.component.html',
    styleUrls: ['./slide-down.component.scss'],
    animations: [
        trigger('slideInOut', [
            transition('* <=> *', [
                group([
                    query(':leave', [
                        style({
                            height: '*',
                        }),
                        animate('300ms ease-out', style({
                            height: '0',
                        })),
                    ], { optional: true }),
                    query(':enter', [
                        style({
                            height: '0',
                        }),
                        animate('300ms ease-in', style({
                            height: '*',
                        })),
                    ], { optional: true }),
                ]),
            ]),
        ]),
    ],
    standalone: true,
    imports: [NgIf, RouterLink, IconComponent, RouterOutlet, AsyncPipe]
})
export class AdminSlideDownComponent extends SlideDownComponent {
}
