<div class="d-flex flex-column justify-content-center align-items-center" style="height: 100vh">
    <div class="d-flex align-items-center mb-4">
        <h1 class="mb-0 mr-3 pr-3 text-primary" style="border-right: 1px solid #575757">404</h1>
        <p class="lead mb-0">{{ 'not-found.infoText' | transloco }}</p>
    </div>
    <p><strong>Falls dieser Fehler weiter auftritt, können Sie folgendes versuchen:</strong></p>
    <ul>
        <li>Überprüfen Sie ihr Lesezeichen - evtl. wurde die von Ihnen gespeicherte<br>Seite zu einer neuen Adresse umgezogen</li>
        <li>Rufen Sie die Seite zu einem späteren Zeitpunkt nochmal auf</li>
    </ul>
    <ng-container *ngIf="(isTokenValid$ | async) === null">
        <a class="btn btn-secondary" routerLink="/auth/login">{{ 'not-found.navigateToLogin' | transloco }}</a>
    </ng-container>
    <ng-container *ngIf="(isTokenValid$ | async) === true">
        <a class="btn btn-secondary" [routerLink]="dashboardUrl$ | async">{{ 'not-found.navigateToDashboard' | transloco }}</a>
    </ng-container>
</div>