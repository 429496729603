import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewTokensDialogData } from './view-tokens-dialog-data.interface';
import { IconComponent } from '../../../shared/components/icon/icon.component';

@Component({
    selector: 'app-view-tokens-dialog',
    templateUrl: './view-tokens-dialog.component.html',
    standalone: true,
    imports: [IconComponent]
})
export class ViewTokensDialogComponent {
    public accessToken: string;
    public refreshToken: string;
    public accessTokenDecoded: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: ViewTokensDialogData,
        private dialogRef: MatDialogRef<ViewTokensDialogComponent>,
        ) {

        this.accessToken = data.accessToken;
        this.refreshToken = data.refreshToken;
        this.accessTokenDecoded = JSON.stringify(data.accessTokenDecoded, undefined, 4);
    }


    public onCloseClick(e: MouseEvent): void {
        e.preventDefault();
        this.dialogRef.close({ confirmed: false });
    }

    public onResetAccessToken(): void {
        this.dialogRef.close({event: 'reset_access_token'});
    }

    public onResetRefreshToken(): void {
        this.dialogRef.close({event: 'reset_refresh_token'});
    }

}
