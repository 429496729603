import { Directive, HostBinding, Input } from "@angular/core";

export type Appearance = 'fill' | 'outline' | 'text';
type Size = 'base' | 'small';

const base = 'inline-block text-center text-sm no-underline rounded-full focus-visible:outline focus-visible:outline-2 disabled:cursor-not-allowed disabled:opacity-60';

const sizeClasses = {
  base: 'text-sm px-3 py-1.5',
  small: 'text-xs px-2.5 py-1.5'
};

@Directive()
export class ButtonBase {

  constructor(private classes: { [key: string]: string }) { }
  
  public _size: Size = 'base';
  public _appearance: Appearance = 'fill';

  @HostBinding('class')
  public hostClasses = this.buildClasses(this._appearance, this._size);

  @Input() set size(value: Size) {
    this._size = value;
    this.hostClasses = this.buildClasses(this._appearance, this._size);
  }

  public buildClasses(appearance: Appearance, size: Size): string {
    return `${base} ${this.classes[appearance]} ${sizeClasses[size]}`
  }
}
