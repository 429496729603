<nav class="navbar navbar-toggleable-md header-bar navbar-inverse header-bar">
    <div class="container">
        <a class="navbar-brand mr-auto" (click)="onCloseClick($event)" [routerLink]="logoUrl$ | async">
            <img class="header-bar__logo" [src]="logoImgUrl$ | async"/>
        </a>
        <div class="navbar-nav">
            <a (click)="onCloseClick($event)" class="nav-link icon-link" href="#">
                <app-icon class="derifin-icon" icon="derifin:schliessen"></app-icon>
            </a>
        </div>
    </div>
</nav>
<div class="menu__container">

    <div
        class="menu__row"
        *ngFor="let row of rows; let rowIndex = index;"
        [class.menu__row--current]="currentDepth === rowIndex + 1" [@rowState]="'default'">

        <app-menu-item
            *ngFor="let item of row; let itemIndex = index;"
            [item]="item"
            [active]="isInCurrentPath(itemIndex, rowIndex)"
            (menuItemClick)="onItemClick($event, itemIndex, rowIndex)"
            (submenuItemClicked)="onSubitemSelected($event,itemIndex,rowIndex)"></app-menu-item>

    </div>

</div>
