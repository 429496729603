import { createAction, props } from "@ngrx/store";
import { Shortcuts } from "../../api/models/user.model";

export const loadShortcuts = createAction(
    '[User] Load Shortcuts'
);

export const setShortcuts = createAction(
    '[User] Set Shortcuts',
    props<{ shortcuts: Shortcuts }>()
);
