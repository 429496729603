/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 4/26/17.
 */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ConfigApi } from '../../api/config.api';
import { AppError } from '../../errors/base.errors';
import { handleAuthenticationError } from '../../helpers/errors.helper';
import { Config } from '../../models/config.model';
import { ConfigActionTypes, configLoaded, LoadConfigAction, loadConfigFailed } from '../actions/config.action';
import { ConfigurationService } from '../../services/configuration.service';

/**
 * Effect class for auth effects
 */
@Injectable()
export class ConfigEffects {
    /** Effect Declarations **/

    
    public loadConfig$ = createEffect(() => this.actions$.pipe(
        ofType(ConfigActionTypes.LOAD_CONFIG),
        switchMap((action: LoadConfigAction) => this.onLoadConfig(action)),
    ));

    constructor(private readonly actions$: Actions, private readonly configApi: ConfigApi, private readonly configurationService: ConfigurationService) {
    }

    /** Effect Handler **/

    /**
     * Effect handler called upon the LoadConfigAction
     *
     * This effect handler calls the corresponding API method to load the config for the currently authenticated user
     * @param action
     * @returns {Observable<Action>}
     */
    public onLoadConfig(action: LoadConfigAction): Observable<Action> {
        return this.configApi.getConfig().pipe(
            map((config: Config) => configLoaded(config, this.configurationService.configuration)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: AppError) => of(loadConfigFailed(err))),
        );
    }
}
