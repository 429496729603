import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, switchMapTo, take, tap } from 'rxjs/operators';
import * as AuditsActions from '../../../routes/audits/store/actions/audits.actions';
import { ErrorDialogComponent } from '../../../shared/components/dialogs/error-dialog/error-dialog.component';
import { LoadingDialogComponent } from '../../../shared/components/dialogs/loading-dialog/loading-dialog.component';
import { AsyncLinkApi } from '../../api/async-link.api';
import { AsyncLinkResponseData } from '../../api/models/download.model';
import { WidgetDialogComponent } from '../../components/widget-dialog/widget-dialog.component';
import { AppError } from '../../errors/base.errors';
import { handleAuthenticationError } from '../../helpers/errors.helper';
import { PopupService } from '../../services/popup.service';
import {
    assetsExcelAllFailure,
    AssetsExcelAllRequestAction,
    assetsExcelAllSuccess,
    AssetsExcelAllSuccessAction,
    assetsExcelFailure,
    AssetsExcelRequestAction,
    assetsExcelSuccess,
    AssetsExcelSuccessAction,
    AsyncLinkActions,
    AsyncLinkActionTypes,
    attachmentFailure,
    AttachmentRequestAction,
    AttachmentSuccessAction,
    bwListHistoryFailure,
    BWListHistoryRequestAction,
    bwListHistorySuccess,
    BWListHistorySuccessAction,
    equivalenceCheckExportFailure,
    EquivalenceCheckExportRequestAction,
    equivalenceCheckExportSuccess,
    EquivalenceCheckExportSuccessAction,
    equivalenceExportFailure,
    EquivalenceExportRequestAction,
    equivalenceExportSuccess,
    EquivalenceExportSuccessAction,
    externalProductLinkFailure,
    ExternalProductLinkRequestAction,
    externalProductLinkSuccess,
    ExternalProductLinkSuccessAction,
    FailureAction,
    MultiProductReportRequestAction,
    onePagerFailure,
    OnePagerRequestAction,
    onePagerSuccess,
    OnePagerSuccessAction,
    ProductsExcelRequestAction,
    productsExcelSuccess,
    ProductsExcelSuccessAction,
    reportFailure,
    ReportRequestAction,
    reportSuccess,
    ReportSuccessAction, rfqMultiRequestExcelExportFailure,
    RFQMultiRequestExcelExportRequestAction,
    rfqMultiRequestExcelExportSuccess,
    RFQMultiRequestExcelExportSuccessAction,
    rfqRequestExportFailure,
    RFQRequestExportRequestAction,
    rfqRequestExportSuccess,
    RFQRequestExportSuccessAction,
    rfqResultExportFailure,
    RFQResultExportRequestAction,
    rfqResultExportSuccess,
    RFQResultExportSuccessAction,
    shortlistExportFailure,
    ShortlistExportRequestAction,
    shortlistExportSuccess,
    ShortlistExportSuccessAction,
    shortlistGroupedExportFailure,
    ShortlistGroupedExportRequestAction,
    shortlistGroupedExportSuccess,
    ShortlistGroupedExportSuccessAction,
    tmcCheckReportFailure,
    TmcCheckReportRequestAction,
    tmcCheckReportSuccess,
    TmcCheckReportSuccessAction, tmcExportFailure, TmcExportRequestAction, tmcExportSuccess, TmcExportSuccessAction,
    tradesExcelAllFailure,
    TradesExcelAllRequestAction,
    tradesExcelAllSuccess,
    TradesExcelAllSuccessAction,
    tradesExcelFailure,
    TradesExcelRequestAction,
    tradesExcelSuccess,
    TradesExcelSuccessAction,
    usersExcelAllFailure,
    UsersExcelAllRequestAction,
    usersExcelAllSuccess,
    UsersExcelAllSuccessAction,
    usersExcelFailure,
    UsersExcelRequestAction,
    usersExcelSuccess,
    UsersExcelSuccessAction,
    WatchlistExcelRequestAction,
    watchlistExcelSuccess,
    WatchlistExcelSuccessAction,
} from '../actions/async-link.action';
import * as FundsCompareActions from '../../../routes/shared/funds-compare/store/selectors/funds-compare.selectors';

/**
 * Effect class for download effects
 */
@Injectable()
export class AsyncLinkEffects {
    /** ONE PAGER **/

    
    public onePagerRequest$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.ONE_PAGER_REQUEST),
        switchMap((action: OnePagerRequestAction) => this.onOnePagerRequest(action)),
    ));
    
    public onePagerSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.ONE_PAGER_SUCCESS),
        tap((action: OnePagerSuccessAction) => this.onOnePagerSuccess(action)),
    ), {dispatch: false});
    /** PRODUCTS EXCEL **/

    
    public productsExcelRequest$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.PRODUCTS_EXCEL_REQUEST),
        switchMap((action: ProductsExcelRequestAction) => this.onProductsExcelRequest(action)),
    ));
    
    public productsExcelAllRequest$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.PRODUCTS_EXCEL_ALL_REQUEST),
        switchMap((action: ProductsExcelRequestAction) => this.onProductsExcelRequest(action)),
    ));
    
    public productsExcelSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.PRODUCTS_EXCEL_SUCCESS),
        tap((action: ProductsExcelSuccessAction) => this.onProductsExcelSuccess(action)),
    ), {dispatch: false});
    
    public shortlistExportRequest$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.SHORTLIST_EXPORT_REQUEST),
        switchMap((action: ShortlistExportRequestAction) => this.onShortlistExportRequest(action)),
    ));
    
    public shortlistExportSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.SHORTLIST_EXPORT_SUCCESS),
        tap((action: ShortlistExportSuccessAction) => this.onShortlistExportSuccess(action)),
    ), {dispatch: false});

    public shortlistGroupedExportRequest$ = createEffect(() =>  {
        return this.actions$.pipe(
            ofType(AsyncLinkActionTypes.SHORTLIST_GROUPED_EXPORT_REQUEST),
            switchMap((action: ShortlistGroupedExportRequestAction) => this.onShortlistGroupedExportRequest(action))
        )
    });

    public shortlistGroupedExportSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AsyncLinkActionTypes.SHORTLIST_GROUPED_EXPORT_SUCCESS),
            tap((action: ShortlistGroupedExportSuccessAction) => this.onShortlistGroupedExportSuccess(action))
        )
    }, { dispatch: false });

    /** WATCHLIST EXCEL **/
    
    public watchlistExcelRequest$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.WATCHLIST_EXCEL_REQUEST),
        switchMap((action: WatchlistExcelRequestAction) => this.onWatchlistExcelRequest(action)),
    ));
    
    public watchlistExcelSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.WATCHLIST_EXCEL_SUCCESS),
        tap((action: WatchlistExcelSuccessAction) => this.onWatchlistExcelSuccess(action)),
    ), {dispatch: false});
    /** USERS EXCEL **/
    
    public usersExcelRequest$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.USERS_EXCEL_REQUEST),
        switchMap((action: UsersExcelRequestAction) => this.onUsersExcelRequest(action)),
    ));
    
    public userExcelSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.USERS_EXCEL_SUCCESS),
        tap((action: UsersExcelSuccessAction) => this.onUsersExcelSuccess(action)),
    ), {dispatch: false});
    /** USERS EXCEL ALL **/
    
    public usersExcelAllRequest$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.USERS_EXCEL_ALL_REQUEST),
        switchMap((action: UsersExcelAllRequestAction) => this.onUsersExcelAllRequest(action)),
    ));
    
    public userExcelAllSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.USERS_EXCEL_ALL_SUCCESS),
        tap((action: UsersExcelAllSuccessAction) => this.onUsersExcelAllSuccess(action)),
    ), {dispatch: false});
    /** TRADES EXCEL **/

    
    public tradesExcelRequest$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.TRADES_EXCEL_REQUEST),
        switchMap((action: TradesExcelRequestAction) => this.onTradesExcelRequest(action)),
    ));
    
    public tradesExcelSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.TRADES_EXCEL_SUCCESS),
        tap((action: TradesExcelSuccessAction) => this.onTradesExcelSuccess(action)),
    ), {dispatch: false});
    /** TRADES EXCEL ALL **/

    
    public tradesExcelAllRequest$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.TRADES_EXCEL_ALL_REQUEST),
        switchMap((action: TradesExcelAllRequestAction) => this.onTradesExcelAllRequest(action)),
    ));
    
    public tradesExcelAllSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.TRADES_EXCEL_ALL_SUCCESS),
        tap((action: TradesExcelAllSuccessAction) => this.onTradesExcelAllSuccess(action)),
    ), {dispatch: false});

    /** ASSETS EXCEL **/
    
    public tmcCheckReportRequest$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.TMC_CHECK_REPORT_REQUEST),
        switchMap((action: TmcCheckReportRequestAction) => this.onTmcCheckReportRequest(action)),
    ));
    
    public tmcCheckReportSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.TMC_CHECK_REPORT_SUCCESS),
        tap((action: TmcCheckReportSuccessAction) => this.onTmcCheckReportSuccess(action)),
    ), {dispatch: false});

    /** WATCHLIST TMC EXPORT **/
    
    public tmcExportRequest$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.TMC_EXPORT_REQUEST),
        switchMap((action: TmcExportRequestAction) => this.onTmcExportRequest(action)),
    ));

    
    public tmcExportSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.TMC_EXPORT_SUCCESS),
        tap((action: TmcExportSuccessAction) => this.onTmcExportSuccess(action)),
    ), {dispatch: false});

    /** ASSETS EXCEL **/

    
    public assetsExcelRequest$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.ASSETS_EXCEL_REQUEST),
        switchMap((action: AssetsExcelRequestAction) => this.onAssetsExcelRequest(action)),
    ));
    
    public assetsExcelSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.ASSETS_EXCEL_SUCCESS),
        tap((action: AssetsExcelSuccessAction) => this.onAssetsExcelSuccess(action)),
    ), {dispatch: false});
    /** ASSETS EXCEL ALL **/

    
    public assetsExcelAllRequest$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.ASSETS_EXCEL_ALL_REQUEST),
        switchMap((action: AssetsExcelAllRequestAction) => this.onAssetsExcelAllRequest(action)),
    ));
    
    public assetsExcelAllSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.ASSETS_EXCEL_ALL_SUCCESS),
        tap((action: AssetsExcelAllSuccessAction) => this.onAssetsExcelAllSuccess(action)),
    ), {dispatch: false});
    /** REPORT **/

    
    public reportRequest$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.REPORT_REQUEST),
        switchMap((action: ReportRequestAction) => this.onReportRequest(action)),
    ));

    
    public multiProductReportRequest$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.MULTI_PRODUCT_REPORT_REQUEST),
        switchMap((action: MultiProductReportRequestAction) => this.onMultiProductReportRequest(action)),
    ));

    
    public reportSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.REPORT_SUCCESS),
        tap((action: ReportSuccessAction) => this.onReportSuccess(action)),
    ), {dispatch: false});
    /** ATTACHMENT **/

    
    public attachmentRequest$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.ATTACHMENT_REQUEST),
        switchMap((action: AttachmentRequestAction) => this.onAttachmentRequest(action)),
    ));
    
    public attachmentSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.ATTACHMENT_SUCCESS),
        tap((action: AttachmentSuccessAction) => this.onAttachmentSuccess(action)),
    ), {dispatch: false});
    /** EXTERNAL PRODUCT LINK EXCEL **/

    
    public externalProductLinkRequest$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.EXTERNAL_PRODUCT_LINK_REQUEST),
        switchMap((action: ExternalProductLinkRequestAction) => this.onExternalProductLinkRequest(action)),
    ));
    
    public externalProductLinkSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.EXTERNAL_PRODUCT_LINK_SUCCESS),
        tap((action: ExternalProductLinkSuccessAction) => this.onExternalProductLinkSuccess(action)),
    ), {dispatch: false});
    /** BW_LIST_HISTORY **/

    
    public bwListHistoryRequest$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.BW_LIST_HISTORY_REQUEST),
        switchMap((action: BWListHistoryRequestAction) => this.onBWListHistoryRequest(action)),
    ));
    
    public bwListHistorySuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.BW_LIST_HISTORY_SUCCESS),
        tap((action: BWListHistorySuccessAction) => this.onBWListHistorySuccess(action)),
    ), {dispatch: false});

    /** RFQ_EXPORT_RESULT_QUOTE_REQUEST **/
    
    public rfqExportResultRequest$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.RFQ_EXPORT_RESULT_QUOTE_REQUEST),
        switchMap((action: RFQResultExportRequestAction) => this.onRFQResultExportRequest(action)),
    ));
    
    public rfqExportResultSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.RFQ_EXPORT_RESULT_QUOTE_SUCCESS),
        tap((action: RFQResultExportSuccessAction) => this.onRFQResultExportSuccess(action)),
    ), {dispatch: false});

    /** RFQ_EXPORT_REQUEST_QUOTE_REQUEST **/
    
    public rfqExportRequestRequest$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.RFQ_EXPORT_REQUEST_QUOTE_REQUEST),
        switchMap((action: RFQRequestExportRequestAction) => this.onRFQRequestExportRequest(action)),
    ));
    
    public rfqExportRequestSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.RFQ_EXPORT_REQUEST_QUOTE_SUCCESS),
        tap((action: RFQRequestExportSuccessAction) => this.onRFQRequestExportSuccess(action)),
    ), {dispatch: false});

    /** RFQ_EXPORT_REQUEST_QUOTE_REQUEST **/
    
    public rfqMultiRequestExcelExportRequest$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.RFQ_MULTI_EXCEL_EXPORT_REQUEST),
        switchMap((action: RFQMultiRequestExcelExportRequestAction) => this.onRFQMultiRequestExcelExportRequest(action)),
    ));
    
    public rfqMultiRequestExcelExportSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.RFQ_MULTI_EXCEL_EXPORT_SUCCESS),
        tap((action: RFQMultiRequestExcelExportSuccessAction) => this.onRFQMultiRequestExcelExportSuccess(action)),
    ), {dispatch: false});

    
    public equivalenceExportRequest$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.EQUIVALENCE_EXPORT_REQUEST),
        switchMap((action: EquivalenceExportRequestAction) => this.onEquivalenceExportRequest(action)),
    ));
    
    public equivalenceExportSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(AsyncLinkActionTypes.EQUIVALENCE_EXPORT_SUCCESS),
        tap((action: EquivalenceExportSuccessAction) => this.onEquivalenceExportSuccess(action)),
    ), {dispatch: false});

    public equivalenceCheckExportRequest$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AsyncLinkActionTypes.EQUIVALENCE_CHECK_EXPORT_REQUEST),
            switchMap((action: EquivalenceCheckExportRequestAction) => this.onEquivalenceCheckExportRequest(action)),
        )
    });

    public equivalenceCheckExportSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AsyncLinkActionTypes.EQUIVALENCE_CHECK_EXPORT_SUCCESS),
            tap((action: EquivalenceCheckExportSuccessAction) => this.onEquivalenceCheckExportSuccess(action)),
        )
    }, { dispatch: false });

    public productCompareExport$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AsyncLinkActions.productCompareExport),
            concatLatestFrom(() => this.store.select(FundsCompareActions.selectChartFilter)),
            switchMap(([action, filter]) => {
                this.showLoading();

                return this.asyncLinkApi.getProductCompareExport(action.dqs, filter).pipe(
                    map((data: AsyncLinkResponseData) =>
                        AsyncLinkActions.exportSuccess({ link: data.link })
                    ),
                    catchError((err) => handleAuthenticationError(err, action)),
                    catchError((error: AppError) => of(
                        AsyncLinkActions.exportFailure({ payload: { error }})
                    ))
                );
            })
        )
    });

    public exportSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AsyncLinkActions.exportSuccess),
            tap((action) => {
                this.hideLoading();
                this.popupService.open(action.link);
            })
        )
    }, { dispatch: false });

    // Errors
    
    public failure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                AsyncLinkActionTypes.ASSETS_EXCEL_ALL_FAILURE,
                AsyncLinkActionTypes.ASSETS_EXCEL_FAILURE,
                AsyncLinkActionTypes.ATTACHMENT_FAILURE,
                AsyncLinkActionTypes.EXTERNAL_PRODUCT_LINK_FAILURE,
                AsyncLinkActionTypes.ONE_PAGER_FAILURE,
                AsyncLinkActionTypes.PRODUCTS_EXCEL_FAILURE,
                AsyncLinkActionTypes.REPORT_FAILURE,
                AsyncLinkActionTypes.TRADES_EXCEL_ALL_FAILURE,
                AsyncLinkActionTypes.TRADES_EXCEL_FAILURE,
                AsyncLinkActionTypes.BW_LIST_HISTORY_FAILURE,
                AsyncLinkActionTypes.TMC_CHECK_REPORT_FAILURE,
                AsyncLinkActionTypes.MULTI_PRODUCT_REPORT_FAILURE,
                AsyncLinkActionTypes.SHORTLIST_EXPORT_FAILURE,
                AsyncLinkActionTypes.USERS_EXCEL_FAILURE,
                AsyncLinkActionTypes.USERS_EXCEL_ALL_FAILURE,
                AsyncLinkActionTypes.WATCHLIST_EXCEL_FAILURE,
                AsyncLinkActionTypes.RFQ_EXPORT_RESULT_QUOTE_FAILURE,
                AsyncLinkActionTypes.RFQ_EXPORT_REQUEST_QUOTE_FAILURE,
                AsyncLinkActionTypes.RFQ_MULTI_EXCEL_EXPORT_FAILURE,
                AsyncLinkActionTypes.EQUIVALENCE_EXPORT_FAILURE,
                AsyncLinkActionTypes.EQUIVALENCE_CHECK_EXPORT_FAILURE,
                AsyncLinkActionTypes.TMC_EXPORT_FAILURE,
                AuditsActions.exportAuditFailure,
                AsyncLinkActions.exportFailure
            ),
            tap((action: FailureAction) => this.onFailure(action))
    )}, { dispatch: false });
    private loadingDialogRef: MatDialogRef<LoadingDialogComponent> = null;

    constructor(
        private actions$: Actions,
        private store: Store,
        private translocoService: TranslocoService,
        private asyncLinkApi: AsyncLinkApi,
        private dialog: MatDialog,
        private popupService: PopupService
    ) { }

    /** Effect Handler **/

    /**
     * Effect handler called upon the LoadConfigAction
     *
     * This effect handler calls the corresponding API method to load the config for the currently authenticated user
     * @param action
     * @returns {Observable<Action>}
     */
    public onOnePagerRequest(action: OnePagerRequestAction): Observable<Action> {
        this.showLoading();

        return this.asyncLinkApi
            .getOnePager(
                action.payload.productDqs,
                action.payload.type,
                action.payload.targetMarketCriteria,
                action.payload.context
            )
            .pipe(
                map((data: AsyncLinkResponseData) => onePagerSuccess(data)),
                catchError((err) => handleAuthenticationError(err, action)),
                catchError((err: AppError) => of(onePagerFailure(err)))
            );
    }

    /**
     * Effect handler called upon the LoadConfigAction
     *
     * This effect handler calls the corresponding API method to load the config for the currently authenticated user
     * @param action
     * @returns {Observable<Action>}
     */
    public onProductsExcelRequest(action: ProductsExcelRequestAction): Observable<Action> {
        this.showLoading();

        return this.asyncLinkApi.getProductsExcelSheet(
            action.payload && action.payload.dqs,
            action.payload.context
        ).pipe(
            map((data: AsyncLinkResponseData) => productsExcelSuccess(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: AppError) => of(externalProductLinkFailure(err)))
        );
    }

    /**
     * Effect handle called upon UsersExcelRequestAction
     *
     * @param action
     * @returns {Observable<Action>}
     */
    public onUsersExcelRequest(action: UsersExcelRequestAction): Observable<Action> {
        this.showLoading();

        return this.asyncLinkApi.getUsersExcelSheet(action.payload.users).pipe(
            map((data: AsyncLinkResponseData) => usersExcelSuccess(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: AppError) => of(usersExcelFailure(err))),
        );
    }

    public onUsersExcelAllRequest(action: UsersExcelAllRequestAction) {
        this.showLoading();

        return this.asyncLinkApi.getUsersExcelAllSheet().pipe(
            map((data: AsyncLinkResponseData) => usersExcelAllSuccess(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: AppError) => of(usersExcelAllFailure(err))),
        );
    }

    /**
     * Effect handler called upon the LoadConfigAction
     *
     * This effect handler calls the corresponding API method to load the config for the currently authenticated user
     * @param action
     * @returns {Observable<Action>}
     */
    public onTradesExcelRequest(action: TradesExcelRequestAction): Observable<Action> {
        this.showLoading();

        return this.asyncLinkApi.getTradesExcelSheet(action.payload.dqs).pipe(
            map((data: AsyncLinkResponseData) => tradesExcelSuccess(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: AppError) => of(tradesExcelFailure(err))),
        );
    }

    /**
     * @param action
     * @returns {Observable<Action>}
     */
    public onTradesExcelAllRequest(action: TradesExcelAllRequestAction): Observable<Action> {
        this.showLoading();

        return this.asyncLinkApi.getTradesExcelAllSheet(action.payload.sessionId).pipe(
            map((data: AsyncLinkResponseData) => tradesExcelAllSuccess(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: AppError) => of(tradesExcelAllFailure(err))),
        );
    }

    /**
     * @param action
     * @returns {Observable<Action>}
     */
    public onAssetsExcelRequest(action: AssetsExcelRequestAction): Observable<Action> {
        this.showLoading();

        return this.asyncLinkApi.getAssetsExcelSheet(action.payload.sessionId, action.payload.dqs).pipe(
            map((data: AsyncLinkResponseData) => assetsExcelSuccess(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: AppError) => of(assetsExcelFailure(err))),
        );
    }

    /**
     * @param action
     * @returns {Observable<Action>}
     */
    public onTmcCheckReportRequest(action: TmcCheckReportRequestAction): Observable<Action> {
        this.showLoading();

        return this.asyncLinkApi.getTmcCheckReport(action.payload).pipe(
            map((data: AsyncLinkResponseData) => tmcCheckReportSuccess(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: AppError) => of(tmcCheckReportFailure(err))),
        );
    }

    /**
     * @param action
     * @returns {Observable<Action>}
     */
    public onTmcExportRequest(action: TmcExportRequestAction): Observable<Action> {
        return this.translocoService.selectTranslate('asyncLink.loadingMessages.tmcExportRequest').pipe(
            tap((text) => this.showLoading(text)),
            switchMapTo(this.asyncLinkApi.getTmcExportReport(action.payload.watchlistId, action.payload.comments)),
            map((data: AsyncLinkResponseData) => tmcExportSuccess(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: AppError) => of(tmcExportFailure(err))),
        );
    }

    /**
     * Effect handler called upon the AttachmentRequestAction
     *
     * This effect handler calls the corresponding API method to load the report
     * @param action
     * @returns {Observable<Action>}
     */
    public onAttachmentRequest(action: AttachmentRequestAction): Observable<Action> {
        this.showLoading();

        return this.asyncLinkApi.getAttachment(action.payload.attachmentId).pipe(
            map((data: AsyncLinkResponseData) => reportSuccess(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: AppError) => of(attachmentFailure(err))),
        );
    }

    /**
     * @param action
     * @returns {Observable<Action>}
     */
    public onAssetsExcelAllRequest(action: AssetsExcelAllRequestAction): Observable<Action> {
        this.showLoading();

        return this.asyncLinkApi.getAssetsExcelAllSheet(action.payload.sessionId).pipe(
            map((data: AsyncLinkResponseData) => assetsExcelAllSuccess(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: AppError) => of(assetsExcelAllFailure(err))),
        );
    }

    /**
     * Effect handler called upon the ReportRequestAction
     *
     * This effect handler calls the corresponding API method to load the report
     * @param action
     * @returns {Observable<Action>}
     */
    public onReportRequest(action: ReportRequestAction): Observable<Action> {
        this.showLoading();

        return this.asyncLinkApi.getReport(action.payload.data).pipe(
            map((data: AsyncLinkResponseData) => reportSuccess(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: AppError) => of(reportFailure(err))),
        );
    }

    /**
     * Effect handler called upon the MultiProductReportRequestAction
     *
     * This effect handler calls the corresponding API method to load the report
     * @param action
     * @returns {Observable<Action>}
     */
    public onMultiProductReportRequest(action: MultiProductReportRequestAction): Observable<Action> {
        this.showLoading();

        return this.asyncLinkApi.getMultiProductReport(action.payload.data).pipe(
            map((data: AsyncLinkResponseData) => reportSuccess(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: AppError) => of(reportFailure(err))),
        );
    }

    /**
     * Effect handler called upon the ExternalProductLinkRequestAction
     *
     * This effect handler calls the corresponding API method to load the external product link
     * @param action
     * @returns {Observable<Action>}
     */
    public onExternalProductLinkRequest(action: ExternalProductLinkRequestAction): Observable<Action> {
        this.showLoading();

        return this.asyncLinkApi.getExternalProductLink(
            action.payload.productDq,
            action.payload.linkType,
            action.payload.tmc
        ).pipe(
            map((data: AsyncLinkResponseData) => externalProductLinkSuccess(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: AppError) => of(externalProductLinkFailure(err)))
        );
    }

    /**
     * @param action
     */
    public onBWListHistoryRequest(action: BWListHistoryRequestAction): Observable<Action> {
        this.showLoading();

        return this.asyncLinkApi.getBWListHistory(
            action.payload.module,
            action.payload.timestamp,
            action.payload.instrumentTypeRestrictions,
        ).pipe(
            map((data: AsyncLinkResponseData) => bwListHistorySuccess(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: AppError) => of(bwListHistoryFailure(err))),
        );
    }

    /**
     * Effect handler called upon the OnePagerSuccessAction
     *
     * @param action
     */
    public onOnePagerSuccess(action: OnePagerSuccessAction): void {
        this.hideLoading();
        const payload = action.payload;

        if (payload.confirmationRequired) {
            const structure = payload.structure;
            const values = payload.values;

            this.dialog.open(WidgetDialogComponent, {
                data: {
                    link: payload.link,
                    confirmationRequired: payload.confirmationRequired,
                    structure,
                    values,
                }
            });
        } else {
            this.popupService.open(payload.link);
        }
    }

    /**
     * Effect handler called upon the ProductsExcelSuccessAction
     *
     * @param action
     */
    public onProductsExcelSuccess(action: ProductsExcelSuccessAction) {
        this.hideLoading();
        this.popupService.open(action.payload.link);
    }

    public onWatchlistExcelSuccess(action: WatchlistExcelSuccessAction) {
        this.hideLoading();
        this.popupService.open(action.payload.link);
    }

    /**
     * Effect handler called upon the UsersExcelSuccessAction
     *
     * @param action
     */
    public onUsersExcelSuccess(action: UsersExcelSuccessAction) {
        this.hideLoading();
        this.popupService.open(action.payload.link);
    }

    /**
     * Effect handler called upon the UsersExcelSuccessAction
     *
     * @param action
     */
    public onUsersExcelAllSuccess(action: UsersExcelAllSuccessAction) {
        this.hideLoading();
        this.popupService.open(action.payload.link);
    }

    /**
     * Effect handler called upon the TradesExcelSuccessAction
     *
     * @param action
     */
    public onTradesExcelSuccess(action: TradesExcelSuccessAction) {
        this.hideLoading();
        this.popupService.open(action.payload.link);
    }

    /**
     * Effect handler called upon the TradesExcelAllSuccessAction
     *
     * @param action
     */
    public onTradesExcelAllSuccess(action: TradesExcelAllSuccessAction) {
        this.hideLoading();
        this.popupService.open(action.payload.link);
    }

    /**
     * Effect handler called upon the AssetsExcelSuccessAction
     *
     * @param action
     */
    public onAssetsExcelSuccess(action: AssetsExcelSuccessAction) {
        this.hideLoading();
        this.popupService.open(action.payload.link);
    }

    /**
     * Effect handler called upon the TmcCheckReportSuccessAction
     *
     * @param action
     */
    public onTmcCheckReportSuccess(action: TmcCheckReportSuccessAction) {
        this.hideLoading();
        this.popupService.open(action.payload.link);
    }

    /**
     * Effect handler called upon the AssetsExcelAllSuccessAction
     *
     * @param action
     */
    public onAssetsExcelAllSuccess(action: AssetsExcelAllSuccessAction) {
        this.hideLoading();
        this.popupService.open(action.payload.link);
    }

    /**
     * Effect handler called upon the ReportSuccessAction
     *
     * @param action
     */
    public onReportSuccess(action: ReportSuccessAction): void {
        this.hideLoading();
        const payload = action.payload;

        if (payload.confirmationRequired) {
            const structure = payload.structure;
            const values = payload.values;

            this.dialog.open(WidgetDialogComponent, {
                data: {
                    link: payload.link,
                    confirmationRequired: payload.confirmationRequired,
                    structure,
                    values,
                }
            });
        } else {
            this.popupService.open(payload.link);
        }
    }

    /**
     * Effect handler called upon the AttachmentRequestSentAction
     *
     * @param action
     */
    public onAttachmentSuccess(action: AttachmentSuccessAction) {
        this.hideLoading();
        this.popupService.open(action.payload.link);
    }

    /**
     * Effect handler called upon the ExternalProductLinkSuccessAction
     *
     * @param action
     */
    public onExternalProductLinkSuccess(action: ExternalProductLinkSuccessAction): void {
        this.hideLoading();
        const payload = action.payload;

        if (payload.confirmationRequired) {
            const structure = payload.structure;
            const values = payload.values;

            this.dialog.open(WidgetDialogComponent, {
                data: {
                    link: payload.link,
                    confirmationRequired: payload.confirmationRequired,
                    structure,
                    values,
                }
            });
        } else {
            this.popupService.open(action.payload.link);
        }
    }

    /**
     * @param action
     */
    public onBWListHistorySuccess(action: BWListHistorySuccessAction): void {
        this.hideLoading();
        const payload = action.payload;

        if (payload.confirmationRequired) {
            const structure = payload.structure;
            const values = payload.values;

            this.dialog.open(WidgetDialogComponent, {
                data: {
                    link: payload.link,
                    confirmationRequired: payload.confirmationRequired,
                    structure,
                    values,
                }
            });
        } else {
            this.popupService.open(action.payload.link);
        }
    }

    public async onFailure(action: FailureAction) {
        this.hideLoading();

        if (action.payload.error.statusCode === 404) {
            const title: string = await this.translocoService.selectTranslate('asyncLink.errors.404.title').pipe(take(1)).toPromise();
            const message = `<p>${await this.translocoService.selectTranslate('asyncLink.errors.404.message').pipe(take(1)).toPromise()}</p>`;

            this.dialog.open(
                ErrorDialogComponent,
                {
                    data: {
                        title,
                        message,
                        html: true,
                    }
                }
            );
        } else {
            const title: string = await this.translocoService.selectTranslate('asyncLink.errors.other.title').pipe(take(1)).toPromise();
            const message = `<p>${await this.translocoService.selectTranslate('asyncLink.errors.other.message').pipe(take(1)).toPromise()}</p>`;

            this.dialog.open(
                ErrorDialogComponent,
                {
                    data: {
                        title,
                        message,
                        html: true,
                    }
                }
            );
        }
    }

    public showLoading(text: string = null) {
        this.loadingDialogRef = this.dialog.open(LoadingDialogComponent, { data: { text }});
    }

    public hideLoading() {
        if (this.loadingDialogRef) {
            this.loadingDialogRef.close();
        }
    }

    public onShortlistExportRequest(action: ShortlistExportRequestAction) {
        this.showLoading();

        return this.asyncLinkApi.getShortlistExport(action.payload.id, action.payload.dqs).pipe(
            map((data: AsyncLinkResponseData) => shortlistExportSuccess(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: AppError) => of(shortlistExportFailure(err))),
        );
    }

    public onShortlistExportSuccess(action: ShortlistExportSuccessAction) {
        this.hideLoading();
        this.popupService.open(action.payload.link);
    }

    public onShortlistGroupedExportRequest(action: ShortlistGroupedExportRequestAction) {
        this.showLoading();

        return this.asyncLinkApi.getShortlistGroupedExport(action.payload.id).pipe(
            map((data: AsyncLinkResponseData) => shortlistGroupedExportSuccess(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: AppError) => of(shortlistGroupedExportFailure(err)))
        );
    }

    public onShortlistGroupedExportSuccess(action: ShortlistGroupedExportSuccessAction) {
        this.hideLoading();
        this.popupService.open(action.payload.link);
    }

    private onWatchlistExcelRequest(action: WatchlistExcelRequestAction) {
        this.showLoading();

        return this.asyncLinkApi.getWatchlistExcelSheet(
            action.payload.watchlistId,
            action.payload.dqs,
            action.payload.context
        ).pipe(
            map((data: AsyncLinkResponseData) => watchlistExcelSuccess(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: AppError) => of(externalProductLinkFailure(err)))
        );
    }

    private onRFQResultExportRequest(action: RFQResultExportRequestAction) {
        this.showLoading();

        return this.asyncLinkApi.getRFQResultExport(action.payload.moduleId, action.payload.quoteId, action.payload.redirectToken).pipe(
            map((data: AsyncLinkResponseData) => rfqResultExportSuccess(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: AppError) => of(rfqResultExportFailure(err))),
        );
    }

    private onRFQResultExportSuccess(action: RFQResultExportSuccessAction) {
        this.hideLoading();
        this.popupService.open(action.payload.link);
    }

    private onRFQRequestExportRequest(action: RFQRequestExportRequestAction) {
        this.showLoading();

        return this.asyncLinkApi.getRFQRequestExport(action.payload.moduleId, action.payload.quoteId, action.payload.redirectToken).pipe(
            map((data: AsyncLinkResponseData) => rfqRequestExportSuccess(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: AppError) => of(rfqRequestExportFailure(err))),
        );
    }

    private onRFQMultiRequestExcelExportSuccess(action: RFQMultiRequestExcelExportSuccessAction) {
        this.hideLoading();
        this.popupService.open(action.payload.link);
    }

    private onRFQMultiRequestExcelExportRequest(action: RFQMultiRequestExcelExportRequestAction) {
        this.showLoading();

        return this.asyncLinkApi.getRFQMultiExcelExport(action.payload.moduleId, action.payload.quoteId).pipe(
            map((data: AsyncLinkResponseData) => rfqMultiRequestExcelExportSuccess(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: AppError) => of(rfqMultiRequestExcelExportFailure(err))),
        );
    }

    private onRFQRequestExportSuccess(action: RFQRequestExportSuccessAction) {
        this.hideLoading();
        this.popupService.open(action.payload.link);
    }

    private onEquivalenceExportRequest(action: EquivalenceExportRequestAction) {
        this.showLoading();
        return this.asyncLinkApi.getEquivalenceExport(action.payload.watchlistId, action.payload.resultId, action.payload.comments).pipe(
            map((data: AsyncLinkResponseData) => equivalenceExportSuccess(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: AppError) => of(equivalenceExportFailure(err))),
        );
    }

    private onEquivalenceExportSuccess(action: EquivalenceExportSuccessAction) {
        this.hideLoading();
        this.popupService.open(action.payload.link);
    }

    private onTmcExportSuccess(action: TmcExportSuccessAction) {
        this.hideLoading();
        this.popupService.open(action.payload.link);
    }

    private onEquivalenceCheckExportRequest(action: EquivalenceCheckExportRequestAction) {
        this.showLoading();
        
        return this.asyncLinkApi.getEquivalenceCheckExport(
            action.payload.productDq,
            action.payload.equivalenceCheckId,
            action.payload.comments
        ).pipe(
            map((data: AsyncLinkResponseData) => equivalenceCheckExportSuccess(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: AppError) => of(equivalenceCheckExportFailure(err)))
        );
    }

    private onEquivalenceCheckExportSuccess(action: EquivalenceExportSuccessAction) {
        this.hideLoading();
        this.popupService.open(action.payload.link);
    }
}
