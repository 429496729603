export interface FormStructureConfig {
    areas?: FormAreaConfig[];
    options?: FormStructureOptions;
    sectionsOrientation?: 'horizontal' | 'vertical';
}

export interface FormStructureWithoutAreasConfig extends FormStructureElementConfig {
    sections: FormSectionConfig[];
    options?: FormStructureOptions;
}

export interface FormStructureElementConfig {
    weight?: number;
    options?: FormControlOptions;

    _parent?: FormStructureElementConfig | FormControlGroupConfig | FormAreaConfig | FormSectionConfig | FormColumnConfig; // for internal processing
}

export interface FormAreaConfig extends FormStructureElementConfig {
    sections: FormSectionConfig[];
}

export interface FormSectionConfig extends FormStructureElementConfig {
    title?: string;
    translateTitle?: boolean;
    columns: FormColumnConfig[];
}

export interface FormColumnConfig extends FormStructureElementConfig {
    controlGroups: FormControlGroupConfig[];
}

export interface FormControlGroupConfig extends FormStructureElementConfig {
    title?: string;
    translateTitle?: boolean;
    controls: FormControlConfig[];
}

export interface FormControlOptions {
    conditionPath?: string;
    conditionValues?: any[];
    advancedConditions?: AdvancedCondition[];
    submitEmptyValue?: boolean;
    pathToDisabled?: string;

    validationRules?: FormValidationRules;
    [option: string]: any;
}

export interface FormStructureOptions {
    enableFormValidation: boolean;
    onlySubmitWhenValid: boolean;
}

/**
 * @Note(Alex):
 * Concept for form validation **/
export interface FormValidationConfiguration {
    [field: string]: FormValidationRules;
}

export interface FormValidationRules {
    [rule: string]: FormValidationRule;
}

export interface FormValidationRule {
    rule: string;
    options: FormValidationRuleOptions;
}

export interface FormValidationRuleOptions {
    message?: string;
    value: any;

    [key:string]: any;
}

/**
 * control: {
*      type: 'textInput',
*      path: 'name',
*      options: {
*         validationRules: {
*                 'startsWith': {
*                     value: '123',
*                     message: '',
*                 },
*                 'endsWith': {
*                     message: '',
*                 },
*                 'required': {
*                     message: 'Muss ausgefüllt werden'
*                 },
*                 'minLength': {},
*                 'maxLength': {},
*                 'eq': {},
*                 'lt': {},
*                 'gt': {},
*                 'lte': {},
*                 'gte': {},
 *         }
 *      }
 * }
 **/

/** END **/

export interface AdvancedCondition {
    type: 'HAS_VALUE' | 'PD_COMPONENT_COUNT' | 'TARGET_VALUE' | string;
    relation: 'EQ' | 'NEQ' | 'LTQ' | 'GTQ' | 'LT' | 'GT' | 'IN' | 'NIN';
    logic?: 'AND' | 'OR';
    value: any[] | any;
    target?: string;
}

export interface FormControlConfig<T extends FormControlOptions = FormControlOptions> {
    values?: string;
    path?: string;
    type: string;
    options?: T;
    styleHints?: string[];
    disabled?: boolean;

    _parent?: FormStructureElementConfig | FormControlGroupConfig | FormAreaConfig | FormSectionConfig | FormColumnConfig; // for internal processing
    __hadEmptyPath?: boolean;
}

export interface PartialTextMatchFormControlConfig extends FormControlConfig {
    matcher?: RegExp;
}

export interface FormValues {
    [key: string]: any | FormValues;
}

export interface FormControlSelectOption {
    id?: any;
    label?: string;
    translateLabel?: boolean;
    isSeparator?: boolean;
    enabled?: boolean;
    tooltip?: string;
    description?: string;
}

export interface FormControlSelectOptionExtra extends FormControlSelectOption {
    [key: string]: any;
}

export interface FormControlSelectOptions {
    [value: string]: FormControlSelectOption[];
}

export interface FormControlSelectEquivalence extends FormControlSelectOption {
    deriBwIds: string[];
}

export interface FormControlSelectSubItemsOption extends FormControlSelectOption {
    items: FormControlSelectOption[];
    defaultItem?: string;
}

export interface UnderlyingFormControlSelectOption extends FormControlSelectOption {
    ids: { isin: string; wkn: string; }
}
