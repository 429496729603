import {NgModule} from '@angular/core';
import {mapToCanActivate, RouterModule, Routes} from '@angular/router';
import {OverlayComponent} from './core/components/overlay/overlay.component';
import {SlideDownComponent} from './core/components/slide-down/slide-down.component';
import {AuthGuard} from './core/guards/auth.guard';
import {NoAuthGuard} from './core/guards/no-auth.guard';
import {DefaultLayoutComponent} from './core/layouts/default-layout.component';

import {AdminSlideDownComponent} from './core/components/slide-down/admin-slide-down.component';
import {AdminLayoutComponent} from './core/layouts/admin-layout.component';
import {ExternalLayoutComponent} from './core/layouts/external-layout.component';
import {LandingLayoutComponent} from './core/layouts/landing-layout.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';

export const GLOBAL_SCROLL_OFFSET: [number, number] = [0, 250];
const routes: Routes = [
    {
        path: '',
        component: LandingLayoutComponent,
        canActivate: mapToCanActivate([NoAuthGuard]),
        children: [
            {
                path: '',
                loadChildren: () => import('./routes/landing/landing.module').then((m) => m.LandingModule),
            },
        ],
    },
    {
        path: 'auth',
        canActivate: mapToCanActivate([NoAuthGuard]),
        loadChildren: () => import('./routes/auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: 'admin',
        canActivate: mapToCanActivate([AuthGuard]),
        component: AdminLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./routes/admin/admin.module').then((m) => m.AdminModule),
            },
            {
                path: '',
                component: AdminSlideDownComponent,
                data: {
                  origin: 'admin',
                },
                outlet: 'slide-down',
                children: [
                    {
                        path: 'profile',
                        loadChildren: () => import('./routes/profile/profile.module').then((m) => m.ProfileModule),
                    },
                ],
            },
        ],
    },
    {
        path: 'external',
        canActivate: mapToCanActivate([AuthGuard]),
        component: ExternalLayoutComponent,
        children: [
            {
                path: 'product-details',
                data: {
                    origin: 'external',
                },
                loadChildren: () => import('./routes/product-details/product-details.module').then((m) => m.ProductDetailsModule),
            },
            {
                path: 'product-designer',
                loadChildren: () => import('./routes/product-designer/product-designer.module').then((m) => m.ProductDesignerModule),
            },
        ],
    },
    {
        path: 'app',
        component: DefaultLayoutComponent,
        canActivate: mapToCanActivate([AuthGuard]),
        children: [
            {
                path: 'dashboard',
                data: {
                    context: 'DASHBOARD'
                },
                loadChildren: () => import('./routes/dashboard/dashboard.module').then((m) => m.DashboardModule),
            }, {
                path: 'products',
                data: {
                    context: 'PRODUCT_LIST',
                    castFrom: 'type',
                    castTo: 'moduleId'
                },
                loadChildren: () => import('./routes/products/products.module').then((m) => m.ProductsModule),
            }, {
                path: 'products-export',
                loadChildren: () => import('./routes/products-export/products-export.module').then((m) => m.ProductsExportModule),
            }, {
                path: 'other-products',
                loadChildren: () => import('./routes/other-products/other-products.module').then((m) => m.OtherProductsModule),
            }, {
                path: 'file-list-administration',
                loadChildren: () => import('./routes/file-list-administration/file-list-administration.module').then((m) => m.FileListAdministrationModule),
            }, {
                path: 'watchlists',
                loadChildren: () => import('./routes/watchlists/watchlists.module').then((m) => m.WatchlistsModule),
            }, {
                path: 'watchlists-group',
                loadChildren: () => import('./routes/watchlists-group/watchlists-group.module').then((m) => m.WatchlistsGroupModule),
            }, {
                path: 'bwlist',
                loadChildren: () => import('./routes/bwlist/bwlist.module').then((m) => m.BWListModule),
            }, {
                path: 'recommendation',
                loadChildren: () => import('./routes/recommendations/recommendation.module').then((m) => m.RecommendationModule),
            }, {
                path: 'trades',
                loadChildren: () => import('./routes/trades/trades.module').then((m) => m.TradesModule),
            }, {
                path: 'assets',
                loadChildren: () => import('./routes/assets/assets.module').then((m) => m.AssetsModule),
            }, {
                path: 'dpc',
                loadChildren: () => import('./routes/dpc/dpc.module').then((m) => m.DPCModule),
            }, {
                path: 'product-designer',
                loadChildren: () => import('./routes/product-designer/product-designer.module').then((m) => m.ProductDesignerModule),
            }, {
                path: 'rfq-issuer',
                loadChildren: () => import('./routes/rfq-issuer/rfq-issuer.module').then((m) => m.RfqIssuerModule),
            }, {
                path: 'advisor-product-baskets',
                loadChildren: () => import('./routes/advisor-product-baskets/advisor-product-baskets.module').then((m) => m.AdvisorProductBasketsModule),
            }, {
                path: 'risk-manager',
                loadChildren: () => import('./routes/risk-manager/risk-manager.module').then((m) => m.RiskManagerModule),
            }, {
                path: 'market-analysis',
                loadChildren: () => import('./routes/market-analysis/market-analysis.module').then((m) => m.MarketAnalysisModule),
            }, {
                path: 'shortlist',
                loadChildren: () => import('./routes/shortlist/shortlist.module').then((m) => m.ShortlistModule),
            }, {
                path: 'file-lists',
                loadChildren: () => import('./routes/file-list/file-list.module').then((m) => m.FileListModule)
            }, {
                path: 'audits',
                loadChildren: () => import('./routes/audits/audits.module').then((m) => m.AuditsModule)
            }, {
                path: '',
                component: SlideDownComponent,
                data: {
                    origin: 'app',
                },
                outlet: 'slide-down',
                children: [
                    {
                        path: 'profile',
                        loadChildren: () => import('./routes/profile/profile.module').then((m) => m.ProfileModule),
                    },
                ],
            }, {
                path: '',
                component: OverlayComponent,
                outlet: 'overlay',
                children: [
                    {
                        path: 'product-selection',
                        loadChildren: () => import('./routes/product-selection/product-selection.module').then((m) => m.ProductSelectionModule),
                    },
                    {
                        path: 'funds-compare',
                        loadChildren: () => import('./routes/funds-compare/funds-compare.module').then((m) => m.FundsCompareModule),
                    },
                    {
                        path: 'product-details',
                        data: {
                            context: 'FACTSHEET'
                        },
                        loadChildren: () => import('./routes/product-details/product-details.module').then((m) => m.ProductDetailsModule),
                    },
                    {
                        path: 'watchlist-details',
                        loadChildren: () => import('./routes/watchlist-details/watchlist-details.module').then((m) => m.WatchlistDetailsModule),
                    },
                    {
                        path: 'volatility-matrix',
                        loadChildren: () => import('./routes/volatility-matrix/volatility-matrix.module').then((m) => m.VolatilityMatrixModule),
                    },
                    {
                        path: 'customer-selection',
                        loadChildren: () => import('./routes/customer-selection/customer-selection.module').then((m) => m.CustomerSelectionModule)
                    }
                ]
            }
        ]
    },
    {
        path: 'not-found',
        component: NotFoundComponent
    },
    {
        path: '**',
        redirectTo: 'not-found'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: GLOBAL_SCROLL_OFFSET
})],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
