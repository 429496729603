import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonBase } from './button-base';

type NeutralAppearance = 'outline' | 'text';

const appearanceClases: { [key in NeutralAppearance]: string } = {
    outline: 'text-white ring-inset ring-1 ring-white hover:bg-white/10 focus-visible:outline-primary-white disabled:bg-transparent',
    text: 'text-white hover:bg-white/10 focus-visible:outline-primary-white disabled:bg-transparent'
};

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[chroma-button-neutral], a[chroma-button-neutral]',
    templateUrl: './button.html',
    standalone: true,
    imports: [CommonModule]
})
export class ButtonNeutralComponent extends ButtonBase {

    constructor() {
        super(appearanceClases);
    }

    @Input() set appearance(value: NeutralAppearance) {
        this._appearance = value;
        this.hostClasses = this.buildClasses(this._appearance, this._size);
    }
}
