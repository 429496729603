/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 4/26/17.
 */

import { Action } from '@ngrx/store';
import { TokenUser } from '../../api/models/me.model';
import {
    AuthActionTypes,
    AuthUserLoadedAction,
    SignInFailedAction, SignInRedirectTokenSuccessAction,
    TokenRefreshedAction,
    UpdateUserSettingsSuccessAction,
} from '../actions/auth.action';
// import { routerActions } from '@ngrx/router-store';
import * as  RouterActions from '../actions/router.action';
import { AuthState } from '../state/auth.state';

export const initialState: AuthState = {
    updateUserSettingsLoading: false,
    authUser: null,
    accessToken: null,
    refreshToken: null,
    signInError: null,
    signingIn: false,
    redirectTokenLoginMode: null,
};

/**
 * The reducer responsible for the @link{AuthState}
 * @param state
 * @param action
 * @returns {any}
 */
export function authReducer(state: AuthState = initialState, action: Action): AuthState {

    switch (action.type) {

        case AuthActionTypes.SIGN_IN:
            return {
                ...state,
                signInError: null,
                signingIn: true,
                redirectTokenLoginMode: null,
            };

        case AuthActionTypes.SIGNED_IN:
            return {
                ...state,
                signingIn: false,
                redirectTokenLoginMode: null,
            };

        case AuthActionTypes.SIGN_IN_REDIRECT_TOKEN:
            return {
                ...state,
                redirectTokenLoginMode: 'PROGRESS',
            };

        case AuthActionTypes.SIGN_IN_REDIRECT_TOKEN_SUCCESS:
            const signInRedirectTokenSuccessAction = action as SignInRedirectTokenSuccessAction;

            return {
                ...state,
                redirectTokenLoginMode: 'DONE',
                accessToken: signInRedirectTokenSuccessAction.payload.token.token,
                refreshToken: signInRedirectTokenSuccessAction.payload.token.refreshToken || signInRedirectTokenSuccessAction.payload.token.token,
            };

        case AuthActionTypes.SIGN_IN_REDIRECT_TOKEN_FAILURE:
            return {
                ...state,
                redirectTokenLoginMode: 'FAIL',
            };

        case AuthActionTypes.RESET_REFRESH_TOKEN: {
            return {
                ...state,
                refreshToken: '',
            };
        }

        case AuthActionTypes.RESET_ACCESS_TOKEN: {
            return {
                ...state,
                accessToken: '',
            };
        }

        case AuthActionTypes.RESET_TOKENS: {
            return {
                ...state,
                accessToken: null,
                refreshToken: null,
                signingIn: false,
                redirectTokenLoginMode: null,
                signInError: null,
                authUser: null,
            }
        }

        /**
         * Handle a sign in failure
         */
        case AuthActionTypes.SIGN_IN_FAILED:
            const signInFailedAction = action as SignInFailedAction;

            return {
                ...state,
                signInError: signInFailedAction.payload.error.errorCode,
                signingIn: false,
                redirectTokenLoginMode: null,
            };

        case AuthActionTypes.TOKEN_REFRESHED:
            const tokenRefreshedAction = action as TokenRefreshedAction;
            const accessToken = (tokenRefreshedAction.payload.tokenResponse.token) ?
                tokenRefreshedAction.payload.tokenResponse.token : state.accessToken;

            return {
                ...state,
                accessToken,
                refreshToken: (tokenRefreshedAction.payload.tokenResponse.refreshToken) ?
                    tokenRefreshedAction.payload.tokenResponse.refreshToken : (state.refreshToken || accessToken),
            };

        case AuthActionTypes.AUTH_USER_LOADED:
            const authUserLoadedAction = action as AuthUserLoadedAction;
            return {
                ...state,
                authUser: {
                    ...state.authUser,
                    ...authUserLoadedAction.payload.authUser,
                },
            };

        case AuthActionTypes.SIGN_OUT:
            return {
                updateUserSettingsLoading: false,
                authUser: null,
                accessToken: null,
                refreshToken: null,
                signInError: null,
                signingIn: false,
                redirectTokenLoginMode: null,
            };

        case RouterActions.UPDATE_LOCATION:
            return {
                ...state,
                signInError: null,
            };

        case AuthActionTypes.UPDATE_USER_SETTINGS:
            return {
                ...state,
                updateUserSettingsLoading: true,
            };

        case AuthActionTypes.UPDATE_USER_SETTINGS_SUCCESS:
            const userSettings = (action as UpdateUserSettingsSuccessAction).payload.userSettings;
            const authUser: TokenUser = {
                ...state.authUser,
                appData: {
                    ...userSettings,
                },
            };

            return {
                ...state,
                updateUserSettingsLoading: false,
                authUser,
            };

        case AuthActionTypes.UPDATE_USER_SETTINGS_FAILURE:
            return {
                ...state,
                updateUserSettingsLoading: false,
            };

        default:
            return state;
    }
}
