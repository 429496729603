import { createAction, props } from "@ngrx/store";
import { Ad } from "../state/ad.state";

export const loadAd = createAction(
    '[Ad] Load Ad'
);

export const setAd = createAction(
    '[Ad] Set Ad',
    props<{ data: Ad }>()
);

export const resetAd = createAction(
    '[Ad] Reset Ad'
);

export const activatePolling = createAction(
    '[Ad] Activate Polling'
);
