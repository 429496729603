import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { MenuItem } from '../../../core/models/menu.model';
import { RouterLinkActive, RouterLink } from '@angular/router';

@Component({
    selector: 'app-submenu-item',
    templateUrl: './submenu-item.component.html',
    styleUrls: ['./submenu-item.component.scss'],
    standalone: true,
    imports: [RouterLinkActive, RouterLink]
})
export class SubmenuItemComponent implements OnInit {
    @HostBinding('class.submenu-item') public submenuItemClass = true;
    @Input() public item: MenuItem;

    constructor() {
    }

    public ngOnInit() {
    }

}
