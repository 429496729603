/**
 * Created by Alex Klein <florian@rocketloop.de> on 10/17/2017.
 */

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { handleAuthenticationError } from '../../../../../core/helpers/errors.helper';
import * as RouterActions from '../../../../../core/store/actions/router.action';
import { ShortListResponse, WatchlistProductsCheckData, WatchlistProductsCheckResponse } from '../../api/models/watchlist.model';
import { ShortListApi } from '../../api/short-list.api';
import { WatchlistGroupApi } from '../../api/watchlist-group.api';
import { WatchlistApi } from '../../api/watchlist.api';
import { WatchlistErrors } from '../../errors/watchlist.errors';
import {
    Watchlist,
    WatchlistData,
    WatchlistImportValidationResult,
    WatchlistProductGroup,
    WatchlistsGroup,
    WatchlistValues,
} from '../../models/watchlist.model';
import {
    LoadShortlistAction,
    loadShortlistDataFailed,
    loadShortlistDataSucceeded, loadShortlistFailed,
    loadShortlistStructuresFailed,
    loadShortlistStructuresSucceeded, loadShortlistSucceeded,
    ShortlistActionTypes,
} from '../actions/shortlist.actions';
import {
    AddProductsToWatchlistAction,
    addProductsToWatchlistFailed,
    addProductsToWatchlistSucceeded,
    ClearWatchlistAction,
    createWatchlistFailed,
    CreateWatchlistsAction,
    createWatchlistSucceeded,
    DeleteWatchlistAction,
    deleteWatchlistFailed,
    deleteWatchlistSucceeded,
    ExecuteWatchlistImportAction,
    executeWatchlistImportFailed,
    executeWatchlistImportSucceeded,
    LoadSingleWatchlistDataAction,
    LoadSingleWatchlistStructureAction,
    loadSingleWatchlistStructureFailed,
    loadSingleWatchlistStructureSucceeded,
    LoadWatchlistAction,
    loadWatchlistData,
    LoadWatchlistDataAction,
    loadWatchlistDataSucceeded,
    LoadWatchlistEquivalenceDataAction,
    loadWatchlistEquivalenceDataFailed,
    loadWatchlistEquivalenceDataSucceeded,
    loadWatchlistFailed,
    LoadWatchlistsAction,
    loadWatchlistsFailed,
    LoadWatchlistsGroupAction,
    loadWatchlistsGroupFailed,
    loadWatchlistsGroupSucceeded,
    loadWatchlistsSucceeded,
    LoadWatchlistStructuresAction,
    loadWatchlistStructuresFailed,
    loadWatchlistStructuresSucceeded,
    loadWatchlistSucceeded,
    LoadWatchlistTabStructuresAction,
    loadWatchlistTabStructuresFailed,
    loadWatchlistTabStructuresSucceeded,
    LoadWatchlistValuesAction,
    loadWatchlistValuesFailed,
    loadWatchlistValuesSucceeded,
    PatchWatchlistAction,
    patchWatchlistFailed,
    patchWatchlistSucceeded, PerformTmcCheckAction, performTmcCheckFailed, performTmcCheckSucceeded,
    RemoveProductsFromWatchlistAction,
    removeProductsFromWatchlistFailed,
    UpdateWatchlistAction,
    updateWatchlistFailed,
    updateWatchlistSucceeded,
    ValidateProductsForWatchlistImportAction,
    validateProductsForWatchlistImportFailed,
    validateProductsForWatchlistImportSucceeded,
    WatchlistActionTypes,
} from '../actions/watchlist.actions';
import * as WatchlistActions from '../actions/watchlist.actions';

import { ProductDetailsErrors } from '../../../../product-details/errors/product-details.errors';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';

/**
 * Effect class for watchlist effects
 */
@Injectable()
export class WatchlistsEffects {

    /** Effect Declarations **/

    
    public loadWatchLists$ = createEffect(() => this.actions$.pipe(
        ofType(WatchlistActionTypes.LOAD_WATCHLISTS),
        switchMap((action: LoadWatchlistsAction) => this.loadWatchlists(action)),
    ));

    
    public loadWatchListsGroup$ = createEffect(() => this.actions$.pipe(
        ofType(WatchlistActionTypes.LOAD_WATCHLISTS_GROUP),
        switchMap((action: LoadWatchlistsGroupAction) => this.loadWatchlistsGroup(action)),
    ));

    
    public loadWatchList$ = createEffect(() => this.actions$.pipe(
        ofType(WatchlistActionTypes.LOAD_WATCHLIST),
        switchMap((action: LoadWatchlistAction) => this.loadWatchlist(action)),
    ));

    
    public loadWatchListValues$ = createEffect(() => this.actions$.pipe(
        ofType(WatchlistActionTypes.LOAD_WATCHLIST_VALUES),
        switchMap((action: LoadWatchlistValuesAction) => this.loadWatchlistValues(action)),
    ));

    
    public createWatchList$ = createEffect(() => this.actions$.pipe(
        ofType(WatchlistActionTypes.CREATE_WATCHLIST),
        switchMap((action: CreateWatchlistsAction) => this.createWatchlist(action)),
    ));

    
    public updateWatchList$ = createEffect(() => this.actions$.pipe(
        ofType(WatchlistActionTypes.UPDATE_WATCHLIST),
        switchMap((action: UpdateWatchlistAction) => this.updateWatchlist(action)),
    ));

    
    public patchWatchList$ = createEffect(() => this.actions$.pipe(
        ofType(WatchlistActionTypes.PATCH_WATCHLIST),
        switchMap((action: PatchWatchlistAction) => this.patchWatchlist(action)),
    ));

    
    public deleteWatchList$ = createEffect(() => this.actions$.pipe(
        ofType(WatchlistActionTypes.DELETE_WATCHLIST),
        switchMap((action: DeleteWatchlistAction) => this.deleteWatchlist(action)),
    ));

    
    public clearWatchList$ = createEffect(() => this.actions$.pipe(
        ofType(WatchlistActionTypes.CLEAR_WATCHLIST),
        switchMap((action: ClearWatchlistAction) => this.clearWatchlist(action)),
    ));

    
    public addProductsToWatchList$ = createEffect(() => this.actions$.pipe(
        ofType(WatchlistActionTypes.ADD_PRODUCTS_TO_WATCHLIST),
        switchMap((action: AddProductsToWatchlistAction) => this.addProductsToWatchlist(action)),
    ));

    
    public removeProductsFromWatchList$ = createEffect(() => this.actions$.pipe(
        ofType(WatchlistActionTypes.REMOVE_PRODUCTS_FROM_WATCHLIST),
        switchMap((action: RemoveProductsFromWatchlistAction) => this.removeProductsFromWatchlist(action)),
    ));

    
    public loadWatchlistStructures$ = createEffect(() => this.actions$.pipe(
        ofType(WatchlistActionTypes.LOAD_WATCHLIST_STRUCTURES),
        switchMap((action: LoadWatchlistStructuresAction) => this.loadWatchlistStructures(action)),
    ));

    
    public loadWatchlistTabStructures$ = createEffect(() => this.actions$.pipe(
        ofType(WatchlistActionTypes.LOAD_WATCHLIST_TAB_STRUCTURES),
        switchMap((action: LoadWatchlistTabStructuresAction) => this.loadWatchlistTabStructures(action)),
    ));

    
    public loadShortlistStructures$ = createEffect(() => this.actions$.pipe(
        ofType(ShortlistActionTypes.LOAD_SHORTLIST_STRUCTURES),
        switchMap((action: LoadWatchlistStructuresAction) => this.loadShortlistStructures(action)),
    ));

    
    public loadSingleWatchlistStructure$ = createEffect(() => this.actions$.pipe(
        ofType(WatchlistActionTypes.LOAD_SINGLE_WATCHLIST_STRUCTURE),
        switchMap((action: LoadSingleWatchlistStructureAction) => this.loadSingleWatchlistStructure(action)),
    ));

    
    public loadWatchlistData$ = createEffect(() => this.actions$.pipe(
        ofType(WatchlistActionTypes.LOAD_WATCHLIST_DATA),
        switchMap((action: LoadWatchlistDataAction) => this.loadWatchlistData(action)),
    ));

    
    public loadShortListData$ = createEffect(() => this.actions$.pipe(
        ofType(ShortlistActionTypes.LOAD_SHORTLIST_DATA),
        switchMap((action: LoadWatchlistDataAction) => this.loadShortlistData(action)),
    ));

    
    public loadSingleWatchlistData$ = createEffect(() => this.actions$.pipe(
        ofType(WatchlistActionTypes.LOAD_SINGLE_WATCHLIST_DATA),
        switchMap((action: LoadSingleWatchlistDataAction) => this.loadSingleWatchlistData(action)),
    ));

    
    public loadShortList$ = createEffect(() => this.actions$.pipe(
        ofType(ShortlistActionTypes.LOAD_SHORTLIST),
        switchMap((action: LoadShortlistAction) => this.loadShortlist(action)),
    ));

    
    public importWatchlistValidation$ = createEffect(() => this.actions$.pipe(
        ofType(WatchlistActionTypes.VALIDATE_WATCHLIST_IMPORT),
        switchMap((action: ValidateProductsForWatchlistImportAction) => this.validateProductsForWatchlistImport(action)),
    ));

    
    public executeWatchlistImport$ = createEffect(() => this.actions$.pipe(
        ofType(WatchlistActionTypes.EXECUTE_WATCHLIST_IMPORT),
        switchMap((action: ExecuteWatchlistImportAction) => this.executeWatchlistImport(action)),
    ));

    
    public loadWatchlistEquivalenceData$ = createEffect(() => this.actions$.pipe(
        ofType(WatchlistActionTypes.LOAD_WATCHLIST_EQUIVALENCE_DATA),
        switchMap((action: LoadWatchlistEquivalenceDataAction) => this.loadWatchlistEquivalenceData(action)),
    ));

    
    public performTmcCheckRequest$ = createEffect(() => this.actions$.pipe(
        ofType(WatchlistActionTypes.PERFORM_TMC_CHECK),
        mergeMap((action: PerformTmcCheckAction) => this.onPerformTmcCheckRequest(action)),
    ));

    constructor(
        private actions$: Actions,
        private watchlistApi: WatchlistApi,
        private watchlistGroupApi: WatchlistGroupApi,
        private shortlistApi: ShortListApi,
        private translocoService: TranslocoService,
        private toastrService: ToastrService
    ) { }

    /** Effect Handler **/

    /**
     * Effect handler called upon the LoadWatchlistsAction
     *
     * This effect handler calls the corresponding API method to load the watchlists
     * @param action
     * @returns {Observable<Action>}
     */
    public loadWatchlists(action: LoadWatchlistsAction): Observable<Action> {
        return this.watchlistApi
        .getWatchlists().pipe(
            map((data: Watchlist[]) => loadWatchlistsSucceeded(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: any) => of(loadWatchlistsFailed({
                errorCode: WatchlistErrors.UNKNOWN,
                httpError: true,
                statusCode: err.status,
            }))),
        );
    }

    public loadWatchlistsGroup(action: LoadWatchlistsGroupAction): Observable<Action> {
        return this.watchlistGroupApi
        .getWatchlistsGroup(action.payload.moduleId).pipe(
            map((data: WatchlistsGroup) => loadWatchlistsGroupSucceeded(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: any) => of(loadWatchlistsGroupFailed({
                errorCode: WatchlistErrors.UNKNOWN,
                httpError: true,
                statusCode: err.status,
            }))),
        );
    }

    /**
     * Effect handler called upon the LoadWatchlistAction
     *
     * This effect handler calls the corresponding API method to load the watchlist with the given id
     * @param action
     * @returns {Observable<Action>}
     */
    public loadWatchlist(action: LoadWatchlistAction): Observable<Action> {
        return this.watchlistApi
        .getWatchlist(action.payload.watchlistId).pipe(
            map((data: Watchlist) => loadWatchlistSucceeded(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: any) => of(loadWatchlistFailed({
                errorCode: WatchlistErrors.UNKNOWN,
                httpError: true,
                statusCode: err.status,
            }))),
        );
    }

    /**
     * Effect handler called upon the LoadWatchlistValuesAction
     *
     * This effect handler calls the corresponding API method to load the watchlist values
     * @param action
     * @returns {Observable<Action>}
     */
    public loadWatchlistValues(action: LoadWatchlistValuesAction): Observable<Action> {
        return this.watchlistApi
        .getWatchlistValues().pipe(
            map((data: WatchlistValues) => loadWatchlistValuesSucceeded(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: any) => of(loadWatchlistValuesFailed({
                errorCode: WatchlistErrors.UNKNOWN,
                httpError: true,
                statusCode: err.status,
            }))),
        );
    }

    /**
     * Effect handler called upon the CreateWatchlistsAction
     *
     * This effect handler calls the corresponding API method to create a new watchlist
     * @param action
     * @returns {Observable<Action>}
     */
    public createWatchlist(action: CreateWatchlistsAction): Observable<Action> {
        return this.watchlistApi
        .createWatchlist(action.payload.request).pipe(
            map((data: Watchlist) => createWatchlistSucceeded(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: any) => of(createWatchlistFailed({
                errorCode: WatchlistErrors.UNKNOWN,
                httpError: true,
                statusCode: err.status,
            }))),
        );
    }

    /**
     * Effect handler called upon the UpdateWatchlistAction
     *
     * This effect handler calls the corresponding API method to update an existing watchlist
     * @param action
     * @returns {Observable<Action>}
     */
    public updateWatchlist(action: UpdateWatchlistAction): Observable<Action> {
        return this.watchlistApi
        .updateWatchlist(action.payload.watchlistId, action.payload.data).pipe(
            map((data: Watchlist) => updateWatchlistSucceeded(data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: any) => of(updateWatchlistFailed({
                errorCode: WatchlistErrors.UNKNOWN,
                httpError: true,
                statusCode: err.status,
            }))),
        );
    }

    /**
     * Effect handler called upon the PatchWatchlistAction
     *
     * This effect handler calls the corresponding API method to patch an existing watchlist
     * @param action
     * @returns {Observable<Action>}
     */
    public patchWatchlist(action: PatchWatchlistAction): Observable<Action> {
        return this.watchlistApi
        .patchWatchlist(action.payload.watchlistId, action.payload.data).pipe(
            mergeMap((data: Watchlist) => {
                return [
                    patchWatchlistSucceeded(data),
                    new RouterActions.Go({
                        path: [
                            '/app',
                            { outlets: { overlay: ['watchlist-details', action.payload.watchlistId] } },
                        ],
                    }),
                ];
            }),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: any) => of(patchWatchlistFailed({
                errorCode: WatchlistErrors.UNKNOWN,
                httpError: true,
                statusCode: err.status,
            }))),
        );
    }

    /**
     * Effect handler called upon the DeleteWatchlistAction
     *
     * This effect handler calls the corresponding API method to delete an existing watchlist
     * @param action
     * @returns {Observable<Action>}
     */
    public deleteWatchlist(action: DeleteWatchlistAction): Observable<Action> {
        return this.watchlistApi
        .deleteWatchList(action.payload.watchlistId).pipe(
            map(() => deleteWatchlistSucceeded(action.payload.watchlistId)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: any) => of(deleteWatchlistFailed({
                errorCode: WatchlistErrors.UNKNOWN,
                httpError: true,
                statusCode: err.status,
            }))),
        );
    }

    /**
     * Effect handler called upon the DeleteWatchlistAction
     *
     * This effect handler calls the corresponding API method to delete an existing watchlist
     * @param action
     * @returns {Observable<Action>}
     */
    public clearWatchlist(action: DeleteWatchlistAction): Observable<Action> {
        return this.watchlistApi
        .deleteWatchList(action.payload.watchlistId).pipe(
            map(() => deleteWatchlistSucceeded(action.payload.watchlistId)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: any) => of(deleteWatchlistFailed({
                errorCode: WatchlistErrors.UNKNOWN,
                httpError: true,
                statusCode: err.status,
            }))),
        );
    }

    /**
     * Effect handler called upon the DeleteWatchlistAction
     *
     * This effect handler calls the corresponding API method to delete an existing watchlist
     * @param action
     * @returns {Observable<Action>}
     */
    public addProductsToWatchlist(action: AddProductsToWatchlistAction): Observable<Action> {
        return this.watchlistApi
        .addProductsToWatchlist(action.payload.watchlistId, action.payload.products).pipe(
            map((watchlist: Watchlist) => addProductsToWatchlistSucceeded(watchlist)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: any) => of(addProductsToWatchlistFailed({
                errorCode: WatchlistErrors.UNKNOWN,
                httpError: true,
                statusCode: err.status,
            }))),
        );
    }

    /**
     * Effect handler called upon the DeleteWatchlistAction
     *
     * This effect handler calls the corresponding API method to delete an existing watchlist
     * @param action
     * @returns {Observable<Action>}
     */
    public removeProductsFromWatchlist(action: RemoveProductsFromWatchlistAction): Observable<Action> {
        return this.watchlistApi
        .removeProductsFromWatchlist(action.payload.watchlistId, action.payload.products).pipe(
            mergeMap((watchlist: Watchlist) => [loadWatchlistData(action.payload.watchlistId)]),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: any) => of(removeProductsFromWatchlistFailed({
                errorCode: WatchlistErrors.UNKNOWN,
                httpError: true,
                statusCode: err.status,
            }))),
        );
    }

    /**
     * Effect handler called upon the DeleteWatchlistAction
     *
     * This effect handler calls the corresponding API method to delete an existing watchlist
     * @param action
     * @returns {Observable<Action>}
     */
    public loadWatchlistStructures(action: LoadWatchlistStructuresAction): Observable<Action> {
        return this.watchlistApi
        .getWatchlistStructures(action.payload.watchlistId).pipe(
            map((data) => {
                return loadWatchlistStructuresSucceeded(
                    action.payload.watchlistId,
                    data.actions,
                    data.structures,
                    data.title,
                    data.tabs,
                );
            }),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: any) => of(loadWatchlistStructuresFailed({
                errorCode: WatchlistErrors.UNKNOWN,
                httpError: true,
                statusCode: err.status,
            }))),
        );
    }

    public loadShortlistStructures(action: LoadWatchlistStructuresAction): Observable<Action> {
        return this.shortlistApi
        .getShortListStructures(action.payload.watchlistId).pipe(
            map((data) => {
                return loadShortlistStructuresSucceeded(
                    action.payload.watchlistId,
                    data.actions,
                    data.structures,
                );
            }),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: any) => of(loadShortlistStructuresFailed({
                errorCode: WatchlistErrors.UNKNOWN,
                httpError: true,
                statusCode: err.status,
            }))),
        );
    }

    /**
     * Effect handler called upon the DeleteWatchlistAction
     *
     * This effect handler calls the corresponding API method to delete an existing watchlist
     * @param action
     * @returns {Observable<Action>}
     */
    public loadSingleWatchlistStructure(action: LoadSingleWatchlistStructureAction): Observable<Action> {
        return this.watchlistApi
        .getWatchlistStructure(action.payload.watchlistId, action.payload.groupId).pipe(
            map((structure: WatchlistProductGroup) => loadSingleWatchlistStructureSucceeded(structure)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: any) => of(loadSingleWatchlistStructureFailed({
                errorCode: WatchlistErrors.UNKNOWN,
                httpError: true,
                statusCode: err.status,
            }))),
        );
    }

    /**
     * Effect handler called upon the DeleteWatchlistAction
     *
     * This effect handler calls the corresponding API method to delete an existing watchlist
     * @param action
     * @returns {Observable<Action>}
     */
    public loadWatchlistData(action: LoadWatchlistDataAction): Observable<Action> {
        return this.watchlistApi
        .getWatchlistData(action.payload.watchlistId).pipe(
            map((data: WatchlistData) => loadWatchlistDataSucceeded(data, action.payload.watchlistId)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: any) => of(loadSingleWatchlistStructureFailed({
                errorCode: WatchlistErrors.UNKNOWN,
                httpError: true,
                statusCode: err.status,
            }))),
        );
    }

    public loadShortlistData(action: LoadWatchlistDataAction): Observable<Action> {
        return this.shortlistApi
        .getShortListData(action.payload.watchlistId).pipe(
            map((data: WatchlistData) => loadShortlistDataSucceeded(data, action.payload.watchlistId)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: any) => of(loadShortlistDataFailed({
                errorCode: WatchlistErrors.UNKNOWN,
                httpError: true,
                statusCode: err.status,
            }))),
        );
    }

    /**
     * Effect handler called upon the DeleteWatchlistAction
     *
     * This effect handler calls the corresponding API method to delete an existing watchlist
     * @param action
     * @returns {Observable<Action>}
     */
    public loadSingleWatchlistData(action: LoadSingleWatchlistDataAction): Observable<Action> {
        return this.watchlistApi
        .getSingleWatchlistData(action.payload.watchlistId, action.payload.groupId).pipe(
            map((structure: WatchlistProductGroup) => loadSingleWatchlistStructureSucceeded(structure)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: any) => of(loadSingleWatchlistStructureFailed({
                errorCode: WatchlistErrors.UNKNOWN,
                httpError: true,
                statusCode: err.status,
            }))),
        );
    }

    public loadShortlist(action: LoadShortlistAction) {
        const shortlistId = action.payload.shortlistId;

        return this.shortlistApi
        .getShortList(shortlistId, action.payload.tmc).pipe(
            map((res: ShortListResponse) => loadShortlistSucceeded(res.data, res.structure, shortlistId)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: any) => of(loadShortlistFailed({
                errorCode: WatchlistErrors.UNKNOWN,
                httpError: true,
                statusCode: err.status,
            }, shortlistId))),
        );
    }

    private validateProductsForWatchlistImport(action: ValidateProductsForWatchlistImportAction) {
        return this.watchlistApi
        .validateWatchlistImportProducts(action.payload.watchlistId, action.payload.productIds).pipe(
            map((data: WatchlistImportValidationResult) => validateProductsForWatchlistImportSucceeded(action.payload.watchlistId, data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: any) => of(validateProductsForWatchlistImportFailed(action.payload.watchlistId, {
                errorCode: WatchlistErrors.UNKNOWN,
                httpError: true,
                statusCode: err.status,
            }))),
        );
    }

    private executeWatchlistImport(action: ExecuteWatchlistImportAction) {
        return this.watchlistApi
        .executeWatchlistImportProducts(action.payload.watchlistId, action.payload.products, action.payload.reset).pipe(
            map((data: Watchlist) => executeWatchlistImportSucceeded(action.payload.watchlistId, data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: any) => of(executeWatchlistImportFailed(action.payload.watchlistId, {
                errorCode: WatchlistErrors.UNKNOWN,
                httpError: true,
                statusCode: err.status,
            }))),
        );
    }

    private loadWatchlistTabStructures(action: LoadWatchlistTabStructuresAction) {
        return this.watchlistApi
            .getWatchlistTabsStructure(action.payload.watchlistId).pipe(
                map((data) => {
                    return loadWatchlistTabStructuresSucceeded(
                        action.payload.watchlistId,
                        data.tabs,
                        data.title,
                    );
                }),
                catchError((err) => handleAuthenticationError(err, action)),
                catchError((err: any) => of(loadWatchlistTabStructuresFailed(action.payload.watchlistId, {
                    errorCode: WatchlistErrors.UNKNOWN,
                    httpError: true,
                    statusCode: err.status,
                }))),
            );
    }

    private loadWatchlistEquivalenceData(action: LoadWatchlistEquivalenceDataAction) {
        return this.watchlistApi
            .getWatchlistEquivalenceData(
                action.payload.watchlistId,
                action.payload.deriBwIds
            ).pipe(
                map((data) => {
                    return loadWatchlistEquivalenceDataSucceeded(
                        action.payload.watchlistId,
                        data.resultId,
                        data.checkResult,
                    );
                }),
                catchError((err) => handleAuthenticationError(err, action)),
                catchError((err: any) => of(loadWatchlistEquivalenceDataFailed(action.payload.watchlistId, {
                    errorCode: WatchlistErrors.UNKNOWN,
                    httpError: true,
                    statusCode: err.status,
                }))),
            );
    }

    public updateWatchlistEquivalenceComments$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(WatchlistActions.updateWatchlistEquivalenceComments),
            switchMap((action) => 
                this.watchlistApi.updateWatchlistEquivalenceComments(
                    action.watchlistId,
                    action.comments
                ).pipe(
                    tap(() => this.toastrService.show(
                        this.translocoService.translate('watchlists.equivalenceCheck.commentsSaved')
                    ))
                )
            )
        );
    }, { dispatch: false });

    private onPerformTmcCheckRequest(action: PerformTmcCheckAction) {
        return this.watchlistApi.performTmcCheck(action.payload.watchlistId).pipe(
            map((data: WatchlistProductsCheckData) => performTmcCheckSucceeded(action.payload.watchlistId, data)),
            catchError((err) => handleAuthenticationError(err, action)),
            catchError((err: any) => of(performTmcCheckFailed(action.payload.watchlistId, {
                errorCode: ProductDetailsErrors.UNKNOWN,
                httpError: true,
                statusCode: err.statusCode,
                originalError: err,
            }))),
        );
    }
}
