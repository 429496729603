export interface Configuration {
    theme: string;
    api: {
        duse: {
            baseUrl: string;
        };
        derifin: {
            baseUrl: string;
            dmzUrl: string;
        };
        dericonId: {
            baseUrl: string;
        },
        mercury: {
            baseUrl: string;
        }
    };
    environment: {
        id: string;
        translationId: string;
        organizationId?: string,
        colorScheme: { [colorName: string]: string },
        assets: {
            logo: string;
            logoInverse: string;
        };

        logoUseCustomUser: boolean,
        logoUserMap: { [userId: string]: { logo: string; logoInverse: string; } }
        chartColors: {
            default: string[],
            bubble: string[],
            pie?: string[],
        };
        fakeAdvisoryProductsList: string;
        showOSPlusTab: boolean;
        profileUseDisplayName: boolean;
        useHamburgerMenuButtonLabel: boolean;
    };
    logging: {
        logglyKey: string,
        logglyEnabled: boolean,
        logglyEnvironment?: boolean,
    };
}

export function createConfiguration(params: Partial<Configuration>) {
    return {
        theme: 'default',
        api: {
            duse: {
                baseUrl: 'https://cloud.derif.in/duse/api',
            },
            derifin: {
                baseUrl: 'https://cloud.derif.in/broker/api',
                dmzUrl: 'https://cloud.derif.in/broker/dmz',
            },
            dericonId: {
                baseUrl: 'https://id.dericon.it',
            },
            mercury: {
                baseUrl: 'http://mercury.dericon.it',
            },
        },
        environment: {
            id: 'QQD3D3M0',
            organizationId: '',
            translationId: 'de',
            assets: {
                logo: '/assets/img/derifin-logo-blue.png',
                logoInverse: '/assets/img/derifin-logo-white.png',
            },

            logoUseCustomUser: false,
            logoUserMap: {},
            colorScheme: {
                primary: '#003f5e',
                secondary: '#919191',
            },
            chartColors: {
                default: [
                    '#003f5e',
                    '#919191',
                    '#e6194b',
                    '#0082c8',
                ],
                bubble: [
                    '#e6194b',
                    '#3cb44b',
                    '#ffe119',
                    '#0082c8',
                    '#f58231',
                    '#911eb4',
                    '#46f0f0',
                    '#f032e6',
                    '#d2f53c',
                    '#fabebe',
                    '#008080',
                    '#e6beff',
                    '#aa6e28',
                    '#fffac8',
                    '#800000',
                    '#aaffc3',
                    '#808000',
                    '#ffd8b1',
                ],
            },
        },
        logging: {
            logglyEnabled: true,
            logglyKey: '84a07d13-365f-43f4-949a-35ec8d4c2112',
        },
        ...params,
    } as Configuration;
}
