<ng-container *ngIf="processedData">
    <div class="row">
        <div class="col entity-widget-market-data__table-column" *ngFor="let column of columnizedData; let isFirstColumn = first">
            <ng-container *ngFor="let ref of column; let isFirstRef = first">
                <ng-container *ngIf="ref.type === 'table'">
                    <table class="table table-hover entity-table">
                        <thead>
                            <tr>
                                <th width="40%" class="entity-table-header ">{{ref.label}}</th>
                                <th width="25%" class="entity-table-header entity-table-cell--right-aligned">Kurs</th>
                                <th width="25%" class="entity-table-header entity-table-cell--right-aligned">%</th>
                                <th width="10%" class="entity-table-header entity-table-cell--right-aligned" *ngIf="options?.enableGraph"></th>
                            </tr>
                        </thead>
                        <tbody>

                            <ng-container *ngFor="let dataRef of ref.value">
                                <ng-container *ngIf="resolveDataRef(dataRef) as row">
                                    <tr class="app-entity-table-row vmiddle" [class.app-entity-table-row-selected--chart]="selectedInstrument?.dq === row.dq && options?.enableGraph">
                                        <td class="entity-table-cell">
                                            <ng-container *ngIf="hasFactsheet(row) else: noFactsheetText">
                                                <a href="#" class="entity-widget-market-data__link" (click)="onClickInstrumentRow($event, row)">{{row.label}}</a>
                                            </ng-container>
                                            <ng-template #noFactsheetText>
                                                {{row.label}}
                                            </ng-template>
                                        </td>
                                        <td [class]="getClassesForInstrumentColumn(row, 'quote')"><span>{{ (row.quote || row.quote === 0) ? (row.quote | translocoDecimal) : '-' }}</span></td>
                                        <td [class]="getClassesForInstrumentColumn(row, 'prevDayPerformance', true)"><span>{{ (row.prevDayPerformance || row.prevDayPerformance === 0) ? (row.prevDayPerformance | translocoPercent) : '-' }}</span></td>
                                        <td class="entity-table-cell entity-table-cell--right-aligned" style="padding-right: 0.5rem" *ngIf="hasFactsheet(row) && options?.enableGraph"><a href="" (click)="onClickOpenFactsheet($event, row)">Factsheet</a></td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </tbody>
                    </table>
                </ng-container>
            </ng-container>
        </div>

        <div class="col-6 entity-widget-market-data__chart-column" *ngIf="options?.enableGraph">
            <div *ngIf="additionalData" class="chart-container">
                <div class="align-both-center" style="position: absolute" *ngIf="additionalData.loading">
                    <app-spinner type="small-boxes"></app-spinner>
                </div>

                <ng-container *ngIf="graphHasData; else noDataView">
                    <app-dynamic-chart-new [useParentWidth]="true"
                                           [options]="additionalData?.data?.options"
                                           [data]="additionalData?.data?.chart"
                    ></app-dynamic-chart-new>
                </ng-container>
                <ng-template #noDataView>
                    <div class="align-both-center">
                        <span>Keine Graphdaten für den gewählten Kurs vorhanden.</span>
                    </div>
                </ng-template>

                <div class="entity-widget-market-data__filter-buttons app-filter-radio-button-group">
                    <mat-button-toggle-group [value]="selectedButton" (change)="onClickFilterButton($event)">
                        <mat-button-toggle *ngFor="let value of graphButtons" [value]="value" disableRipple="true">
                            {{ value.text }}
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            </div>
        </div>
    </div>
</ng-container>
