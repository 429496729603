import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import { UserApi } from "../../api/user.api";
import { handleAuthenticationError } from "../../helpers/errors.helper";
import * as UserActions from "../actions/user.actions";

@Injectable()
export class UserEffects {

    constructor(
        private actions$: Actions,
        private userApi: UserApi
    ) { }

    public loadShortcutsGroups$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UserActions.loadShortcuts),
            switchMap((action) => 
                this.userApi.getShortcuts().pipe(
                    map((shortcuts) => 
                        UserActions.setShortcuts({ shortcuts })
                    ),
                    catchError((err) => handleAuthenticationError(err, action))
                )
            )
        );
    });
}

