<div *ngIf="genericActionsStructure && genericActionsStructure.length > 0" class="actions-container">
    <ul class="entity-actions">
        <ng-container *ngFor="let action of genericActionsStructure">
            <li class="entity-actions__action" [class.entity-actions__action-icon]="!!action.icon" *ngIf="isVisibilityTriggeredForAction(action) && !isHideTriggeredForAction(action)">
                <a
                    class="entity-actions__action-link"
                    [class.entity-actions__action-disabled]="!isFadeTriggeredForAction(action) || isFadeOutTriggeredForAction(action)"
                    href="#"
                    id="{{action?.type}}"
                    (click)="onActionClicked(action)">
                    <app-icon [icon]="icon(action.icon)" *ngIf="action.icon"></app-icon>
                    <span *ngIf="action?.translateLabel">{{ action?.label | transloco }}</span>
                    <span *ngIf="!action?.translateLabel">{{ action?.label }}</span>
                </a>
            </li>
        </ng-container>
    </ul>
</div>
