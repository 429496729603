/**
 * Created by Alex Klein <florian@rocketloop.de> on 8/14//17.
 */

import {Action, combineReducers, createReducer, on} from '@ngrx/store';
import {clone, get, setWith} from 'lodash';
import {Data} from '../../../../core/models/data.model';
import {FormValues} from '../../../../core/models/form.model';
import {EntitiesState} from '../../../../core/store/state/entities.state';
import {StringMappingState} from '../../../../core/store/state/mapping.state';
import {
    ProductSharedActionTypes,
    UpdateProductActivationSucceededAction,
    UpdateProductNotesSucceededAction,
} from '../../../shared/product-shared/store/actions/product-shared.actions';
import {
    FactsheetUniverseData,
    ProductChangelog,
    ProductDetailsFormStructure,
    ProductDetailsStructure
} from '../../models/product-details-structure.model';

import {
    DeleteProductAttachmentSuccessAction,
    ProductAttachmentsActionTypes,
    ProductAttachmentUploadedAction,
} from '../actions/product-attachments.actions';
import {
    DeleteProductFormDataSuccessAction,
    LoadProductFormInitialSuccessAction,
    LoadProductFormStructureFailureAction,
    LoadProductFormStructureSuccessAction,
    LoadProductFormValuesFailureAction,
    LoadProductFormValuesSuccessAction,
    ProductFormActions,
    SaveProductFormDataSuccessAction,
    UpdateProductFormData,
} from '../actions/product-form.actions';
import {
    LoadFactsheetUniverseDataSucceededAction,
    ProductDetailsActionTypes,
    ProductDetailsDataSuccessAction,
    ProductDetailsStructureSuccessAction,
    ProductDetailsTabStructureFailureAction,
    ProductDetailsTabStructureSuccessAction,
    ProductDetailsValuesSuccessAction,
    UpdatedProductDetailsDataSuccessAction,
    UpdateSelectedTabIdAction,
} from '../actions/product-details.actions';
import * as ProductDetailsActions from '../actions/product-details.actions';
import {ProductDetailsMetaState, ProductDetailsState} from '../state/product-details.state';
import {
    ProductDocumentActionTypes, ProductDocumentDeleteSuccessAction,
    ProductDocumentUploadFailureAction,
    ProductDocumentUploadRequestAction,
    ProductDocumentUploadSuccessAction
} from '../actions/product-document.actions';
import { ActionTypes } from '../../../../core/store/actions/ui.action';
import * as moment from 'moment';

export const initialState: ProductDetailsState = {
    structures: {},
    data: {},
    values: {},
    universes: {},
    changelog: {
        loading: true,
        empty: true,
        userList: [],
        changes: [],
        filteredChanges: []
    },
    currentSessionMapping: {},
    form: {
        values: {},
        initial: {},
        structures: {},
    },
    meta: {
        title: null,
        subTitle: null,
        loadingData: false,
        loadingStructure: false,
        productAttachmentDeleted: null,
        currentTabId: null,
        tabs: {},
        loadingDetails: null,
        loadingUniverse: false,
        errorLoadingDetails: null,
        loadingFormStructure: false,
        loadingFormInitial: false,
        loadingFormValues: false,
        loadingFormSave: false,
        loadingTMCData: false,
        loadingTMCStructure: false,
        successFormSave: null,
        successFormSaveMessage: null,
        successFormSaveState: null,
        loadingFormDelete: false,
        successFormDelete: null,
        resultFormDeleteMessage: null,
        resultFormDeleteState: null,
        errorFormInitial: null,
        errorFormValues: null,
        errorFormStructure: null,
        errorFormDelete: null,
        errorLoadingTMCData: null,
        errorLoadingTMCStructure: null,
    },
};

/**
 * The reducer responsible for the product details structures @link{ProductDetailsState}
 * @param state
 * @param action
 * @returns {EntitiesState<ProductState>}
 */
export function productDetailsStructuresReducer(
    state: EntitiesState<ProductDetailsStructure> = initialState.structures,
    action: Action,
): EntitiesState<ProductDetailsStructure> {
    switch (action.type) {

        case ProductDetailsActionTypes.PRODUCT_DETAILS_STRUCTURE_SUCCESS:
            const productDetailsStructureLoadedAction = action as ProductDetailsStructureSuccessAction;
            return {
                [productDetailsStructureLoadedAction.payload.sessionId]: productDetailsStructureLoadedAction.payload.structure,
            };

        default:
            return state;
    }
}

export function productUniversesReducer(
    state: EntitiesState<FactsheetUniverseData> = initialState.universes,
    action: Action,
): EntitiesState<FactsheetUniverseData> {
    switch (action.type) {
        case ProductDetailsActionTypes.LOAD_FACTSHEET_UNIVERSE_DATA_SUCCEEDED:
            const loadFactsheetUniverseDataSucceededAction = action as LoadFactsheetUniverseDataSucceededAction;

            return {
                ...state,
                [loadFactsheetUniverseDataSucceededAction.payload.sessionId]: loadFactsheetUniverseDataSucceededAction.payload.data,
            };

        default:
            return state;
    }
}

export const productChangelogReducer = createReducer(initialState.changelog,
    on(ProductDetailsActions.setChangelog, (state, { changelog }): ProductChangelog => {
        const dates = changelog.changes.map(change => moment(change.timestamp));

        return { ...state, 
            ...changelog,
            loading: false,
            empty: changelog.changes.length === 0,
            userList: [...new Set(changelog.changes.map(change => change.userDisplayName))],
            filteredChanges: changelog.changes,
            startDate: moment.min(dates).toDate(),
            endDate: moment.max(dates).toDate()
        };
    }),
    on(ProductDetailsActions.filterChangelog, (state, { filter }): ProductChangelog => {
        const startDate = filter.startDate ? filter.startDate : state.startDate;
        const endDate = filter.endDate ? filter.endDate : state.endDate;
        let filteredChanges = [];

        if (filter.user) {
            filteredChanges = state.changes.filter(change => 
                change.userDisplayName === filter.user
            );
        } else {
            filteredChanges = state.changes;
        }

        return { ...state,
            filteredChanges: filteredChanges.filter(change => 
                moment(change.timestamp).isBetween(startDate, endDate, 'days', '[]')
            )
        };
    })
);

/**
 * The reducer responsible for the product details fields data of the @link{ProductDetailsState}
 * @param state
 * @param action
 * @returns {EntitiesState<ProductState>}
 */
export function productQuerySetMappingReducer(
    state: EntitiesState<Data> = initialState.data,
    action: Action,
): EntitiesState<Data> {

    switch (action.type) {

        case ProductDetailsActionTypes.PRODUCT_DETAILS_DATA_SUCCESS:
            const productDetailsDataLoadedAction = action as ProductDetailsDataSuccessAction;
            return {
                [productDetailsDataLoadedAction.payload.sessionId]: productDetailsDataLoadedAction.payload.data,
            };

        case ProductDetailsActionTypes.UPDATED_PRODUCT_DETAILS_DATA_SUCCESS:
            const updatedProductDetailsDataLoadedAction = action as UpdatedProductDetailsDataSuccessAction;
            return {
                ...state,
                [updatedProductDetailsDataLoadedAction.payload.sessionId]: {
                    ...state[updatedProductDetailsDataLoadedAction.payload.sessionId],
                    ...updatedProductDetailsDataLoadedAction.payload.data,
                },
            };

        case ProductSharedActionTypes.UPDATE_PRODUCT_NOTES_SUCCEEDED:
            const updateProductNotesSucceededAction = action as UpdateProductNotesSucceededAction;
            const attachments = get(
                state[updateProductNotesSucceededAction.payload.sessionId],
                updateProductNotesSucceededAction.payload.dataPath
            ).attachments;

            return {
                ...state,
                [updateProductNotesSucceededAction.payload.sessionId]:
                    setWith(
                        clone(state[updateProductNotesSucceededAction.payload.sessionId]),
                        updateProductNotesSucceededAction.payload.dataPath,
                        { ...updateProductNotesSucceededAction.payload.notes, attachments }, 
                        clone
                    )
            };

        case ProductSharedActionTypes.UPDATE_PRODUCT_ACTIVATION_SUCCEEDED:
            const updateProductActivationSucceededAction = action as UpdateProductActivationSucceededAction;
            return {
                ...state,
            };

        case ProductAttachmentsActionTypes.PRODUCT_ATTACHMENT_UPLOADED:
            const productAttachmentUploadedAction = action as ProductAttachmentUploadedAction;

            return {
                ...state,
                [productAttachmentUploadedAction.payload.uploadFile.context.sessionId]: setWith(
                    clone(state[productAttachmentUploadedAction.payload.uploadFile.context.sessionId]),
                    productAttachmentUploadedAction.payload.uploadFile.context.dataPath,
                    [
                        ...get(state[productAttachmentUploadedAction.payload.uploadFile.context.sessionId],
                            productAttachmentUploadedAction.payload.uploadFile.context.dataPath,
                            [],
                        ),
                        productAttachmentUploadedAction.payload.uploadFile.response.data,
                    ],
                    clone,
                ),
            };

        case ProductAttachmentsActionTypes.DELETE_PRODUCT_ATTACHMENT_SUCCESS:
            const deleteProductAttachmentSucceededAction = action as DeleteProductAttachmentSuccessAction;
            const attachmentData = get(state[deleteProductAttachmentSucceededAction.payload.sessionId],
                deleteProductAttachmentSucceededAction.payload.dataPath,
            ).filter((attachment) => attachment.id !== deleteProductAttachmentSucceededAction.payload.attachmentId);
            return {
                ...state,
                [deleteProductAttachmentSucceededAction.payload.sessionId]: setWith(
                    clone(state[deleteProductAttachmentSucceededAction.payload.sessionId]),
                    deleteProductAttachmentSucceededAction.payload.dataPath,
                    attachmentData,
                    clone,
                ),
            };

        case ProductDocumentActionTypes.PRODUCT_DOCUMENT_UPLOAD_REQUEST: {
            const currentAction = action as ProductDocumentUploadRequestAction;
            return state;
        }
        case ProductDocumentActionTypes.PRODUCT_DOCUMENT_UPLOAD_SUCCESS: {
            const currentAction = action as ProductDocumentUploadSuccessAction;
            return {
                ...state,
                [currentAction.payload.sessionId]: setWith(
                    clone(state[currentAction.payload.sessionId]),
                    currentAction.payload.path,
                    currentAction.payload.response
                )
            };
        }
        case ProductDocumentActionTypes.PRODUCT_DOCUMENT_UPLOAD_FAILURE: {
            const currentAction = action as ProductDocumentUploadFailureAction;
            return state;
        }
        case ProductDocumentActionTypes.PRODUCT_DOCUMENT_DELETE_SUCCESS: {
            const currentAction = action as ProductDocumentDeleteSuccessAction;
            return {
                ...state,
                [currentAction.payload.sessionId]: setWith(
                    clone(state[currentAction.payload.sessionId]),
                    currentAction.payload.path,
                    undefined
                )
            };
        }

        default:
            return state;
    }
}

/**
 * The reducer responsible for the mapping of product id to session id in the @link{ProductDetailsState}
 * @param state
 * @param action
 * @returns {EntitiesState<ProductState>}
 */
export function currentSessionMappingReducer(
    state: StringMappingState<string> = initialState.currentSessionMapping,
    action: Action,
): StringMappingState<string> {

    switch (action.type) {
        case ProductDetailsActionTypes.PRODUCT_DETAILS_TAB_STRUCTURE_SUCCESS:
            const productDetailsTabStructureLoadedAction = action as ProductDetailsTabStructureSuccessAction;
            return {
                [productDetailsTabStructureLoadedAction.payload.productId]: productDetailsTabStructureLoadedAction.payload.structure.id,
            };

        case ActionTypes.SET_OVERLAY_CLOSED:
            return initialState.currentSessionMapping;

        default:
            return state;
    }
}

/**
 * The reducer responsible for the meta state @link{ProductDetailsState}
 * @param state
 * @param action
 * @returns {ProductDetailsMetaState}
 */
export function productDetailsMetaReducer(state: ProductDetailsMetaState = initialState.meta, action: Action): ProductDetailsMetaState {
    switch (action.type) {
        case ProductDetailsActionTypes.PRODUCT_DETAILS_STRUCTURE_REQUEST:
            return {
                ...state,
                loadingStructure: true,
            };

        case ProductDetailsActionTypes.PRODUCT_DETAILS_STRUCTURE_FAILURE:
        case ProductDetailsActionTypes.PRODUCT_DETAILS_STRUCTURE_SUCCESS:
            return {
                ...state,
                loadingStructure: false,
            };

        case ProductDetailsActionTypes.PRODUCT_DETAILS_DATA_REQUEST:
            return {
                ...state,
                loadingData: true,
            };

        case ProductDetailsActionTypes.PRODUCT_DETAILS_DATA_FAILURE:
        case ProductDetailsActionTypes.PRODUCT_DETAILS_DATA_SUCCESS:
            return {
                ...state,
                loadingData: false,
            };

        case ProductAttachmentsActionTypes.DELETE_PRODUCT_ATTACHMENT_REQUEST:
            return {
                ...state,
                productAttachmentDeleted: null,
            };

        case ProductAttachmentsActionTypes.DELETE_PRODUCT_ATTACHMENT_SUCCESS:
            return {
                ...state,
                productAttachmentDeleted: true,
            };

        case ProductDetailsActionTypes.PRODUCT_DETAILS_TAB_STRUCTURE_REQUEST:
            return {
                ...state,
                loadingDetails: true,
                currentTabId: null,
                errorLoadingDetails: null,
            };

        case ProductDetailsActionTypes.PRODUCT_DETAILS_TAB_STRUCTURE_FAILURE:
            const productDetailsTabStructureFailureAction = action as ProductDetailsTabStructureFailureAction;
            return {
                ...state,
                loadingDetails: false,
                errorLoadingDetails: productDetailsTabStructureFailureAction.payload.error,
            };

        case ProductDetailsActionTypes.PRODUCT_DETAILS_TAB_STRUCTURE_SUCCESS:
            const productDetailsTabStructureLoadedAction = action as ProductDetailsTabStructureSuccessAction;
            return {
                ...state,
                title: productDetailsTabStructureLoadedAction.payload.structure.title,
                subTitle: productDetailsTabStructureLoadedAction.payload.structure.subTitle,
                instrumentType: productDetailsTabStructureLoadedAction.payload.structure.instrumentType,
                loadingStructure: false,
                loadingDetails: false,
                tabs: {
                    ...state.tabs,
                    [productDetailsTabStructureLoadedAction.payload.productId]: productDetailsTabStructureLoadedAction.payload.structure.tabs,
                },
            };

        case ProductDetailsActionTypes.UPDATE_SELECTED_TAB:
            const updateSelectedTabIdAction = action as UpdateSelectedTabIdAction;
            return {
                ...state,
                currentTabId: updateSelectedTabIdAction.payload.tabId,
            };

        case ProductDetailsActionTypes.LOAD_FACTSHEET_UNIVERSE_DATA:
            return {
                ...state,
                loadingUniverse: true,
            };

        case ProductDetailsActionTypes.LOAD_FACTSHEET_UNIVERSE_DATA_SUCCEEDED:
        case ProductDetailsActionTypes.LOAD_FACTSHEET_UNIVERSE_DATA_FAILED:
            return {
                ...state,
                loadingUniverse: false,
            };

        case ProductFormActions.LOAD_PRODUCT_FORM_INITIAL_REQUEST: {
            return {
                ...state,
                loadingFormInitial: true,
            };
        }

        case ProductFormActions.LOAD_PRODUCT_FORM_INITIAL_SUCCESS:
        case ProductFormActions.LOAD_PRODUCT_FORM_INITIAL_FAILURE: {
            return {
                ...state,
                loadingFormInitial: false,
            };
        }

        case ProductFormActions.LOAD_PRODUCT_FORM_STRUCTURE_REQUEST: {
            return {
                ...state,
                loadingFormStructure: true,
            };
        }

        case ProductFormActions.LOAD_PRODUCT_FORM_STRUCTURE_SUCCESS: {
            return {
                ...state,
                loadingFormStructure: false,
            };
        }

        case ProductFormActions.LOAD_PRODUCT_FORM_STRUCTURE_FAILURE: {
            const loadProductFormStructureFailureAction = action as LoadProductFormStructureFailureAction;

            return {
                ...state,
                loadingFormStructure: false,
                errorFormStructure: loadProductFormStructureFailureAction.payload.error,
            };
        }

        // ---

        case ProductFormActions.LOAD_PRODUCT_FORM_VALUES_REQUEST: {
            return {
                ...state,
                loadingFormValues: true,
            };
        }

        case ProductFormActions.LOAD_PRODUCT_FORM_VALUES_SUCCESS: {
            return {
                ...state,
                loadingFormValues: false,
            };
        }

        case ProductFormActions.LOAD_PRODUCT_FORM_VALUES_FAILURE: {
            const loadProductFormValuesFailureAction = action as LoadProductFormValuesFailureAction;

            return {
                ...state,
                loadingFormValues: false,
                errorFormValues: loadProductFormValuesFailureAction.payload.error,
            };
        }

        case ProductFormActions.SAVE_PRODUCT_FORM_DATA_REQUEST: {
            return {
                ...state,
                loadingFormSave: true,
                successFormSave: null,
                successFormSaveMessage: null,
                successFormSaveState: null,
            };
        }

        case ProductFormActions.SAVE_PRODUCT_FORM_DATA_FAILURE: {
            const saveProductFormDataFailureAction = action as SaveProductFormDataSuccessAction;

            return {
                ...state,
                loadingFormSave: false,
                successFormSave: false,
                successFormSaveMessage: saveProductFormDataFailureAction.payload.message,
                successFormSaveState: saveProductFormDataFailureAction.payload.state,
            };
        }

        case ProductFormActions.SAVE_PRODUCT_FORM_DATA_SUCCESS: {
            const saveProductFormDataSuccessAction = action as SaveProductFormDataSuccessAction;

            return {
                ...state,
                loadingFormSave: false,
                successFormSave: true,
                successFormSaveMessage: saveProductFormDataSuccessAction.payload.message,
                successFormSaveState: saveProductFormDataSuccessAction.payload.state,
            };
        }

        case ProductFormActions.DELETE_PRODUCT_FORM_DATA_REQUEST: {
            return {
                ...state,
                loadingFormDelete: true,
                successFormDelete: null,
                resultFormDeleteMessage: null,
                resultFormDeleteState: null,
            };
        }

        case ProductFormActions.DELETE_PRODUCT_FORM_DATA_FAILURE: {
            const deleteProductFormDataFailureAction = action as DeleteProductFormDataSuccessAction;

            return {
                ...state,
                loadingFormDelete: false,
                successFormDelete: false,
                resultFormDeleteMessage: deleteProductFormDataFailureAction.payload.message,
                resultFormDeleteState: deleteProductFormDataFailureAction.payload.state,
            };
        }

        case ProductFormActions.DELETE_PRODUCT_FORM_DATA_SUCCESS: {
            const deleteProductFormDataSuccessAction = action as DeleteProductFormDataSuccessAction;

            return {
                ...state,
                loadingFormDelete: false,
                successFormDelete: true,
                resultFormDeleteMessage: deleteProductFormDataSuccessAction.payload.message,
                resultFormDeleteState: deleteProductFormDataSuccessAction.payload.state,
            };
        }

        default:
            return state;
    }
}

export function productDetailsValuesReducer(
    state: EntitiesState<FormValues> = initialState.values,
    action: Action,
): EntitiesState<FormValues> {
    switch (action.type) {
        case ProductDetailsActionTypes.PRODUCT_DETAILS_VALUES_SUCCESS:
            const productDetailsValuesLoadedAction = action as ProductDetailsValuesSuccessAction;
            return {
                [productDetailsValuesLoadedAction.payload.sessionId]: productDetailsValuesLoadedAction.payload.values,
            };
            
        default:
            return state;
    }
}

export function productFormStructuresReducer(
    state: EntitiesState<ProductDetailsFormStructure> = initialState.form.structures,
    action: Action,
): EntitiesState<ProductDetailsFormStructure> {
    switch (action.type) {
        case ProductFormActions.LOAD_PRODUCT_FORM_STRUCTURE_SUCCESS: {
            const loadProductFormStructureSuccessAction = action as LoadProductFormStructureSuccessAction;
            return {
                ...state,
                [loadProductFormStructureSuccessAction.payload.sessionId]: loadProductFormStructureSuccessAction.payload.structure,
            };
        }
    }

    return state;
}

export function productFormValuesReducer(
    state: EntitiesState<FormValues> = initialState.form.values,
    action: Action,
): EntitiesState<FormValues> {
    switch (action.type) {
        case ProductFormActions.LOAD_PRODUCT_FORM_VALUES_SUCCESS: {
            const loadProductFormValuesSuccessAction = action as LoadProductFormValuesSuccessAction;
            return {
                ...state,
                [loadProductFormValuesSuccessAction.payload.sessionId]: loadProductFormValuesSuccessAction.payload.values,
            };
        }
    }

    return state;
}

export function productFormInitialsReducer(
    state: EntitiesState<FormValues> = initialState.form.initial,
    action: Action,
): EntitiesState<FormValues> {
    switch (action.type) {
        case ProductFormActions.LOAD_PRODUCT_FORM_INITIAL_SUCCESS: {
            const loadProductFormInitialSuccessAction = action as LoadProductFormInitialSuccessAction;
            return {
                ...state,
                [loadProductFormInitialSuccessAction.payload.sessionId]: loadProductFormInitialSuccessAction.payload.values,
            };
        }

        case ProductFormActions.UPDATE_PRODUCT_FORM_DATA: {
            const updateProductFormDataAction = action as UpdateProductFormData;

            return {
                ...state,
                [updateProductFormDataAction.payload.sessionId]: {
                    ...(state[updateProductFormDataAction.payload.sessionId] || {}),
                    ...(updateProductFormDataAction.payload.data || {}),
                },
            };
        }
    }

    return state;
}


// export function productDocumentReducer(
//     state: EntitiesState<FormValues> = initialState.values,
//     action: Action,
// ): EntitiesState<FormValues> {
//     switch (action.type) {
//         case ProductDocumentActionTypes.PRODUCT_DOCUMENT_UPLOAD_REQUEST: {
//             const currentAction = action as ProductDocumentUploadRequestAction;
//             return state;
//         }
//         case ProductDocumentActionTypes.PRODUCT_DOCUMENT_UPLOAD_SUCCESS: {
//             console.log(state);
//             const currentAction = action as ProductDocumentUploadSuccessAction;
//             const result = {
//                 ...state,
//                 // [currentAction.payload.sessionId]: productDetailsValuesLoadedAction.payload.values,
//                 //
//                 // return {
//                 //     ...state,
//                 //     [productDetailsValuesLoadedAction.payload.sessionId]: productDetailsValuesLoadedAction.payload.values,
//                 // };
//             };
//             setWith(
//                 result,
//                 currentAction.payload.path,
//                 currentAction.payload.response
//             );
//             return result;
//         }
//         case ProductDocumentActionTypes.PRODUCT_DOCUMENT_UPLOAD_FAILURE: {
//             const currentAction = action as ProductDocumentUploadFailureAction;
//             console.error(currentAction.payload.response);
//         }
//     }
//     return state;
// }

/**
 * The reducer responsible for the @link{ProductDetailsState}
 * @type {ActionReducer<ProductState>}
 */
export const productDetailsReducer = combineReducers({
    structures: productDetailsStructuresReducer,
    data: productQuerySetMappingReducer,
    values: productDetailsValuesReducer,
    universes: productUniversesReducer,
    changelog: productChangelogReducer,
    currentSessionMapping: currentSessionMappingReducer,
    meta: productDetailsMetaReducer,
    form: combineReducers({
        structures: productFormStructuresReducer,
        values: productFormValuesReducer,
        initial: productFormInitialsReducer,
    })
});
