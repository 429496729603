/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 5/4/17.
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthStoreService } from '../services/auth-store.service';
import { ConfigurationService } from '../services/configuration.service';
import { LogglyLoggerService } from '../services/loggly-logger.service';
import { AuthenticatedApi } from './authenticated.api';
import { UserSettings } from './models/me.model';

/**
 * API abstraction layer for the User Settings API
 */
@Injectable({
    providedIn: 'root',
})
export class UserSettingsApi extends AuthenticatedApi {
    constructor(http: HttpClient, authStoreService: AuthStoreService, loggerService: LogglyLoggerService, appConfigService: ConfigurationService) {
        super(appConfigService.configuration.api.derifin.baseUrl, http, authStoreService, loggerService);
    }

    public updateSettings(settings: UserSettings): Observable<UserSettings> {
        return this.post('settings', settings).pipe(
            map((response) => {
                return {
                    smallMode: response.data.smallMode,
                };
            }),
        );
    }
}
