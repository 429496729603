<div class="row entity-widget-mercury__grouping" #groupingContainer>
    <div class="col-12">
        <div class="app-filter-radio-button-group" *ngIf="(data$ | async) as widgetData">
            <ng-container *ngIf="widgetData && widgetData.groupings.length > 1">
                <mat-button-toggle-group [value]="activeGroupingId$ | async" (change)="onActiveGroupingChange($event)">
                    <mat-button-toggle *ngFor="let value of widgetData.groupings" [value]="value.id" disableRipple="true">
                        {{ value.title }}
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </ng-container>
        </div>
    </div>
</div>

<ng-container *ngIf="filteredData$ | async as filteredData; else noDataAvailable">
    <div *ngIf="filteredData.length; else: noDataAvailable" class="entity-widget-mercury__list-container" [style.max-height]="maxScrollHeight + 'px'">
        <ul class="entity-widget-mercury__list">
            <li class="entity-widget-mercury__list__item" *ngFor="let item of filteredData; let i=index">
                <div class="entity-widget-mercury__list__item__icon" *ngIf="!disableAvatars">
                    <app-contents-author-avatar [size]="40" [author]="item.author"></app-contents-author-avatar>
                </div>
                <div class="entity-widget-mercury__list__item__content">
                    <div class="row">
                        <div class="col entity-widget-mercury__list__item__title">
                            <a href="#" (click)="onClickItem($event, item)">{{ item.title || 'Ohne Titel' }}</a>
                        </div>
                        <div class="col-2 entity-widget-mercury__list__item__meta-top" style="position: relative;" *ngIf="item.mustRead">
                            <div style="position: absolute; display: flex; right: 0px; top: 4px">
                                <app-icon icon="material:notifications"></app-icon>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col entity-widget-mercury__list__item__text">{{ item.bodySummary | truncate:[maxLengthBodyText] }} <a href="#" (click)="onClickItem($event, item)">[weiterlesen]</a></div>
                    </div>
                    <div class="row">
                        <div class="col entity-widget-mercury__list__item__meta">
                            <strong *ngIf="item.author">{{ (item.author.firstName + ' ' + item.author.lastName).trim() || item.author.username }}</strong>
                            <span *ngIf="item.commentsCount > 0">{{"\u2022"}} {{ item.commentsCount }} Antworten</span>

                            <ng-container *ngIf="item.lastReply && item.commentsCount; else: showCreateDate">
                                {{"\u2022"}}
                                <span>Letzte Antwort: {{item.lastReply | distanceToNow}}</span>
                            </ng-container>
                            <ng-template #showCreateDate>
                                {{"\u2022"}}
                                <span>Letzte Änderung: {{item.updatedAt | distanceToNow}}</span>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</ng-container>

<ng-template #noDataAvailable>
    <div style="text-align: center; padding: 2rem">
        Keine Daten für die gewählte Gruppierung vorhanden.
    </div>
</ng-template>
