import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, ValidationErrors, FormsModule } from '@angular/forms';
import { map } from 'lodash';
import { FormControlConfig, FormControlSelectOption } from '../../../core/models/form.model';
import { SingleValueFilterControl } from '../filter-control';
import { NgIf, NgFor } from '@angular/common';

/**
 * Single select component that is responsible for a select filter
 */
@Component({
    selector: 'app-filter-single-select',
    templateUrl: './filter-single-select.component.html',
    styleUrls: ['./filter-single-select.component.scss'],
    standalone: true,
    imports: [FormsModule, NgIf, NgFor]
})
export class FilterSingleSelectComponent implements OnInit, SingleValueFilterControl<string>, OnChanges {

    @Input() public config: FormControlConfig;

    @Input() public values: FormControlSelectOption[];

    @Input() public disabled = false;

    public currentValue: string;
    public _lastValidValue: string = null;

    public onChange: () => void;

    public onTouched: () => void;

    constructor(private cdf: ChangeDetectorRef) {
    }

    public ngOnInit() {
    }

    public writeValue(value: string): void {
        if (this.currentValue !== value) {
            this.currentValue = value;
        }
    }

    protected _isValidValue(value: string) {
        if (value === 'ANY') { return true; }

        if (this.values) {
            return this.values.reduce((hasValue, validValue) => {
                return value == validValue.id || hasValue;
            }, false);
        } else {
            return true;
        }

    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                fn(this.currentValue);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Validates the filter control
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        return (this._isValidValue(this.currentValue)) ? null : {
            singleSelectError: 'Invalid value specified',
        };
    }

    /**
     * Called when the selection changed
     */
    public onSingleSelectChange(selectedValue: string): void {
        const value = this.values.find(
            (value) => String(value.id) === selectedValue
        ).id;
        
        if (this.currentValue !== value) {
            this.currentValue = value;
            if (this.onChange) {
                this.onChange();
            }
        }
    }

    public defaultOptionExists(): boolean {
        return map(this.values, 'id').includes(null);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.values) {
            const currentValueStillValid = this.values
                ? this.values.filter((value) => value.id === this.currentValue).length > 0 :
                false;

            if (this.currentValue) {
                this._lastValidValue = this.currentValue;
            }

            this.currentValue = currentValueStillValid
                ? this.currentValue
                : this._lastValidValue;
        }
    }

}
