import { Action } from '@ngrx/store';
import { GetTokenResponse } from '../../api/models/auth.model';
import { UserSettings } from '../../api/models/me.model';
import { AppError } from '../../errors/base.errors';
import { rateLimit } from '../../helpers/functions.helper';
import { type } from '../util';

export const AuthActionTypes = {
    SIGN_IN: type('AUTH:SIGN_IN'),
    SIGNED_IN: type('AUTH:SIGNED_IN'),
    SIGN_IN_FAILED: type('AUTH:SIGN_IN_FAILED'),

    SIGN_IN_REDIRECT_TOKEN: type('AUTH:SIGN_IN_REDIRECT_TOKEN'),
    SIGN_IN_REDIRECT_TOKEN_SUCCESS: type('AUTH:SIGN_IN_REDIRECT_TOKEN_SUCCESS'),
    SIGN_IN_REDIRECT_TOKEN_FAILURE: type('AUTH:SIGN_IN_REDIRECT_TOKEN_FAILURE'),

    SIGN_OUT: type('AUTH:SIGN_OUT'),

    REFRESH_TOKEN: type('AUTH:REFRESH_TOKEN'),
    STORE_INIT_AFTER_REFRESH_TOKEN: type('AUTH:STORE_INIT_AFTER_REFRESH_TOKEN'),
    TOKEN_REFRESHED: type('AUTH:TOKEN_REFRESHED'),

    LOAD_AUTH_USER: type('AUTH:LOAD_AUTH_USER'),
    AUTH_USER_LOADED: type('AUTH:AUTH_USER_LOADED'),
    LOAD_AUTH_USER_FAILED: type('AUTH:LOAD_AUTH_USER_FAILED'),

    VALIDATE_TOKEN: type('AUTH:VALIDATE_TOKEN'),

    UPDATE_USER_SETTINGS: type('AUTH:UPDATE_USER_SETTINGS'),
    UPDATE_USER_SETTINGS_SUCCESS: type('AUTH:UPDATE_USER_SETTINGS_SUCCESS'),
    UPDATE_USER_SETTINGS_FAILURE: type('AUTH:UPDATE_USER_SETTINGS_FAILURE'),

    RESET_REFRESH_TOKEN: type('AUTH:RESET_REFRESH_TOKEN'),
    RESET_ACCESS_TOKEN: type('AUTH:RESET_ACCESS_TOKEN'),
    RESET_TOKENS: type('AUTH:RESET_TOKENS'),
};

export function resetTokens(): Action {
    return {
        type: AuthActionTypes.RESET_TOKENS,
    };
}

export function resetRefreshToken(): Action {
    return {
        type: AuthActionTypes.RESET_REFRESH_TOKEN,
    };
}

export function resetAccessToken(): Action {
    return {
        type: AuthActionTypes.RESET_ACCESS_TOKEN,
    };
}

export interface SignInRedirectTokenAction extends Action {
    payload: {
        redirectToken: string,
        returnUrl: string,
    };
}

export function signInRedirectToken(redirectToken: string, returnUrl?: string): SignInRedirectTokenAction {
    return {
        type: AuthActionTypes.SIGN_IN_REDIRECT_TOKEN,
        payload: {
            redirectToken,
            returnUrl,
        },
    };
}

export interface SignInRedirectTokenSuccessAction extends Action {
    payload: {
        token: GetTokenResponse;
    };
}

export function signInRedirectSuccessAction(token: GetTokenResponse): SignInRedirectTokenSuccessAction {
    return {
        type: AuthActionTypes.SIGN_IN_REDIRECT_TOKEN_SUCCESS,
        payload: {
            token
        },
    };
}

export interface SignInRedirectTokenFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function signInRedirectFailureAction(error: AppError): SignInRedirectTokenFailureAction {
    return {
        type: AuthActionTypes.SIGN_IN_REDIRECT_TOKEN_FAILURE,
        payload: {
            error,
        },
    };
}

/** SignInAction **/

export interface SignInAction extends Action {
    payload: {
        username: string;
        password: string;
        returnUrl: string;
    };
}

export function signIn(username: string, password: string, returnUrl?: string): SignInAction {
    return {
        type: AuthActionTypes.SIGN_IN,
        payload: {
            username,
            password,
            returnUrl,
        },
    };
}

/** SignedInAction **/

export interface SignedInAction extends Action {
    payload?: void;
}

export function signedIn(): SignedInAction {
    return {
        type: AuthActionTypes.SIGNED_IN,
    };
}

/** SignInFailedAction **/

export interface SignInFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function signInFailed(error: AppError): SignInFailedAction {
    return {
        type: AuthActionTypes.SIGN_IN_FAILED,
        payload: {
            error,
        },
    };
}

/** SignOutAction **/

export interface SignOutAction extends Action {
    payload: {
        returnUrl?: string;
        redirectToLogin: boolean
    };
}

export function signOut(redirectToLogin: boolean = false, returnUrl?: string): SignOutAction {
    return {
        type: AuthActionTypes.SIGN_OUT,
        payload: {
            returnUrl,
            redirectToLogin,
        },
    };
}

export interface StoreInitAfterRefreshToken extends Action {
    payload?: void;
}

export function storeInitAfterRefreshToken(): StoreInitAfterRefreshToken {
    return {
        type: AuthActionTypes.STORE_INIT_AFTER_REFRESH_TOKEN,
    };
}

/** RefreshTokenAction **/

export interface RefreshTokenAction extends Action {
    payload: {
        originalAction: Action;
    };

}

// Rate limit the calls to the refreshToken function to 10 times in 300ms to prevent loops when authentication errors
// happen after a login if the current user does not have enough permissions.
// When the rate limitation is broken the user will be logged out automatically.
//
// @TODO(alex): Remove when we get better error codes from the backend to distinguish between permission problems and
//              other authentication errors.
export const refreshToken = rateLimit((originalAction: Action): RefreshTokenAction => {
    return {
        type: AuthActionTypes.REFRESH_TOKEN,
        payload: {
            originalAction,
        },
    };
}, 300, 10, signOut);

/** TokenRefreshedAction **/

export interface TokenRefreshedAction extends Action {
    payload: {
        tokenResponse: GetTokenResponse,
    };
}

export function tokenRefreshed(tokenResponse: GetTokenResponse): TokenRefreshedAction {
    return {
        type: AuthActionTypes.TOKEN_REFRESHED,
        payload: {
            tokenResponse,
        },
    };
}

/** LoadAuthUserAction **/

export interface LoadAuthUserAction extends Action {
    payload?: void;
}

export function loadAuthUser(): LoadAuthUserAction {
    return {
        type: AuthActionTypes.LOAD_AUTH_USER,
    };
}

/** AuthUserLoadedAction **/

export interface AuthUserLoadedAction extends Action {
    payload: {
        authUser: any;
    };
}

export function authUserLoaded(authUser: any): AuthUserLoadedAction {
    return {
        type: AuthActionTypes.AUTH_USER_LOADED,
        payload: {
            authUser,
        },
    };
}

/** LoadAuthUserFailedAction **/

export interface LoadAuthUserFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadAuthUserFailed(error: AppError): LoadAuthUserFailedAction {
    return {
        type: AuthActionTypes.LOAD_AUTH_USER_FAILED,
        payload: {
            error,
        },
    };
}

/** ValidateTokenAction **/

export interface ValidateTokenAction extends Action {
    payload?: void;
}

export function validateToken(): ValidateTokenAction {
    return {
        type: AuthActionTypes.VALIDATE_TOKEN,
    };
}

// UPDATE USER SETTINGS
export interface UpdateUserSettingsAction extends Action {
    payload: {
        userSettings: UserSettings;
    };
}

export function updateUserSettings(userSettings: UserSettings): UpdateUserSettingsAction {
    return {
        type: AuthActionTypes.UPDATE_USER_SETTINGS,
        payload: {
            userSettings,
        },
    };
}

export interface UpdateUserSettingsSuccessAction extends Action {
    payload: {
        userSettings: UserSettings;
    };
}

export function updateUserSettingsSuccess(userSettings: UserSettings): UpdateUserSettingsSuccessAction {
    return {
        type: AuthActionTypes.UPDATE_USER_SETTINGS_SUCCESS,
        payload: {
            userSettings,
        },
    };
}

export interface UpdateUserSettingsFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function updateUserSettingsFailure(error: AppError): UpdateUserSettingsFailureAction {
    return {
        type: AuthActionTypes.UPDATE_USER_SETTINGS_FAILURE,
        payload: {
            error,
        },
    };
}
