<form [formGroup]="form">
    <div class="alert alert-danger" *ngIf="!isValid">Mindestens ein Wert muss ausgewählt sein!</div>

    <table class="table table-hover">
        <thead>
        <tr>
            <th></th>
            <th *ngFor="let p of portfolios">{{p.label}}</th>
            <th>PA</th>
        </tr>
        </thead>
        <tbody formArrayName="items">
        <tr *ngFor="let item of itemsList.controls; let itemIndex=index" [formGroupName]="itemIndex">
            <td style="vertical-align: middle; font-weight: bold">{{getLabelForClientId(item.get('client').value)}}</td>

            <ng-container formArrayName="portfolios">
                <td style="vertical-align: middle"
                    *ngFor="let port of getItemControls(item, 'portfolios'); let pIndex=index" [formGroupName]="pIndex">
                    <app-entity-widget-baskets-admin-button-group 
                        *ngIf="!options.displayPaOnly.includes(item.get('client').value)"
                        formControlName="value"
                        [values]="buttons"
                        [defaultValue]="defaultButton">
                    </app-entity-widget-baskets-admin-button-group>
                </td>
            </ng-container>
            <td style="vertical-align: middle">
                <app-checkbox-input [showSwitch]="true" formControlName="pa"></app-checkbox-input>
            </td>
        </tr>
        </tbody>
    </table>

    <div class="row">
        <div class="col-8">
            <div class="card entity-widget__card entity-widget__card--muted">
                <div class="card-block">
                    <h3 class="card-title">Legende</h3>
                    <div class="card-block__container">
                        <table class="table entity-details-table__table">
                            <tbody>
                            <tr *ngFor="let item of legend">
                                <th class="entity-details-table__line-label">{{item.label}}</th>
                                <td class="entity-details-table-cell entity-details-table-cell--text">{{item.description}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col text-right" *ngIf="options.displayMode !== 'INTEGRATED'">
            <button class="btn btn-primary" type="submit" (click)="onClickFormSave($event)">
                Zuordnung speichern
            </button>
        </div>
    </div>
</form>
