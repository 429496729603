import { createReducer, on } from "@ngrx/store";
import { ShortcutType } from "../../api/models/user.model";
import { getRouterLinkFromMenuItemTargetConfig } from "../../helpers/menu.helper";
import * as UserActions from "../actions/user.actions";
import { UserState } from "../state/user.state";

export const initialState: UserState = {
    shortcuts: {}
};

export const reducer = createReducer(initialState,
    on(UserActions.setShortcuts, (state, { shortcuts }): UserState =>
        ({ ...state,
            shortcuts: Object.assign({}, 
            ...Object.keys(shortcuts).map((type: ShortcutType) => {
                switch(type) {
                    case ShortcutType.Module: {
                        return {
                            [type]: shortcuts[type].map((shortcut) =>
                                ({ ...shortcut,
                                    link: getRouterLinkFromMenuItemTargetConfig({
                                        type: shortcut.moduleType,
                                        id: shortcut.moduleId 
                                    })
                                })
                            )
                        };
                    }
                    case ShortcutType.Watchlist: {
                        return {
                            [type]: shortcuts[type].map((shortcut) =>
                                ({ ...shortcut,
                                    link: [
                                        '/app', {
                                            outlets: {
                                                overlay:
                                                    ['watchlist-details', shortcut.watchlistId]
                                            }
                                        }
                                    ]
                                })
                            )
                        };
                    }
                }
            }))
        })
    )
);
