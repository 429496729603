import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, HostListener } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { truthy } from '../../../shared/helpers/general.helper';
import { getFirstMenuItemLink } from '../../helpers/menu.helper';
import { ConfigurationService } from '../../services/configuration.service';
import { MenuService } from '../../services/menu.service';
import { UiService } from '../../services/ui.service';
import { MenuComponent } from '../menu/menu.component';
import { AuthStoreService } from '../../services/auth-store.service';
import { MenuItemComponent } from '../../../shared/components/menu-item/menu-item.component';
import { NgFor, AsyncPipe } from '@angular/common';
import { IconComponent } from '../../../shared/components/icon/icon.component';
import { RouterLink } from '@angular/router';

/**
 * Component that represents the main menu including animations
 */
@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss'],
    animations: [
        trigger('rowState', [
            state('void', style({
                height: 0,
                bottom: '-4rem',
                opacity: 0,
            })),
            state('default', style({
                height: '*',
                bottom: 0,
                opacity: 1,
            })),
            transition('void => *', animate('250ms ease-out')),
            transition('* => void', animate('250ms ease-out')),
        ]),
    ],
    standalone: true,
    imports: [RouterLink, IconComponent, NgFor, MenuItemComponent, AsyncPipe]
})
export class MainMenuComponent extends MenuComponent {
    @HostBinding('class.menu--inverse') public classMenuInverse = true;

    public logoUrl: string;
    public logoImgUrl$: Observable<string>;
    public logoUrl$: Observable<any[]>;

    constructor(private uiService: UiService, private configurationService: ConfigurationService, private menuService: MenuService, private authStore: AuthStoreService) {
        super();

        this.logoUrl = this.configurationService.configuration.environment.assets.logoInverse;
        this.logoImgUrl$ = this.configurationService.getLogoImageUrl('logoInverse');

        this.logoUrl$ = this.menuService.getMenuConfig().pipe(
            filter(truthy),
            map((menuConfig) => getFirstMenuItemLink(menuConfig)),
        );
    }

    @HostListener('window:keyup', ['$event'])
    public onHotKey(e: KeyboardEvent) {
        if (e.key === 'Escape' || e.key === 'Esc') {
            this.uiService.closeMenu();
        }
    }

    public onCloseClick(e: Event) {
        e.preventDefault();
        this.uiService.closeMenu();

        return false;
    }

    public onItemSelected(itemIndex: number, rowIndex: number) {
        const handled = super.onItemSelected(itemIndex, rowIndex);

        if (!handled) {
            this.uiService.closeMenu();
        }

        return true;
    }

    public onSubitemSelected(subitemIndex: number, itemIndex: number, rowIndex: number): boolean {
        this.uiService.closeMenu();

        return true;
    }
}
