<a
    routerLinkActive
    #rla="routerLinkActive"
    class="menu-item__link"
    [class.menu-item--active]="active || (item?.hasSubmenu && (hasActiveSubmenu$ | async))"
    [routerLink]="item?.link"
    (click)="handleMenuClick($event)">

    <ng-container *ngIf="item?.icon">
        <app-icon class="menu-item__icon" [icon]="item.icon"></app-icon>
    </ng-container>
    <span class="menu-item__label">{{ item?.label }}</span>
</a>
<div
    *ngIf="item?.hasSubmenu && (active || ((hasActiveSubmenu$ | async) && expandActiveSubmenu))"
    class="menu-item__submenu"
    [@submenuState]="'default'">
    <div class="menu-item__submenu-container">
        <app-submenu-item
            *ngFor="let subitem of item.children let subitemIndex = index;"
            [item]="subitem"
            (click)="onSubmenuItemClicked($event, subitemIndex)"></app-submenu-item>
    </div>
</div>
