/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 09/28/2017.
 */

import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { handleAuthenticationError } from '../../../../core/helpers/errors.helper';
import { FormValues } from '../../../../core/models/form.model';
import { TargetMarketApi } from '../../api/target-market.api';
import { TargetMarketErrors } from '../../errors/target-market.errors';
import { TargetMarketCriteriaStructure } from '../../models/target-market-criteria-structure.model';
import { TargetMarketCustomCustomer } from '../../models/target-market-customer.model';
import {
    ActivateTargetMarketCustomCustomerAction,
    ActivateTargetMarketCustomerAction, ActivateTargetMarketReferenceCustomerAction,
    CreateTargetMarketCustomCustomerAction,
    createTargetMarketCustomCustomerFailed,
    createTargetMarketCustomCustomerSucceeded,
    CreateTargetMarketCustomCustomerSucceededAction,
    engageTargetMarketCriteria,
    engageTargetMarketCriteriaFailed,
    loadTargetMarketCriteriaAvailableValues,
    LoadTargetMarketCriteriaAvailableValuesAction,
    loadTargetMarketCriteriaAvailableValuesFailed,
    loadTargetMarketCriteriaAvailableValuesSucceeded,
    LoadTargetMarketCriteriaStructureAction,
    loadTargetMarketCriteriaStructureFailed,
    loadTargetMarketCriteriaStructureSucceeded,
    LoadTargetMarketCriteriaStructureSucceededAction,
    LoadTargetMarketCustomCustomersAction,
    loadTargetMarketCustomCustomersFailed,
    loadTargetMarketCustomCustomersSucceeded,
    LoadTargetMarketCustomerSuggestionsAction,
    loadTargetMarketCustomerSuggestionsFailed,
    loadTargetMarketCustomerSuggestionsSucceeded,
    LoadTargetMarketReferenceCustomersAction,
    loadTargetMarketReferenceCustomersFailed,
    loadTargetMarketReferenceCustomersSucceeded,
    LoadTargetMarketSelectedCustomerAction,
    loadTargetMarketSelectedCustomerFailed,
    loadTargetMarketSelectedCustomerSucceeded,
    RemoveTargetMarketCustomCustomerAction,
    removeTargetMarketCustomCustomerFailed,
    removeTargetMarketCustomCustomerSucceeded,
    RemoveTargetMarketCustomCustomerSucceededAction,
    SaveTargetMarketCustomCustomerAction,
    saveTargetMarketCustomCustomerFailed,
    saveTargetMarketCustomCustomerSucceeded,
    TargetMarketActionTypes,
} from '../actions/target-market.actions';

import { catchError, map, switchMap } from 'rxjs/operators';
import * as RouterActions from '../../../../../app/core/store/actions/router.action';
import * as TargetMarketSelectors from '../selectors/target-market.selectors';

/**
 * Effect class for target market effects
 */
@Injectable()
export class TargetMarketEffects {

    /** Effect Declarations **/

    
    public loadTargetMarketCriteriaStructure$ = createEffect(() => this.actions$.pipe(
        ofType(TargetMarketActionTypes.LOAD_TARGET_MARKET_CRITERIA_STRUCTURE),
        switchMap((action: LoadTargetMarketCriteriaStructureAction) => this.loadTargetMarketCriteriaStructure(action)),
    ));

    
    public loadTargetMarketCriteriaStructureSucceeded$ = createEffect(() => this.actions$.pipe(
        ofType(TargetMarketActionTypes.LOAD_TARGET_MARKET_CRITERIA_STRUCTURE_SUCCEEDED),
        switchMap((action: LoadTargetMarketCriteriaStructureSucceededAction) => this.loadTargetMarketCriteriaStructureSucceeded(action)),
    ));

    
    public loadTargetMarketCriteriaAvailableValues$ = createEffect(() => this.actions$.pipe(
        ofType(TargetMarketActionTypes.LOAD_TARGET_MARKET_CRITERIA_AVAILABLE_VALUES),
        switchMap((action: LoadTargetMarketCriteriaAvailableValuesAction) => this.loadTargetMarketCriteriaAvailableValues(action)),
    ));

    
    public activateTargetMarketCustomer$ = createEffect(() => this.actions$.pipe(
        ofType(TargetMarketActionTypes.ACTIVATE_TARGET_MARKET_CUSTOMER),
        switchMap((action: ActivateTargetMarketCustomerAction) => this.activateTargetMarketCustomer(action)),
    ));

    
    public loadTargetMarketCustomCustomers$ = createEffect(() => this.actions$.pipe(
        ofType(TargetMarketActionTypes.LOAD_TARGET_MARKET_CUSTOM_CUSTOMERS),
        switchMap((action: LoadTargetMarketCustomCustomersAction) => this.loadTargetMarketCustomCustomers(action)),
    ));

    
    public loadTargetMarketReferenceCustomers$ = createEffect(() => this.actions$.pipe(
        ofType(TargetMarketActionTypes.LOAD_TARGET_MARKET_REFERENCE_CUSTOMERS),
        switchMap((action: LoadTargetMarketReferenceCustomersAction) => this.loadTargetMarketReferenceCustomers(action)),
    ));

    
    public activateTargetMarketCustomCustomer$ = createEffect(() => this.actions$.pipe(
        ofType(TargetMarketActionTypes.ACTIVATE_TARGET_MARKET_CUSTOM_CUSTOMER),
        switchMap((action: ActivateTargetMarketCustomCustomerAction) => this.activateTargetMarketCustomCustomer(action)),
    ));

    public reactivateTargetMarketCustomCustomerOnSave$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(TargetMarketActionTypes.SAVE_TARGET_MARKET_CUSTOM_CUSTOMER_SUCCEEDED),
            concatLatestFrom(() =>  this.store.select(TargetMarketSelectors.selectActivatedCustomCustomerId)),
            switchMap(([action, activatedCustomCustomerId]: [ActivateTargetMarketCustomCustomerAction, string]) => {
                if (activatedCustomCustomerId) {
                    return this.activateTargetMarketCustomCustomer(action);
                }
            })
        )
    });
    
    public activateTargetMarketReferenceCustomer$ = createEffect(() => this.actions$.pipe(
        ofType(TargetMarketActionTypes.ACTIVATE_TARGET_MARKET_REFERENCE_CUSTOMER),
        switchMap((action: ActivateTargetMarketReferenceCustomerAction) => this.activateTargetMarketReferenceCustomer(action)),
    ));

    
    public saveTargetMarketCustomCustomer$ = createEffect(() => this.actions$.pipe(
        ofType(TargetMarketActionTypes.SAVE_TARGET_MARKET_CUSTOM_CUSTOMER),
        switchMap((action: SaveTargetMarketCustomCustomerAction) => this.saveTargetMarketCustomCustomer(action)),
    ));

    
    public createTargetMarketCustomCustomer$ = createEffect(() => this.actions$.pipe(
        ofType(TargetMarketActionTypes.CREATE_TARGET_MARKET_CUSTOM_CUSTOMER),
        switchMap((action: CreateTargetMarketCustomCustomerAction) => this.createTargetMarketCustomCustomer(action)),
    ));

    
    public createTargetMarketCustomCustomerSucceeded$ = createEffect(() => this.actions$.pipe(
        ofType(TargetMarketActionTypes.CREATE_TARGET_MARKET_CUSTOM_CUSTOMER_SUCCEEDED),
        switchMap((action: CreateTargetMarketCustomCustomerSucceededAction) => this.createTargetMarketCustomCustomerSucceeded(action)),
    ));

    
    public removeTargetMarketCustomCustomer$ = createEffect(() => this.actions$.pipe(
        ofType(TargetMarketActionTypes.REMOVE_TARGET_MARKET_CUSTOM_CUSTOMER),
        switchMap((action: RemoveTargetMarketCustomCustomerAction) => this.removeTargetMarketCustomCustomer(action)),
    ));

    
    public removeTargetMarketCustomCustomerSucceeded$ = createEffect(() => this.actions$.pipe(
        ofType(TargetMarketActionTypes.REMOVE_TARGET_MARKET_CUSTOM_CUSTOMER_SUCCEEDED),
        switchMap((action: RemoveTargetMarketCustomCustomerSucceededAction) => this.removeTargetMarketCustomCustomerSucceeded(action)),
    ));

    
    public loadTargetMarketCustomCustomerSubset$ = createEffect(() => this.actions$.pipe(
        ofType(TargetMarketActionTypes.LOAD_TARGET_MARKET_CUSTOMER_SUGGESTIONS),
        switchMap((action: LoadTargetMarketCustomerSuggestionsAction) => this.loadTargetMarketCustomCustomerSubset(action)),
    ));

    
    public loadTargetMarketSelectedCustomer$ = createEffect(() => this.actions$.pipe(
        ofType(TargetMarketActionTypes.LOAD_TARGET_MARKET_SELECTED_CUSTOMER),
        switchMap((action: LoadTargetMarketSelectedCustomerAction) => this.loadTargetMarketSelectedCustomer(action)),
    ));

    constructor(
        private actions$: Actions,
        private targetMarketApi: TargetMarketApi,
        private store: Store
    ) { }

    /** Effect Handler **/

    /**
     * Effect handler called upon the LoadTargetMarketCriteriaStructureAction
     *
     * This effect handler calls the corresponding API method to load the structure for the target market criteria
     * @param action
     * @returns {Observable<Action>}
     */
    public loadTargetMarketCriteriaStructure(action: LoadTargetMarketCriteriaStructureAction): Observable<Action> {
        return this.targetMarketApi
            .getTargetMarketCriteriaStructure().pipe(
                map((structure: TargetMarketCriteriaStructure) => loadTargetMarketCriteriaStructureSucceeded(structure)),
                catchError((err) => handleAuthenticationError(err, action)),
                catchError((err: any) => of(loadTargetMarketCriteriaStructureFailed({
                    errorCode: TargetMarketErrors.UNKNOWN,
                    httpError: true,
                    statusCode: err.status,
                }))),
            );
    }

    /**
     * Effect handler called upon the LoadTargetMarketCriteriaStructureAction
     *
     * This effect handler calls the corresponding API method to load the structure for the target market criteria
     * @param action
     * @returns {Observable<Action>}
     */
    public loadTargetMarketCriteriaStructureSucceeded(action: LoadTargetMarketCriteriaStructureSucceededAction): Observable<Action> {
        return of(loadTargetMarketCriteriaAvailableValues());
    }

    /**
     * Effect handler called upon the LoadTargetMarketCriteriaAvailableValuesAction
     *
     * This effect handler calls the corresponding API method to load the available values for the target market
     * criteria
     * @param action
     * @returns {Observable<Action>}
     */
    public loadTargetMarketCriteriaAvailableValues(action: LoadTargetMarketCriteriaAvailableValuesAction): Observable<Action> {
        return this.targetMarketApi
            .getTargetMarketCriteriaAvailableValues().pipe(
                map((values: FormValues) => loadTargetMarketCriteriaAvailableValuesSucceeded(values)),
                catchError((err) => handleAuthenticationError(err, action)),
                catchError((err: any) => of(loadTargetMarketCriteriaAvailableValuesFailed({
                    errorCode: TargetMarketErrors.UNKNOWN,
                    httpError: true,
                    statusCode: err.status,
                }))),
            );
    }

    /**
     * Effect handler called upon the LoadTargetMarketReferenceCustomersAction
     *
     * This effect handler calls the corresponding API method to load the reference target market customer data
     * @param action
     * @returns {Observable<Action>}
     */
    public loadTargetMarketReferenceCustomers(action: LoadTargetMarketReferenceCustomersAction): Observable<Action> {
        return this.targetMarketApi
            .getTargetMarketReferenceCustomers().pipe(
                map((data: any) => loadTargetMarketReferenceCustomersSucceeded(data)),
                catchError((err) => handleAuthenticationError(err, action)),
                catchError((err: any) => of(loadTargetMarketReferenceCustomersFailed({
                    errorCode: TargetMarketErrors.UNKNOWN,
                    httpError: true,
                    statusCode: err.status,
                }))),
            );
    }

    /**
     * Effect handler called upon the LoadTargetMarketCustomCustomersAction
     *
     * This effect handler calls the corresponding API method to load the custom target market customer data
     * @param action
     * @returns {Observable<Action>}
     */
    public loadTargetMarketCustomCustomers(action: LoadTargetMarketCustomCustomersAction): Observable<Action> {
        return this.targetMarketApi
            .getTargetMarketCustomCustomers().pipe(
                map((data: any) => loadTargetMarketCustomCustomersSucceeded(data)),
                catchError((err) => handleAuthenticationError(err, action)),
                catchError((err: any) => of(loadTargetMarketCustomCustomersFailed({
                    errorCode: TargetMarketErrors.UNKNOWN,
                    httpError: true,
                    statusCode: err.status,
                }))),
            );
    }

    /**
     * Effect handler called upon the LoadTargetMarketCustomerDataAction
     *
     * This effect handler calls the corresponding API method to encode the target market criteria of the given customer
     * @param action
     * @returns {Observable<Action>}
     */
    public activateTargetMarketCustomer(action: ActivateTargetMarketCustomerAction): Observable<Action> {
        return this.targetMarketApi
            .encodeTargetMarketCriteria(action.payload.customer.criteria).pipe(
                map((encodedCriteria: string) => engageTargetMarketCriteria(action.payload.customer.criteria, encodedCriteria)),
                catchError((err) => handleAuthenticationError(err, action)),
                catchError((err: any) => of(engageTargetMarketCriteriaFailed({
                    errorCode: TargetMarketErrors.UNKNOWN,
                    httpError: true,
                    statusCode: err.status,
                }))),
            );
    }

    /**
     * Effect handler called upon the LoadTargetMarketCustomCustomerDataAction
     *
     * This effect handler calls the corresponding API method to encode the target market criteria of the given custom
     * customer
     * @param action
     * @returns {Observable<Action>}
     */
    public activateTargetMarketCustomCustomer(action: ActivateTargetMarketCustomCustomerAction): Observable<Action> {
        const customer = action.payload.customer;
        const criteria = {
            ...customer.criteria,
            customData: {
                ...customer.criteria.customData,
                name: customer.details.customerId
            }
        };

        return this.targetMarketApi
            .encodeTargetMarketCriteria(criteria).pipe(
                map((encodedCriteria: string) => engageTargetMarketCriteria(criteria, encodedCriteria)),
                catchError((err) => handleAuthenticationError(err, action)),
                catchError((err: any) => of(engageTargetMarketCriteriaFailed({
                    errorCode: TargetMarketErrors.UNKNOWN,
                    httpError: true,
                    statusCode: err.status,
                }))),
            );
    }

    /**
     * Effect handler called upon the LoadTargetMarketReferenceCustomerDataAction
     *
     * This effect handler calls the corresponding API method to encode the target market criteria of the given reference
     * customer
     * @param action
     * @returns {Observable<Action>}
     */
    public activateTargetMarketReferenceCustomer(action: ActivateTargetMarketReferenceCustomerAction): Observable<Action> {
        return this.targetMarketApi
            .encodeTargetMarketCriteria(action.payload.customer.criteria).pipe(
                map((encodedCriteria: string) => engageTargetMarketCriteria(action.payload.customer.criteria, encodedCriteria)),
                catchError((err) => handleAuthenticationError(err, action)),
                catchError((err: any) => of(engageTargetMarketCriteriaFailed({
                    errorCode: TargetMarketErrors.UNKNOWN,
                    httpError: true,
                    statusCode: err.status,
                }))),
            );
    }

    /**
     * Effect handler called upon the SaveTargetMarketCustomCustomerAction
     *
     * This effect handler calls the corresponding API method to save the target market custom customer
     * @param action
     * @returns {Observable<Action>}
     */
    public saveTargetMarketCustomCustomer(action: SaveTargetMarketCustomCustomerAction): Observable<Action> {
        return this.targetMarketApi
            .updateTargetMarketCustomCustomer(action.payload.customerId, action.payload.data).pipe(
                map((customer: TargetMarketCustomCustomer) => saveTargetMarketCustomCustomerSucceeded(customer)),
                catchError((err) => handleAuthenticationError(err, action)),
                catchError((err: any) => of(saveTargetMarketCustomCustomerFailed({
                    errorCode: TargetMarketErrors.UNKNOWN,
                    httpError: true,
                    statusCode: err.status,
                }))),
            );
    }

    /**
     * Effect handler called upon the CreateTargetMarketCustomCustomerAction
     *
     * This effect handler calls the corresponding API method to create a new target market custom customer
     * @param action
     * @returns {Observable<Action>}
     */
    public createTargetMarketCustomCustomer(action: CreateTargetMarketCustomCustomerAction): Observable<Action> {
        return this.targetMarketApi
            .createTargetMarketCustomCustomer(action.payload.data).pipe(
                map((customer: TargetMarketCustomCustomer) => createTargetMarketCustomCustomerSucceeded(customer)),
                catchError((err) => handleAuthenticationError(err, action)),
                catchError((err: any) => of(createTargetMarketCustomCustomerFailed({
                    errorCode: TargetMarketErrors.UNKNOWN,
                    httpError: true,
                    statusCode: err.status,
                }))),
            );
    }

    /**
     * Effect handler called upon the CreateTargetMarketCustomCustomerSucceededAction
     *
     * This effect handler navigates to the newly created custom customer's page
     * @param action
     * @returns {Observable<Action>}
     */
    public createTargetMarketCustomCustomerSucceeded(action: CreateTargetMarketCustomCustomerSucceededAction): Observable<Action> {
        return of(
            new RouterActions.Go({
                path: [
                    '/app',
                    { outlets: { 'overlay': ['customer-selection', 'own', action.payload.customer.id] } }
                ]
            })
        );
    }

    /**
     * Effect handler called upon the RemoveTargetMarketCustomCustomerAction
     *
     * This effect handler calls the corresponding API method to remove the target market custom customer with he given
     * id
     * @param action
     * @returns {Observable<Action>}
     */
    public removeTargetMarketCustomCustomer(action: RemoveTargetMarketCustomCustomerAction): Observable<Action> {
        return this.targetMarketApi
            .removeTargetMarketCustomCustomer(action.payload.customerId).pipe(
                map(() => removeTargetMarketCustomCustomerSucceeded(action.payload.customerId)),
                catchError((err) => handleAuthenticationError(err, action)),
                catchError((err: any) => of(removeTargetMarketCustomCustomerFailed({
                    errorCode: TargetMarketErrors.UNKNOWN,
                    httpError: true,
                    statusCode: err.status,
                    originalError: err,
                }))),
            );
    }

    /**
     * Effect handler called upon the RemoveTargetMarketCustomCustomerSucceededAction
     *
     * This effect handler navigates back to the general customers page after a customer was removed
     * @param action
     * @returns {Observable<Action>}
     */
    public removeTargetMarketCustomCustomerSucceeded(action: RemoveTargetMarketCustomCustomerSucceededAction): Observable<Action> {
        return of(
            new RouterActions.Go({
                path: ['/app', { outlets: { 'overlay': ['customer-selection', 'own'] } }]
            })
        );
    }

    /**
     * Effect handler called upon the LoadTargetMarketCustomerSuggestionsAction
     *
     * This effect handler loads the custom customer suggestions for the given filter
     * @param {LoadTargetMarketCustomerSuggestionsAction} action
     * @returns {Observable<Action>}
     */
    public loadTargetMarketCustomCustomerSubset(action: LoadTargetMarketCustomerSuggestionsAction): Observable<Action> {
        return this.targetMarketApi
            .getTargetMarketCustomerSuggestions(action.payload.filter).pipe(
                map((data: any) => loadTargetMarketCustomerSuggestionsSucceeded(data)),
                catchError((err) => handleAuthenticationError(err, action)),
                catchError((err: any) => of(loadTargetMarketCustomerSuggestionsFailed({
                    errorCode: TargetMarketErrors.UNKNOWN,
                    httpError: true,
                    statusCode: err.status,
                    originalError: err,
                }))),
            );
    }

    /**
     * Effect handler called upon the LoadTargetMarketSelectedCustomerAction
     *
     * This effect loads the customer criteria for the given criteria id
     * @param {LoadTargetMarketSelectedCustomerAction} action
     * @returns {Observable<Action>}
     */
    public loadTargetMarketSelectedCustomer(action: LoadTargetMarketSelectedCustomerAction): Observable<Action> {
        return this.targetMarketApi
            .getTargetMarketCustomerCriteria(action.payload.criteriaId).pipe(
                map((data: any) => loadTargetMarketSelectedCustomerSucceeded(data)),
                catchError((err) => handleAuthenticationError(err, action)),
                catchError((err: any) => of(loadTargetMarketSelectedCustomerFailed({
                    errorCode: TargetMarketErrors.UNKNOWN,
                    httpError: true,
                    statusCode: err.status,
                    originalError: err,
                }))),
            );
    }
}
