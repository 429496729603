<div class="admin-layout__header-bar__container">
    <div class="container">
        <app-admin-header-bar></app-admin-header-bar>
    </div>
</div>

<div class="container">
    <app-issue-message></app-issue-message>
    <router-outlet name="slide-down"></router-outlet>

    <div class="admin-layout__main-container">
        <app-inline-menu *ngIf="inlineMenuConfig$ | async as inlineMenuConfig" [menu]="inlineMenuConfig"></app-inline-menu>

        <router-outlet></router-outlet>
    </div>
</div>

<app-main-menu [menu]="menu$ | async" [@menuState]="'visible'" *ngIf="menuVisible$ | async"></app-main-menu>
<router-outlet name="overlay"></router-outlet>
<app-scroll-top-button></app-scroll-top-button>
