<div [@slideInOut]="getState(outletRef)">
    <div *ngIf="isActive$ | async" class="slide-down__controls">
        <a href="#" [routerLink]="['/admin', {outlets: {'slide-down': null}}]">
            <app-icon class="derifin-icon" icon="derifin:schliessen"></app-icon>
        </a>
    </div>
</div>

<div
    class="slide-down__content"
    [class.slide-down__content--open]="isActive$ | async"
    [@slideInOut]="getState(outletRef)">

    <router-outlet #outletRef="outlet"></router-outlet>
</div>
