import { ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { MenuComponent } from '../../../core/components/menu/menu.component';
import { MenuItemComponent } from '../menu-item/menu-item.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-inline-menu',
    templateUrl: './inline-menu.component.html',
    styleUrls: ['./inline-menu.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, MenuItemComponent]
})
export class InlineMenuComponent extends MenuComponent implements OnInit {

    @HostBinding('class.menu--inline') public menuInlineClass = true;

    constructor() {
        super();
    }

    public ngOnInit() {
    }

    public updateRows() {
        this.rows = [this._menu.items];
    }
}
