<div class="mat-elevation-z6 mat-dev-toolbar">
    <mat-toolbar color="primary" *ngIf="visible">
        <mat-toolbar-row>
            <button mat-icon-button (click)="onClickClose($event)"><app-icon icon="material:close"></app-icon></button>

            <ng-select class="dev-toolbar-select"
                       [clearable]="false"
                       [(ngModel)]="selectedEnvironment"
                       (change)="onEnvironmentSelect($event)">
                <ng-option *ngFor="let environment of environments" [value]="environment">{{environment}}</ng-option>
            </ng-select>

            <div class="filler-3"></div>

            <ng-select class="dev-toolbar-select"
                       placeholder="Actions"
                       [clearable]="false"
                       [(ngModel)]="selectedAction"
                       (change)="onOpenActionPayload($event)">
                <ng-option *ngFor="let action of actions" [value]="action">{{action}}</ng-option>
            </ng-select>

            <button mat-button (click)="onTokens()" aria-label="Tokens menu">
                <div class="toolbar-icon-button-wrapper">
                    <div class="toolbar-icon-button-icon">
                        <app-icon icon="material:security"></app-icon>
                    </div>

                    <div>Tokens</div>
                </div>
            </button>

            <div class="filler-1"></div>


            <mat-slide-toggle
                [checked]="production"
                (toggleChange)="onChangeProduction(!production)">Use production data</mat-slide-toggle>
        </mat-toolbar-row>
    </mat-toolbar>
</div>
