import { Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { cloneDeep, keyBy, get, groupBy } from 'lodash';
import { ExtendedDynamicChartDataResponse } from '../../../models/dynamic-chart.model';
import { EntityWidgetAdditionalDataChannel, MarketDataOptions } from '../../../models/widgets.model';
import { EntityWidgetContentComponent } from '../entity-widget/entity-widget.component';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { WidgetDataActionTypes } from '../../../../core/store/actions/widgets-data.action';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { DynamicChartNewComponent } from '../../../components/dynamic-chart-new/dynamic-chart-new.component';
import { SpinnerComponent } from '../../../components/spinner/spinner.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-entity-widget-market-data',
    templateUrl: './entity-widget-market-data.component.html',
    styleUrls: ['./entity-widget-market-data.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, SpinnerComponent, DynamicChartNewComponent, MatButtonToggleModule, TranslocoLocaleModule]
})
export class EntityWidgetMarketDataComponent implements OnInit, OnChanges, EntityWidgetContentComponent {
    public static disableTitle = false;

    @HostBinding('class.entity-widget-market-data') public marketDataWidgetClass = true;

    @Input() public entityId: string;
    @Input() public width: number;
    @Input() public options: MarketDataOptions;
    @Input() public data: MarketDataItem[] = [];
    @Input() public additionalData: EntityWidgetAdditionalDataChannel<ExtendedDynamicChartDataResponse>;
    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter();
    public processedData: TransformedMarketData = null;
    public columnizedData: DataRef[][] = [];

    public lastAnimate = 0;
    public selectedInstrument: TransformedMarketInstrument = null;
    public selectedButton: TimeframeButton = {
        text: '-',
        value: {
            range: 3,
            type: 'STATIC',
            unit: 'DAYS'
        }
    };

    public enableChart = true;

    public graphButtons: TimeframeButton[] = null;

    constructor() { }

    public get graphHasData() {
        const values = get(this.additionalData, 'data.chart.seriesList[0].values');

        return values && values.length;
    }

    public ngOnInit() {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        let firstInstrumentWithDQ = null;

        if (changes.data && this.data && this.data.length) {
            if (!this.processedData) {
                const numberOfColumns = this.options.numberOfColumns || 1;
                const indizes: DataRef[] = [];
                let totalNumberOfItems = 0;

                this.processedData = keyBy(cloneDeep(this.data).map((d) => {
                    if (!firstInstrumentWithDQ && d.instruments.length) {
                        firstInstrumentWithDQ = d.instruments.find((instrument) => this.hasFactsheet(instrument));
                    }

                    totalNumberOfItems += d.instruments.length;
                    indizes.push(...d.instruments.map((instrument) => {
                        return {type: 'index', value: d.label + '.instruments.' + instrument.dq, group: d.label};
                    }));

                    return {...d, instruments: keyBy(d.instruments, 'dq')};
                }), 'label') as any;

                const itemsPerColumn = Math.ceil(totalNumberOfItems / numberOfColumns);
                this.columnizedData = [];

                for (let i = 0; i < numberOfColumns; i++) {
                    const items = indizes.slice(itemsPerColumn * i, itemsPerColumn * (i + 1));
                    const groupedItems = groupBy(items, 'group');

                    this.columnizedData.push(Object.keys(groupedItems).map((k) => {
                        return {
                            type: 'table',
                            value: groupedItems[k],
                            label: k,
                        };
                    }));
                }
            } else {
                this.data.forEach((d) => {
                    const prevItem = this.processedData[d.label];

                    d.instruments.forEach((inst) => {
                        const prevInst = prevItem.instruments[inst.dq];
                        prevInst._more = [];
                        prevInst._less = [];

                        Object.keys(inst).forEach((k) => {
                            if (inst[k] > prevInst[k]) {
                                prevInst._more.push(k as MarketDataItemInstrumentKey);
                            } else if (inst[k] < prevInst[k]) {
                                prevInst._less.push(k as MarketDataItemInstrumentKey);
                            }

                            prevInst[k] = inst[k];
                        });
                    });
                });

                this.lastAnimate = this.lastAnimate ? 0 : 1;
            }
        }

        if (changes.options && this.options && this.options.graphButtons) {
            this.graphButtons = this.options.graphButtons;
        }

        if (!this.selectedInstrument && firstInstrumentWithDQ) {
            this.selectedInstrument = firstInstrumentWithDQ;

            if (this.graphButtons && this.graphButtons.length) {
                this.selectedButton = this.graphButtons[0];
            }

            this.loadChartData(this.selectedInstrument.dq, this.selectedButton && this.selectedButton.value);
        }
    }

    public resolveDataRef(ref: DataRef) {
        if (ref.type === 'index') {
            return get(this.processedData, ref.value, null);
        }

        return null;
    }

    public getClassesForInstrumentColumn(instrument: TransformedMarketInstrument | any, key: MarketDataItemInstrumentKey, color: boolean = false) {
        const classes = ['entity-table-cell entity-table-cell--right-aligned'];

        classes.push('entity-widget-market-data__item__' + key);

        if(!isNaN(instrument[key])){
            if (instrument._more && instrument._more.includes(key)) {
                classes.push('md-live-data--rising' + (this.lastAnimate ? '-1' : '-2'));
            } else if (instrument._less && instrument._less.includes(key)) {
                classes.push('md-live-data--falling' + (this.lastAnimate ? '-1' : '-2'));
            }

            if (color) {
                if (instrument[key] >= 0) {
                    classes.push('positive');
                } else {
                    classes.push('negative');
                }
            }
        }

        return classes.join(' ');
    }

    public hasFactsheet(instrument: TransformedMarketInstrument) {
        return instrument && instrument.dq && (instrument.dq.startsWith('QP') || instrument.dq.startsWith('QU'));
    }

    public onClickInstrumentRow(event: MouseEvent, instrument: TransformedMarketInstrument) {
        event.preventDefault();

        if (!this.options.enableGraph) {
            if (instrument.dq && instrument.dq.startsWith('Q')) {
                this.action.next({
                    type: 'factsheet-open',
                    payload: {
                        dq: instrument.dq,
                    },
                });
            }
        } else {
            this.selectedInstrument = instrument;

            this.loadChartData(instrument.dq, this.selectedButton && this.selectedButton.value);
        }
    }

    public loadChartData(dq: string, timeFrame: Timeframe) {
        this.action.emit({
            type: WidgetDataActionTypes.DYNAMIC_CHART_LOAD_GRAPH_DATA,
            payload: {
                requestData: {
                    category: {
                        legend: {
                            show: false,
                        },
                        timeFrame: {
                            ...timeFrame,
                        },
                        type: 'TIME_SERIES',
                    },
                    context: {
                        type: 'FINANCIAL_PRODUCT',
                        entityIds: [
                            dq,
                        ],
                    },
                },
            },
        });
    }

    public onClickOpenFactsheet(event: MouseEvent, value: TransformedMarketInstrument) {
        event.preventDefault();

        this.action.next({
            type: 'factsheet-open',
            payload: {
                dq: value.dq,
            },
        });
    }

    public onClickFilterButton(btn: MatButtonToggleChange) {
        this.selectedButton = btn.value;
        console.log('btn', btn);
        this.loadChartData(this.selectedInstrument.dq, this.selectedButton.value);
    }
}

export interface MarketDataItem {
    label: string;
    instruments: MarketDataItemInstrument[];
}

export interface MarketDataItemInstrument {
    dq: string;
    label: string;
    quote: number;
    prevDayPerformance: number;
}

export interface TransformedMarketData {
    [key: string]: TransformedMarketDataItem;
}

type MarketDataItemInstrumentKey = keyof MarketDataItemInstrument;
type MarketDataItemInstrumentKeys = MarketDataItemInstrumentKey[];
type TransformedMarketInstrument = MarketDataItemInstrument & {
    _more?: MarketDataItemInstrumentKeys; _less?: MarketDataItemInstrumentKeys;
};

export interface TransformedMarketDataItem {
    label: string;
    instruments: { [key: string]: TransformedMarketInstrument };
}

export interface Timeframe {
    unit: string;
    range: number;
    type: string;
}

export interface TimeframeButton {
    text: string;
    value: Timeframe;
}

export interface DataRef {
    type: string;
    value: any;
    label?: string;
    group?: string;
}
