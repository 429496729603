/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 07/11/2017.
 */

import { Action } from '@ngrx/store';
import {
    AddProductsToSelectionAction,
    AddProductToSelectionAction,
    LoadTabDataSucceededAction,
    LoadTabsStructureSucceededAction,
    LoadTabStructureSucceededAction,
    ProductSelectionActionTypes,
    RemoveProductFromSelectionAction,
    RemoveProductsFromSelectionAction,
    SetCurrentTabIdAction,
} from '../actions/product-selection.actions';
import { ProductSelectionState } from '../state/product-selection.state';

export const initialState: ProductSelectionState = {
        tabs: null,
        tabsStructure: {},
        tabsData: {},
        currentSelection: [],
        currentSelectedTabId: null,
    }
;

/**
 * The reducer responsible for the tabs structure and tab structure and data @link{ProductSelectionState}
 * @param state
 * @param action
 * @returns {string[]}
 */
export function reducer(state: ProductSelectionState = initialState, action: Action): ProductSelectionState {

    switch (action.type) {

        case ProductSelectionActionTypes.LOAD_TABS_STRUCTURE_SUCCEEDED: {
            const loadTabsStructureSucceededAction = action as LoadTabsStructureSucceededAction;
            return {
                ...state,
                tabs: loadTabsStructureSucceededAction.payload.structure.tabs,
                currentSelectedTabId: (state.tabs && state.tabs.length > loadTabsStructureSucceededAction.payload.structure.tabs.length) ?
                    loadTabsStructureSucceededAction.payload.structure.tabs[0].id : state.currentSelectedTabId,
            };
        }

        case ProductSelectionActionTypes.LOAD_TAB_STRUCTURE_SUCCEEDED: {
            const loadTabStructureSucceededAction = action as LoadTabStructureSucceededAction;
            return {
                ...state,
                tabsStructure: {
                    ...state.tabsStructure,
                    [loadTabStructureSucceededAction.payload.tabId]: loadTabStructureSucceededAction.payload.structure,
                },
            };
        }

        case ProductSelectionActionTypes.LOAD_TAB_DATA_SUCCEEDED: {
            const loadTabDataSucceededAction = action as LoadTabDataSucceededAction;
            return {
                ...state,
                tabsData: {
                    ...state.tabsData,
                    [loadTabDataSucceededAction.payload.tabId]: loadTabDataSucceededAction.payload.values,
                },
            };
        }

        case ProductSelectionActionTypes.SET_CURRENT_TAB_ID: {
            const setCurrentTabIdAction = action as SetCurrentTabIdAction;
            return {
                ...state,
                currentSelectedTabId: setCurrentTabIdAction.payload.tabId,
            };
        }

        case ProductSelectionActionTypes.ADD_PRODUCT_TO_SELECTION: {
            const addProductToSelectionAction = action as AddProductToSelectionAction;
            const isInSelection = state.currentSelection.indexOf(addProductToSelectionAction.payload.productId) > -1;
            return (isInSelection) ? state : {
                ...state,
                currentSelection: [...state.currentSelection, addProductToSelectionAction.payload.productId],
            };
        }

        case ProductSelectionActionTypes.ADD_PRODUCTS_TO_SELECTION: {
            const addProductsToSelectionAction = action as AddProductsToSelectionAction;
            let changed = false;
            const newState = [...state.currentSelection];
            for (const productId of addProductsToSelectionAction.payload.productIds) {
                const isInSelection = state.currentSelection.indexOf(productId) > -1;
                if (!isInSelection) {
                    changed = true;
                    newState.push(productId);
                }
            }
            return (changed) ? {
                ...state,
                currentSelection: newState,
            } : state;
        }

        case ProductSelectionActionTypes.REMOVE_PRODUCT_FROM_SELECTION: {
            const removeProductFromSelectionAction = action as RemoveProductFromSelectionAction;
            const index = state.currentSelection.indexOf(removeProductFromSelectionAction.payload.productId);
            return (index > -1) ? {
                ...state,
                currentSelection: [...state.currentSelection.slice(0, index), ...state.currentSelection.slice(index + 1)],
            } : state;
        }

        case ProductSelectionActionTypes.REMOVE_PRODUCTS_FROM_SELECTION: {
            const removeProductsFromSelectionAction = action as RemoveProductsFromSelectionAction;
            let newState = state.currentSelection;
            for (const productId of removeProductsFromSelectionAction.payload.productIds) {
                const index = newState.indexOf(productId);
                if (index > -1) {
                    newState = [...newState.slice(0, index), ...newState.slice(index + 1)];
                }
            }
            return {
                ...state,
                currentSelection: newState,
            };
        }

        case ProductSelectionActionTypes.CLEAR_SELECTION:
            return {
                ...state,
                currentSelection: [],
            };

        default:
            return state;
    }
}
