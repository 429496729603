import { Component, EventEmitter, HostBinding, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { get, memoize, toPath } from 'lodash';
import { Entity, EntityTableColumn, EntityTableConfig } from '../../models/entities.model';
import { IconComponent } from '../icon/icon.component';
import { EntityTableCellComponent } from '../entity-table-cell/entity-table-cell.component';
import { EntityTableComponentCellComponent } from '../entity-table-component-cell/entity-table-component-cell.component';
import { EntityTableCellStyleDecoratorDirective } from '../../directives/entity-table-cell-style-decorator.directive';
import { NgIf, NgFor } from '@angular/common';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[appEntityTableRow]',
    templateUrl: './entity-table-row.component.html',
    styleUrls: ['./entity-table-row.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, EntityTableCellStyleDecoratorDirective, EntityTableComponentCellComponent, EntityTableCellComponent, IconComponent]
})
export class EntityTableRowComponent implements OnInit, OnChanges {

    @HostBinding('class.colored-flash') get coloredFlash() {
        return this.config.coloredRowFlashWhen ? !!this.entity[this.config.coloredRowFlashWhen] : false;
    }

    @HostBinding('class.selected') get rowSelected() {
        return this.selected;
    }

    @HostBinding('class.multi-row') get isMultiRow() {
        return this.multiIndex > 0;
    }

    @HostBinding('class.vmiddle') get rowVerticalMiddleAlign() {
        return this.verticalMiddleAlign;
    }

    constructor() {
    }

    get multiRowCount() {
        return this.config.pathToMultiRowCount ? get(this.entity, this.config.pathToMultiRowCount) : 1;
    }

    @Input() public config: EntityTableConfig;

    @Input() public entity: Entity;

    @Input() public selected: boolean;

    @Input() public multiIndex = 0;

    @Output() public toggleSelection: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() public remove: EventEmitter<Entity> = new EventEmitter<Entity>();

    @Output() public componentEvent: EventEmitter<any> = new EventEmitter();

    public verticalMiddleAlign = false;

    @HostListener('click', ['$event'])
    public onRowClick(event) {
        if (!this.config.selectable) {
            return;
        }

        const nodeName = event.target.nodeName.toLowerCase();

        if (nodeName !== 'a' && nodeName !== 'button') { // (nodeName === 'td' || nodeName === 'tr') {
            this.onSelectionChange(event);
        }
    }

    public ngOnInit() {
    }

    public onSelectionChange(e: Event) {
        this.toggleSelection.next(!this.selected);
    }

    public onRemoveClicked(e: Event) {
        e.preventDefault();
        this.remove.next(this.entity);
    }

    public onComponentEvent(event: any) {
        this.componentEvent.next(event);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.config && this.config && this.config.columns) {
            this.verticalMiddleAlign = this.config.columns.reduce((acc, current) => {
                if (current.decorator === 'composite' && (!current.decoratorOptions || !current.decoratorOptions.horizontal)) {
                    return false;
                }

                return acc;
            }, true);
        }
    }

    public isArrayValuePath(path: string) {
        if (!path) {
            return false;
        }

        return this.config.pathToMultiRowCount && path.includes('[]');
    }

    public getArrayValuePath(path: string) {
        if (!path) {
            return path;
        }
        return path.replace('[]', '[' + this.multiIndex + ']');
    }

    public isArrayValuePathInComposite(column: EntityTableColumn) {
        let result = false;

        if (column.decoratorOptions && column.decoratorOptions.lines) {
            column.decoratorOptions.lines.forEach((line) => {
                if (line.path && this.isArrayValuePath(line.path)) {
                    result = true;
                    return false;
                }
            });
        }

        return result;
    }
}
