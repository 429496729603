import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { clearTokensLocalStorage } from './app/core/helpers/local-storage.helper';

import { booleanToString, getParamValueQueryString, stringToBoolean } from './app/shared/helpers/general.helper';
import { createConfiguration } from './app/shared/models/configuration';
import { ConfigurationWindow } from './declarations';
import { environment } from './environments/environment';

let env;
let production = false;

/*
 * DERICON-3583: Handle edge case when calling a direct link from an email.
 *               Outlook/etc. seem to transform '//' to '/' which breaks our overlay outlet routing.
 */
const overlayIndex = window.location.href.indexOf('/overlay:');
if (overlayIndex !== -1) {
    const charPrev = window.location.href.charAt(overlayIndex - 1);

    if (charPrev !== '/') {
        window.location.replace(window.location.href.replace('/overlay:', '//overlay:'));
    }
}

const redirectToken = getParamValueQueryString('redirectToken');

if (redirectToken) {
    clearTokensLocalStorage();
}

if (environment.production) {
    enableProdMode();

    const domain = window.location.host;
    const validDomain = domain.match(/.*(derifin|dericon)\.(de|it)$/i);

    env = validDomain ? domain : 'default';
} else {
    const fakeEnvironment = getParamValueQueryString('fakeEnvironment');
    const productionEnvironment = getParamValueQueryString('productionEnvironment') || sessionStorage.getItem('productionEnvironment');
    production = productionEnvironment ? stringToBoolean(productionEnvironment) : false;

    if (fakeEnvironment) {
        env = fakeEnvironment;
    } else {
        const sessionEnv = sessionStorage.getItem('fakeEnvironment');
        env = sessionEnv ? sessionEnv : 'default';
    }

    sessionStorage.setItem('fakeEnvironment', env);
    sessionStorage.setItem('productionEnvironment', booleanToString(production));
}

const disableTranslations = getParamValueQueryString('disableTranslations') || sessionStorage.getItem('disableTranslations');
if (stringToBoolean(disableTranslations)) {
    (window as unknown as ConfigurationWindow).disableTranslations = true;

    sessionStorage.setItem('disableTranslations', 'true');
} else {
    (window as unknown as ConfigurationWindow).disableTranslations = false;
    sessionStorage.removeItem('disableTranslations');
}

fetch(`assets/config/${env}.json`)
.then((response) => response.json())
.then((json) => {
    const defaultEnv = createConfiguration({});

    (window as unknown as ConfigurationWindow).configuration = (production || environment.production) ? json : {
        ...json,
        api: {
            ...defaultEnv.api,
        },
        environment: {
            ...json.environment,
            id: defaultEnv.environment.id,
        },
    };

    return platformBrowserDynamic().bootstrapModule(AppModule);
})
.catch((err) => console.error(err));
