import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Appearance, ButtonBase } from './button-base';

const appearanceClases: { [key in Appearance]: string } = {
    fill: 'text-white bg-primary-base hover:bg-primary-hover-dark focus-visible:outline-offset-2 focus-visible:outline-primary-focus disabled:bg-primary-base',
    outline: 'text-primary-base ring-inset ring-1 ring-primary-base hover:bg-primary-hover-light focus-visible:ring-primary-focus focus-visible:outline-primary-focus disabled:bg-transparent',
    text: 'text-primary-base hover:bg-primary-hover-light focus-visible:outline-primary-focus disabled:bg-transparent'
};

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[chroma-button], a[chroma-button]',
    templateUrl: './button.html',
    standalone: true,
    imports: [CommonModule]
})
export class ButtonComponent extends ButtonBase {

    constructor() {
        super(appearanceClases);
    }

    @Input() set appearance(value: Appearance) {
        this._appearance = value;
        this.hostClasses = this.buildClasses(this._appearance, this._size);
    }
}
