<div class="absolute">
    <div class="container">
        <nav class="navbar navbar-toggleable-md header-bar navbar-inverse">
            <a class="navbar-brand mr-auto" href="">
                <img class="header-bar__logo" [src]="logoUrl"/>
            </a>
            <div class="navbar-nav">
                <a class="nav-link header-bar__login-button" routerLink="/auth/login">
                    <span class="header-bar__login-button-label">LogIn</span>
                    <app-icon class="header-bar__login-button-icon" icon="derifin:log-in"></app-icon>
                </a>
            </div>
        </nav>

    </div>

</div>

<router-outlet></router-outlet>
