<div class="wms-dialog-inner view-tokens-dialog">
    <nav class="navbar navbar-toggleable-md header-bar navbar-inverse header-bar modal__navbar">
        <div class="container">
            <i class="mr-auto"></i>
            <div class="navbar-nav">
                <a (click)="onCloseClick($event)" class="nav-link icon-link close-modal-button" href="#">
                    <app-icon class="derifin-icon" icon="derifin:schliessen"></app-icon>
                </a>
            </div>
        </div>
    </nav>

    <div class="modal-body" style="width: 960px;">
        <div style="display: flex; flex-direction: column; align-items: stretch; gap: 0.5rem">
            <label>Access Token</label>
            <input readonly [value]="accessToken"/>

            <label>Refresh Token</label>
            <input readonly [value]="refreshToken"/>

            <label>Access Token Decoded</label>
            <textarea readonly rows="20" [value]="accessTokenDecoded"></textarea>
        </div>

        <div style="padding-top: 2rem; display: flex; gap: 1rem">
            <button (click)="onResetAccessToken()">Reset Access Token</button>
            <button (click)="onResetRefreshToken()">Reset Refresh Token</button>
        </div>
    </div>
</div>