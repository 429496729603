import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet, RouterLink } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { IconComponent } from '../../../shared/components/icon/icon.component';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-slide-down',
    templateUrl: './slide-down.component.html',
    styleUrls: ['./slide-down.component.scss'],
    animations: [
        trigger('slideInOut', [
            transition('* <=> *', [
                group([
                    query(':leave', [
                        style({
                            height: '*',
                        }),
                        animate('300ms ease-out', style({
                            height: '0',
                        })),
                    ], { optional: true }),
                    query(':enter', [
                        style({
                            height: '0',
                        }),
                        animate('300ms ease-in', style({
                            height: '*',
                        })),
                    ], { optional: true }),
                ]),
            ]),
        ]),
    ],
    standalone: true,
    imports: [NgIf, RouterLink, IconComponent, RouterOutlet, AsyncPipe]
})
export class SlideDownComponent {
    @HostBinding('class.slide-down') public componentClass = true;

    public isActive$: Observable<boolean>;

    constructor(private router: Router, private route: ActivatedRoute) {
        this.isActive$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            startWith(null as NavigationEnd),
            map(() => !!this.route.firstChild),
        );
    }

    public getState(outletRef: RouterOutlet) {
        if (outletRef.isActivated) {
            return {
                value: outletRef.activatedRoute.snapshot.component,
            };
        } else {
            return {
                value: null,
            };
        }
    }
}
