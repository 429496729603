<div class="form-group">
    <select
        class="custom-select"
        [ngModel]="currentValue"
        (ngModelChange)="onSingleSelectChange($event)"
        [disabled]="disabled">
        <option *ngIf="!defaultOptionExists()" [value]="null" disabled style="display: none;">Keine Auswahl</option>

        <ng-container *ngFor="let value of values">
            <ng-container *ngIf="value.isSeparator; then isSeparator; else isNotSeparator"></ng-container>
            <ng-template #isSeparator>
                <option *ngIf="value.label" disabled>{{value.label}}</option>
                <option *ngIf="!value.label" disabled style="letter-spacing: 3px;">- - - - - - - - - - - - - - - -</option>
            </ng-template>

            <ng-template #isNotSeparator>
                <option [value]="value.id" [selected]="value.id === currentValue">{{value.label}}</option>
            </ng-template>
        </ng-container>
    </select>
</div>
