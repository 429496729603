<div class="wms-dialog-inner add-to-watchlist-dialog">
    <nav class="navbar navbar-toggleable-md header-bar navbar-inverse header-bar modal__navbar">
        <div class="container">
            <i class="mr-auto"></i>
            <div class="navbar-nav">
                <a (click)="onCloseClick($event)" class="nav-link icon-link" href="#">
                    <app-icon class="derifin-icon" icon="derifin:schliessen"></app-icon>
                </a>
            </div>
        </div>
    </nav>

    <div class="modal-body">

        <ng-container *ngIf="productsAdded; else addProdcutsToWatchlistFormTemplate">
            <h1 class="modal-form__title">{{ 'watchlists.modals.addToWatchlist.successful.title' | transloco }}</h1>

            <a class="modal-actions__action" href="#" (click)="linkToWatchlist($event)">
                {{ 'watchlists.modals.addToWatchlist.successful.actions.linkToWatchlist' | transloco }}
            </a>
            <a class="modal-actions__action" href="#" (click)="onCloseClick($event)">
                {{ 'watchlists.modals.addToWatchlist.successful.actions.close' | transloco }}
            </a>
        </ng-container>

        <ng-template #addProdcutsToWatchlistFormTemplate>
            <h1 class="modal-form__title">{{ 'watchlists.modals.addToWatchlist.initial.title' | transloco }}</h1>

            <form class="form-inline form-inverse modal-form__form" [formGroup]="selectWatchlistForm" novalidate
                (submit)="onSubmit($event)">
                <select class="custom-select mr-sm-2 modal-form__field--large watchlist-select"
                        formControlName="name"
                        name="type">
                    <option value="" disabled selected>{{ 'watchlists.modals.addToWatchlist.initial.placeholders.select' | transloco }}
                    </option>
                    <option *ngFor="let watchlist of (watchLists$ | async)" value="{{watchlist.id}}">{{watchlist.name}}
                    </option>
                </select>

                <button type="submit" class="icon-button icon-button--inverse save-button"
                        [disabled]="!selectWatchlistForm.valid">
                    <app-icon class="icon-button__icon" icon="derifin:checkmark-plain"></app-icon>
                </button>
            </form>
        </ng-template>

    </div>
</div>