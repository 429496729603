import { Component, HostBinding } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';
import { IconComponent } from '../../shared/components/icon/icon.component';
import { RouterLink, RouterOutlet } from '@angular/router';

/**
 * Component that holds the landing page layout
 */
@Component({
    selector: 'app-landing-layout',
    templateUrl: 'landing-layout.component.html',
    styleUrls: ['./landing-layout.component.scss'],
    standalone: true,
    imports: [RouterLink, IconComponent, RouterOutlet]
})
export class LandingLayoutComponent {
    @HostBinding('class.layout') public layoutClass = true;
    @HostBinding('class.landing-layout') public landingLayoutClass = true;

    public logoUrl: string;

    constructor(appConfigService: ConfigurationService) {
        this.logoUrl = appConfigService.configuration.environment.assets.logoInverse;
    }
}
