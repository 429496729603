<ng-template #tableHeader let-collapse>
    <tr [ngStyle]="{ visibility: collapse ? 'collapse' : '' }">
        <th
            *ngIf="config?.selectable"
            [ngStyle]="{
                width: selectableColumnWidth
            }">
            <ng-container *ngIf="config.selectionLabel; else noSectionLabel">
                {{config.selectionLabel}}
            </ng-container>
            <ng-template #noSectionLabel>
                <label class="custom-control" [class.custom-checkbox]="!config.switches"
                    [class.custom-switch]="config.switches">
                    <input type="checkbox" class="custom-control-input" (change)="onToggleSelectionAll()"
                        [indeterminate]="selectionState === entityTableSelectionState.SOME"
                        [checked]="selectionState === entityTableSelectionState.ALL">
                    <span class="custom-control-indicator"></span>
                </label>
            </ng-template>
        </th>


        <th
            *ngFor="let column of config?.columns"
            [class]="getColumnHeaderClasses(column)"
            [ngStyle]="{
                width: (column.weight / columnWeightSum) === 0 ? 'auto' : (100 * column.weight / columnWeightSum) + '%'
            }">

            <a
                *ngIf="column.sortPath; else notSortableLabel"
                class="entity-table-header__label"
                [innerHTML]="column.label | columnLabel"
                href="#"
                (click)="onSortClicked($event, column)"></a>

            <ng-template #notSortableLabel>
                <div class="entity-table-header__label" [innerHTML]="column.label | columnLabel"></div>
            </ng-template>

            <div class="entity-table-header__meta">
                <a
                    *ngIf="column.sortPath"
                    class="entity-table-header__sort"
                    [class.entity-table-header__sort--asc]="sort && sort.path === column.sortPath && sort.order === 'ASC'"
                    [class.entity-table-header__sort--desc]="sort && sort.path === column.sortPath && sort.order === 'DESC'"
                    href="#"
                    (click)="onSortClicked($event, column)"></a>

                <span
                    *ngIf="column.info"
                    class="entity-table-header__info"
                    placement="bottom"
                    [matTooltip]="column.info"></span>
            </div>
        </th>
        <th *ngIf="config?.removable"
            [ngStyle]="{
                width: 'auto'
            }">

        </th>
    </tr>
</ng-template>

<ng-template #regularHeader>
    <thead>
    <ng-container *ngTemplateOutlet="tableHeader"></ng-container>
    </thead>
</ng-template>

<div appSyncColumnsWidths>
    <div 
        *ngIf="stickyHeader"
        appSticky
        [stickyOffset]="stickyOffset"
        [contained]="true"
        (headerHeight)="tableHeaderInfo.next({ height: $event, heading: config?.heading })">
        <div #scrollOne class="table-responsive" style="overflow: hidden">
            <table
                class="table entity-table"
                [class.entity-table--highlightable]="highlightable"
                [class.entity-table--fixed]="config?.layoutMode === 'fixed'"
                [class.entity-table__top-spacing]="noActions"
                [class.entity-table--selectable]="config?.selectable">

                <thead *ngIf="stickyHeader; else regularHeader">
                    <ng-container *ngTemplateOutlet="tableHeader"></ng-container>
                </thead>
            </table>    
        </div>
    </div>

    <div #scrollTwo (scroll)="synchronizeScroll(scrollTwo)" class="table-responsive" style="overflow-y: hidden">
        <table
            class="table entity-table"
            [class.entity-table--highlightable]="highlightable"
            [class.entity-table--fixed]="config?.layoutMode === 'fixed'"
            [class.entity-table__top-spacing]="noActions"
            [class.entity-table--selectable]="config?.selectable">

            <thead *ngIf="stickyHeader; else regularHeader">
                <ng-container *ngTemplateOutlet="tableHeader; context:{ $implicit: 'collapse' }"></ng-container>
            </thead>

            <tbody *ngIf="(entities && entities.length > 0 && !isLoading); else noEntities">

            <ng-container *ngFor="let entity of entities; trackBy: trackByFunction;">
                <tr
                    [id]="getEntityId(entity)"
                    [multiIndex]="multiIndex"
                    class="app-entity-table-row"
                    [class.app-entity-table-row-hover]="!hoverMultiRow"
                    [class.entity-table-row-flash-hightlight]="highlightFlash === getEntityId(entity) && highlightFlashState === 'flash'"
                    [class.entity-table-row-highlighted]="highlight && (highlight === getEntityId(entity))"
                    appEntityTableRow
                    [config]="config"
                    [entity]="entity"
                    [selected]="selectedEntitiesMap[getEntityId(entity)]"
                    (componentEvent)="onComponentEvent(getEntityId(entity), $event)"
                    (toggleSelection)="onToggleSelection({entityId: getEntityId(entity), selected: $event})"
                    (remove)="onRemove($event)"
                    (click)="onClickedRow($event, entity)"
                    (mouseenter)="hoverMultiRow[idx] = true"
                    (mouseleave)="hoverMultiRow[idx] = false"
                    *ngFor="let multiIndex of getMultiRowIndexList(entity); let idx = index"></tr>
            </ng-container>

            <ng-container *ngIf="isLoadingMoreEntities">
                <tr *ngFor="let i of [0, 1, 2, 3, 4]">
                    <td [colSpan]="config.columns?.length + ((config.selectable) ? 1 : 0) + ((config.removable) ? 1 : 0)">
                        <content-loader
                            [style]="{ width: '100%', height: '32px', display: 'block' }"
                            viewBox="0 0 2000 32">
                            <svg:rect x="0" y="0" rx="3" ry="3" width="2000" height="32"/>
                        </content-loader>
                    </td>
                </tr>
            </ng-container>
            </tbody>

            <ng-template #noEntities>
                <tbody *ngIf="(entities !== undefined && !isLoading); else loadingEntities">
                <tr>
                    <td
                        class="entity-table__empty-message"
                        [colSpan]="config.columns?.length + ((config.selectable) ? 1 : 0) + ((config.removable) ? 1 : 0)">

                        {{emptyMessage}}
                    </td>
                </tr>
                </tbody>
                <ng-template #loadingEntities>
                    <tbody>

                    <tr *ngFor="let i of [0, 1, 2, 3, 4]">
                        <td [colSpan]="config?.columns?.length + ((config?.selectable) ? 1 : 0) + ((config?.removable) ? 1 : 0)">
                            <content-loader
                                [ngStyle]="{ width: '100%', height: '32px', display: 'block' }"
                                viewBox="0 0 2000 32">
                                <svg:rect x="0" y="0" rx="3" ry="3" width="2000" height="32"/>
                            </content-loader>
                        </td>
                    </tr>
                    </tbody>
                </ng-template>
            </ng-template>
        </table>
    </div>
</div>
