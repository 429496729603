<div
    class="card entity-widget__card"
    [ngClass]="widgetCardStyles"
    [class.entity-widget__card--drag-over]="hasDragOver && hasFileHandler">

    <div class="card-block">
        <h3 *ngIf="title && !disableTitle && type !== 'combined'" class="card-title">{{title}}</h3>
        <h5 *ngIf="subTitle && !disableTitle && type !== 'combined'" class="card-subtitle">{{subTitle}}</h5>

        <div class="card-block__container" [hidden]="isLoadingData">
            <ng-template #widgetContent></ng-template>
        </div>

        <div *ngIf="isLoadingData" [ngStyle]="{ display: 'flex', flex: 1, 'align-items': 'center', 'justify-content': 'center' }">
            <app-spinner type="small-boxes"></app-spinner>
        </div>
        <div *ngIf="infoText" class="widget-info-text">{{infoText}}</div>
    </div>
</div>
