<td *ngIf="config.selectable && multiIndex === 0" [attr.rowspan]="multiRowCount">
    <div class="custom-control entity-checkbox" [class.custom-checkbox]="!config.switches"
           [class.custom-switch]="config.switches">
        <input type="checkbox" class="custom-control-input" [checked]="selected" (change)="onSelectionChange($event)">
        <span class="custom-control-indicator"></span>
    </div>
</td>

<ng-container *ngFor="let column of config.columns">
    <ng-container *ngIf="column.decorator === 'composite'; else singleDecorator">
        <td appEntityTableCellStyleDecorator
            [pathToStyleDecorator]="column.pathToStyleDecorator"
            [styleDecorator]="column.styleDecorator"
            [styleDecoratorOptions]="column.styleDecoratorOptions"
            [entity]="entity"
            class="entity-table-cell entity-table-cell--composite"
            [class.entity-table-cell--composite-horizontal]="column.decoratorOptions?.horizontal"
            *ngIf="isArrayValuePathInComposite(column) || multiIndex === 0"
            [attr.rowspan]="!isArrayValuePathInComposite(column) && multiIndex === 0 ? multiRowCount : 1"
        >
            <ng-container *ngFor="let line of column.decoratorOptions?.lines; let last = last;">
                <div *ngIf="line.decorator === 'component'; else regularCell" appEntityTableComponentCell
                     [component]="line.decoratorOptions?.component" [decoratorOptions]="line.decoratorOptions"
                     [componentOptions]="line.decoratorOptions?.componentOptions" [entity]="entity" [path]="getArrayValuePath(line.path)"
                     [multiIndex]="multiIndex"
                     (event)="onComponentEvent($event)"></div>
                <ng-template #regularCell>
                    <div appEntityTableCell [multiIndex]="multiIndex" [decorator]="line.decorator" [decoratorOptions]="line.decoratorOptions"
                         [entity]="entity" [path]="getArrayValuePath(line.path)"></div>
                </ng-template>
                <ng-container *ngIf="column.decoratorOptions.separator && !last">{{column.decoratorOptions.separator}}
                </ng-container>
            </ng-container>
        </td>
    </ng-container>
    <ng-template #singleDecorator>
        <td *ngIf="column.decorator === 'component' && (isArrayValuePath(column.path) || multiIndex === 0); else regularCell" appEntityTableComponentCell
            [component]="column.decoratorOptions?.component" [decoratorOptions]="column.decoratorOptions" [multiIndex]="multiIndex"
            [componentOptions]="column.decoratorOptions?.componentOptions" [entity]="entity" [path]="getArrayValuePath(column.path)"
            [attr.rowspan]="!isArrayValuePath(column.path) && multiIndex === 0 ? multiRowCount : 1"
            (event)="onComponentEvent($event)"></td>
        <ng-template #regularCell>
            <td appEntityTableCell appEntityTableCellStyleDecorator
                [multiIndex]="multiIndex"
                [decorator]="column.decorator"
                [decoratorOptions]="column.decoratorOptions"
                [pathToStyleHints]="column.pathToStyleHints"
                [pathToStyleDecorator]="column.pathToStyleDecorator"
                [styleDecorator]="column.styleDecorator"
                [styleDecoratorOptions]="column.styleDecoratorOptions"
                [entity]="entity"
                [path]="getArrayValuePath(column.path)"
                *ngIf="isArrayValuePath(column.path) || multiIndex === 0"
                [attr.rowspan]="!isArrayValuePath(column.path) && multiIndex === 0 ? multiRowCount : 1"></td>
        </ng-template>
    </ng-template>
</ng-container>
<td *ngIf="config.removable">
    <a href="#" title="Entfernen" (click)="onRemoveClicked($event)" class="entity-table__remove-button">
        <app-icon class="derifin-icon" icon="derifin:schliessen"></app-icon>
    </a>
</td>
