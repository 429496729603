import { createFeatureSelector, createSelector } from "@ngrx/store";
import { groupBy } from "lodash";
import * as moment from "moment";
import * as RouterSelectors from "../../../../core/store/selectors/router.selectors";
import { ProductDetailsState } from "../state/product-details.state";

export const selectProductDetails = createFeatureSelector<ProductDetailsState>('productDetails');

export const selectCurrentSessionId = createSelector(
    selectProductDetails,
    RouterSelectors.selectOverlayRouteParam,
    (state, productId) => state.currentSessionMapping[productId] 
);

export const selectMeta = createSelector(
    selectProductDetails,
    (state) => state.meta
);

export const selectTabs = createSelector(
    selectMeta,
    (meta) => meta.tabs
);

export const selectCurrentTabId = createSelector(
    selectMeta,
    (meta) => meta.currentTabId
);

export const selectCurrentTabType = createSelector(
    selectTabs,
    selectCurrentTabId,
    RouterSelectors.selectOverlayRouteParam,
    (tabs, tabId, productId) =>
        tabs[productId]?.find((tab) => tab.id === tabId)?.type
);

export const selectData = createSelector(
    selectProductDetails,
    selectCurrentTabType,
    selectCurrentSessionId,
    (state, tabType, sessionId) =>
        tabType === 'WIDGET' ? state.data[sessionId] : state.form.initial[sessionId]
);

export const selectProductDq = createSelector(
    selectData,
    selectCurrentSessionId,
    (data) => data?.dq as string
);

export const selectChangelogLoading = createSelector(
    selectProductDetails,
    (state) => state.changelog.loading
);

export const selectChangelogEmpty = createSelector(
    selectProductDetails,
    (state) => state.changelog.empty
);

export const selectChangelogUserList = createSelector(
    selectProductDetails,
    (state) => state.changelog.userList
);

export const selectChangelogFilteredChanges = createSelector(
    selectProductDetails,
    (state) => groupBy(state.changelog.filteredChanges,
        (change) => moment(change.timestamp).startOf('day').format()
    ) 
);
