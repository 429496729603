import { createReducer, on } from "@ngrx/store";
import * as AdActions from "../actions/ad.action";
import { AdState } from "../state/ad.state";

export const initialState: AdState = {
    polling: {
        active: false,
        interval: 0
    }
}

export const reducer = createReducer(initialState,
    on(AdActions.activatePolling, (state): AdState =>
        ({ ...state,
            data: initialState.data,
            polling: {
                ...state.polling,
                active: true,
                interval: initialState.polling.interval
            }
        })
    ),
    on(AdActions.loadAd, (state): AdState => 
        ({ ...state,
            polling: {
            ...state.polling,
                interval: 30000
            }
        })
    ),
    on(AdActions.setAd, (state, { data }): AdState => 
        ({ ...state, data })
    ),
    on(AdActions.resetAd, (state): AdState => 
        ({ ...state,
            data: initialState.data,
            polling: initialState.polling
        })
    )
);
