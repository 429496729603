/**
 * Created by Alex Klein <alex@rocketloop.de> on 05/16/17.
 */

import { Injectable } from '@angular/core';
import { act, Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { handleAuthenticationError } from '../../../../../core/helpers/errors.helper';
import { ProductDecorfinApi } from '../../api/product-decorfin.api';
import { ProductSharedApi } from '../../api/product-shared.api';
import { ProductSharedErrors } from '../../errors/product-shared.errors';
import { ProductActivation, ProductNotes } from '../../models/product-shared.model';
import {
    ProductSharedActionTypes,
    UpdateProductActivationAction,
    updateProductActivationFailed,
    updateProductActivationSucceeded,
    UpdateProductDecorfinDataAction,
    updateProductDecorfinDataFailed,
    updateProductDecorfinDataSucceeded,
    UpdateProductDericonRecommendAction, updateProductDericonRecommendFailed,
    updateProductDericonRecommendSucceeded,
    UpdateProductNotesAction,
    updateProductNotesFailed,
    updateProductNotesSucceeded,
} from '../actions/product-shared.actions';
import { ProductRecommendApi } from '../../api/product-recommend.api';

/**
 * Effect class for product shared effects
 */
@Injectable()
export class ProductSharedEffects {

    /** Effect Declarations **/

     public loadFilterStructure$ = createEffect(() => this.actions$.pipe(
        ofType(ProductSharedActionTypes.UPDATE_PRODUCT_ACTIVATION),
        switchMap((action: UpdateProductActivationAction) => this.updateProductActivation(action)),
    ));

     public loadFilterAvailableValues$ = createEffect(() => this.actions$.pipe(
        ofType(ProductSharedActionTypes.UPDATE_PRODUCT_NOTES),
        switchMap((action: UpdateProductNotesAction) => this.updateProductNotes(action)),
    ));

     public updateProductDecorfinData$ = createEffect(() => this.actions$.pipe(
        ofType(ProductSharedActionTypes.UPDATE_PRODUCT_DECORFIN_DATA),
        switchMap((action: UpdateProductDecorfinDataAction) => this.updateProductDecorfinData(action)),
    ));

     public updateProductDericonRecommend$ = createEffect(() => this.actions$.pipe(
        ofType(ProductSharedActionTypes.UPDATE_PRODUCT_DERICON_RECOMMEND),
        switchMap((action: UpdateProductDericonRecommendAction) => this.updateProductDericonRecommend(action)),
    ));

    constructor(
        private actions$: Actions,
        private productSharedApi: ProductSharedApi,
        private productDecorfinApi: ProductDecorfinApi,
        private productRecommendApi: ProductRecommendApi,
    ) {
    }

    /** Effect Handler **/

    /**
     * Effect handler called upon the UpdateProductActivationAction
     *
     * This effect handler calls the corresponding API method to update the product activation for the specified product
     * type id
     * @param action
     * @returns {Observable<Action>}
     */
    public updateProductActivation(action: UpdateProductActivationAction): Observable<Action> {
        return this.productSharedApi
            .updateProductActivation(
                action.payload.productDq,
                action.payload.issueSurcharge,
                action.payload.activated,
                action.payload.price
            ).pipe(
                map((activation: ProductActivation) => {
                    return updateProductActivationSucceeded(
                        action.payload.sessionId,
                        action.payload.productDq,
                        action.payload.dataPath,
                        activation
                    );
                }),
                catchError((err) => handleAuthenticationError(err, action)),
                catchError((err: any) => of(updateProductActivationFailed({
                    errorCode: ProductSharedErrors.UNKNOWN,
                    httpError: true,
                    statusCode: err.status,
                }))),
            );
    }

    /**
     * Effect handler called upon the UpdateProductNotesAction)
     *
     * This effect handler calls the corresponding API method to update the product notes for the specified
     * product type id
     * @param action
     * @returns {Observable<Action>}
     */
    public updateProductNotes(action: UpdateProductNotesAction): Observable<Action> {
        return this.productSharedApi
            .updateProductNotes(action.payload.productDq, action.payload.comment, action.payload.promoted).pipe(
                map((notes: ProductNotes) => {
                    return updateProductNotesSucceeded(
                        action.payload.sessionId,
                        action.payload.productDq,
                        action.payload.dataPath,
                        notes
                    );
                }),
                catchError((err) => handleAuthenticationError(err, action)),
                catchError((err: any) => of(updateProductNotesFailed({
                    errorCode: ProductSharedErrors.UNKNOWN,
                    httpError: true,
                    statusCode: err.status,
                }))),
            );
    }

    public updateProductDecorfinData(action: UpdateProductDecorfinDataAction): Observable<Action> {
        return this.productDecorfinApi
            .updateProductDecorfinData(
                action.payload.productDq,
                action.payload.brokerDecorfinId,
                action.payload.data,
                action.payload.brokerDecorfinDataMode,
                action.payload.sessionId
            ).pipe(
                map((res) => updateProductDecorfinDataSucceeded()),
                catchError((err) => handleAuthenticationError(err, action)),
                catchError((err: any) => of(updateProductDecorfinDataFailed({
                    errorCode: ProductSharedErrors.UNKNOWN,
                    httpError: true,
                    statusCode: err.status,
                }))),
            );
    }

    private updateProductDericonRecommend(action: UpdateProductDericonRecommendAction) {
        
        return this.productRecommendApi
            .updateProductDericonRecommend(
                action.payload.dq,
                action.payload.instrumentType,
                action.payload.data,
            ).pipe(
                map((res) => updateProductDericonRecommendSucceeded()),
                catchError((err) => handleAuthenticationError(err, action)),
                catchError((err: any) => of(updateProductDericonRecommendFailed({
                    errorCode: ProductSharedErrors.UNKNOWN,
                    httpError: true,
                    statusCode: err.status,
                }))),
            );
    }
}
