<nav class="navbar navbar-toggleable-md header-bar">
    <a class="navbar-brand" [routerLink]="logoUrl$ | async">
        <img class="header-bar__logo" [src]="logoImgUrl$ | async"/>
    </a>
    <div class="navbar-nav mr-auto align-items-center">
        <div
            *ngIf="displaySearch$ | async"
            style="width: 200px;">
            <app-search
                [showGroupName]="true"
                [displayShortcuts]="true"
                [products]="products$ | async"
                [shortcuts]="shortcuts$ | async"
                (termSearch)="onTermSearch($event)">
            </app-search>
        </div>

        <div class="header-bar__divider"></div>

        <a class="nav-link header-bar__link header-bar__logout-button" href="#"
           (click)="onSignOutButtonClicked($event)">
            <span class="header-bar__logout-button-label">{{ 'logout' | transloco }}</span>
            <app-icon class="header-bar__logout-button-icon" icon="derifin:log-out"></app-icon>
        </a>

        <a class="nav-link header-bar__link header-bar__profile-button"
            [routerLink]="profileLink$ | async">
            <span class="header-bar__profile-button-label">{{ profileMenuLinkLabel$ | async }}</span>
            <app-icon icon="material:account"></app-icon>
        </a>

        <a class="nav-link header-bar__link header-bar__customer-selection-button"
           [routerLink]="activatedLink$ | async"
           *ngIf="canSelectCustomer$ | async">
            <span class="header-bar__customer-selection-button-label">{{ (activatedLabel$ | async) | transloco: (activatedLabelParams$ | async) }}</span>
            <app-icon icon="material:account-settings"></app-icon>
        </a>
    </div>
    <div class="navbar-nav">
        <app-product-selection-indicator
            [routerLink]="['/app', { outlets: {'overlay': ['product-selection']}}]"></app-product-selection-indicator>

        <!--<app-current-page-indicator class="nav-link header-bar__link"></app-current-page-indicator>-->

        <div class="header-bar__divider"></div>

        <a href="#" class="nav-link header-bar__link header-bar__menu-button icon-link"
           (click)="onMenuButtonClicked($event)">
            <span
                *ngIf="displayMenuTitle$ | async"
                class="header-bar__hamburger-menu-button-label">{{ 'menu.hamburgerMenuButtonLabel' | transloco }}</span>
            <app-icon icon="derifin:hamburger"></app-icon>
        </a>
    </div>
</nav>
