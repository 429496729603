import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Entity } from '../../models/entities.model';
import { EntityTableCellComponent } from '../entity-table-component-cell/entity-table-component-cell.component';

import { get } from 'lodash';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-entity-table-factsheet-cell',
    templateUrl: './entity-table-factsheet-cell.component.html',
    styleUrls: ['./entity-table-factsheet-cell.component.scss'],
    standalone: true,
    imports: [RouterLink]
})
export class EntityTableFactsheetCellComponent implements OnInit, OnChanges, EntityTableCellComponent {

    @Input() public product: Entity;
    @Input() public options: any;
    @Input() public path: string;
    @Input() public multiIndex = 0;

    /**
     * The label to display
     */
    public label: string;

    /**
     * The id of the product
     */
    public id: string;

    constructor() {
    }

    public ngOnInit() {
    }

    public ngOnChanges(changes: SimpleChanges) {
        this.label = get(this.product, this.path);
        this.id = get(this.product, this.options.idPath);
    }

}
